import { LOCALE } from "../resources/locale";
import { NColumn } from "../../components/src/base/NColumn";
import { NRow } from "../../components/src/base/NRow";
import { NIconButton } from "../../components/src/NIconButton";
import { NMainPanel } from "../../components/src/base/NMainPanel";
import { DialogManager } from "./DialogManager";
import { NContainer } from "../../components/src/base/NContainer";
import { DNode, DStyle } from "../../model/src/DNode";

export class ElementMenu
{
	addMenu = function(element, lang, uiModel, reloadCallback, selectElementCallback) 
    {
        let editor: {[k: string]: any} = {};
    	editor  = document.createElement("DIV");
		editor.id = element.domId + "_edit"
		editor.style.fontFamily = "Arial"
		editor.njsIsEditor = true;
    	let mainDiv = new NMainPanel(editor, NMainPanel.generateUID());
		if ( element.type == "row" ){
			editor.classList.add("rowMenu");
		} else if ( element.type == "column" ){
			editor.classList.add("columnMenu");
		} else {
			editor.classList.add("elementMenu");
			// editor.style.position = "absolute";
			// editor.style.zIndex = "99";
			// editor.style.height = "20px";
			// editor.style.marginTop = "1px";
		}
		editor.style.display = "none";
    	element.getHTMLElement().appendChild(editor);

    	let row = new NRow();
    	mainDiv.addRow(row);
		let con :NContainer = row;
		if ( element.type == "column" ){
			let col = new NColumn().setWidth("28px");
			// col.getHTMLElement().style.border = "1px solid gray";
			row.addColumn(col);
			con = col;
		}

		// let buttonStyle = "padding:0; background-color:#efefef; color:black; width:24px; height:24px; margin:0;";
		let btnRemove = new NIconButton("", "close-o", "left", LOCALE[lang].DELETE_TOOLTIP);
		// let btnNewContainer = new NIconButton("", "add");
		let btnNewContainer = new NIconButton("", "square2", "left", LOCALE[lang].ADD_CONTAINER_TOOLTIP);
		let btnAddSeparator = new NIconButton("", element.type == "column" ? "separator-vertical" : "separator-horizontal", "left", LOCALE[lang].ADD_SEPARATOR_TOOLTIP);
		let btnUp = new NIconButton("", "arrow-up", "left", LOCALE[lang].SELECT_PARENT_TOOLTIP);
		let btnDrag = new NIconButton("", "assign", "left", LOCALE[lang].DRAG_TOOLTIP);

		let btnAddImage = new NIconButton("", "image", "left", LOCALE[lang].SELECT_IMAGE_TOOLTIP).setStyle("height", "26px");

		if ( element.njsNodeType == "image" ){
			con.addElement(btnAddImage);
			btnAddImage.addOnClickEvent( () => element.onOpenImage());
		}

    	con.addElement(btnRemove);
		btnRemove.getHTMLElement().addEventListener("click", this.onRemove.bind(event, element, uiModel, reloadCallback));
		// let removeButton = btnRemoveDOM.getElementsByTagName("button")[0];
		btnRemove.setStyle('padding', '0 2px');
		btnRemove.setStyle('height' ,'26px');
		btnRemove.setStyle('color', 'black');

		if( element.njsParentElement && element.njsParentElement.njsNodeType){
			con.addElement(btnUp);
			btnUp.getHTMLElement().addEventListener("click", this.onSelectParent.bind(event, element, selectElementCallback));
			// let upButton = btnUpDOM.getElementsByTagName("button")[0];
			btnUp.setStyle('padding', '0 2px');
			btnUp.setStyle('height' ,'26px');
		}

		// if ( element.njsNodeType == "row" || element.njsNodeType == "column" ) {
			con.addElement(btnNewContainer);
			btnNewContainer.getHTMLElement().addEventListener("click", this.onAppendNewContainer.bind(event, element, uiModel, reloadCallback));
			// let newButton = btnNewContainer.getElementsByTagName("button")[0];
			btnNewContainer.setStyle('padding', '0 2px');
			if ( element.type == "column" ) {
				btnNewContainer.setStyle('padding',  '0 4px');
			}
			btnNewContainer.setStyle('height', '26px');
			btnNewContainer.setStyle('color', 'black');	
		// }

		// if ( element.njsNodeType == "row" ||  element.njsNodeType == "column" ) {
			con.addElement(btnAddSeparator);
			btnAddSeparator.getHTMLElement().addEventListener("click", this.onAddSeparator.bind(event, element, uiModel, reloadCallback));
			// let newButton = btnAddSeparatorDOM.getElementsByTagName("button")[0];
			btnAddSeparator.setStyle('padding', '0 2px');
			if ( element.type == "column" ) {
				btnAddSeparator.setStyle('padding', '0 4px');
			}
			btnAddSeparator.setStyle('height', '26px'); 
			btnAddSeparator.setStyle('color', 'black'); 	
		// }

		if ( element.type == "row" ||  element.type == "column" ) 
		{
			let btnDivider = new NIconButton("", element.type == "column" ? "divider-vertical" : "divider-horizontal", "left", LOCALE[lang].SET_DIVIDER_TOOLTIP);
			con.addElement(btnDivider);
			btnDivider.getHTMLElement().addEventListener("click", this.onSetDivider.bind(event, element, uiModel, reloadCallback));
			btnDivider.setStyle('padding', '0 3px');
			btnDivider.setStyle('height', '26px'); 
			btnDivider.setStyle('color', 'black'); 	
		}


    	con.addElement(btnDrag);
		btnDrag.getHTMLElement().addEventListener("dragstart", this.onDragStart.bind(event, element));
		btnDrag.getHTMLElement().draggable = true;
		// let dragButton = btnDragDOM.getElementsByTagName("button")[0];
		btnDrag.setStyle('height', '26px'); 
		btnDrag.setStyle('color', 'black'); 
		btnDrag.setStyle('cursor', 'move'); 

		// btnDownDOM.addEventListener("click", onMoveElement.bind(event, element, false));
    }

	onDragStart = function(element) {
		// console.log("drag started!!!!");
	}

	onAppendNewContainer = function (element, uiModel, reloadCallback) 
	{
		let props = uiModel.createNodeProperties(element.type == "column" ? "column" : "row" );
		if ( element.type != "row" && element.type != "column" ) {
			props.style = {};
			props.style.height = "10px";
		}
		uiModel.appendNodeTo(element.njsNodeId, props, false);
		reloadCallback();
	}

	onAddSeparator = function (element, uiModel, reloadCallback) 
	{
		let newElementType = "row";
		if ( element.type == "column" ) {
			newElementType = "column";
		}

		let newNode:DNode;
		newNode = uiModel.createNodeProperties(newElementType);
		newElementType == "column" ? newNode.style.width = "10px" :newNode.style.height = "10px";			
		uiModel.appendNodeTo(element.njsNodeId, newNode, false);
		let sepNode = uiModel.createNodeProperties("separator");
		sepNode.size = "100%";
		uiModel.adddNodeToContainer(newNode, sepNode);
		reloadCallback();
	}

	onSetDivider = function (element, uiModel, reloadCallback) 
	{
		uiModel.updateNode(element.njsNodeId, "hasDivider", true);
		reloadCallback();
	}

	onRemove = (element, uiModel, reloadCallback) =>
	{
		DialogManager.showConfirmDialog("Delete?", "Do you want to delete selected Element?" , function (confirmed)
			{
				if ( confirmed ){
					uiModel.removeNodeById(element.njsNodeId);
					reloadCallback();	
				}
			} 
		);
	}

	onSelectParent = function(element, selectElementCallback, event){
		selectElementCallback(element.njsParentElement, event);
	}

    
}