
import { COMP_LANG, COMP_LOCALE } from "../resources/locale";
import {NElement} from "./base/NElement";
import { NHTMLElement } from "./base/NHTMLElement";
import { NMainPanel } from "./base/NMainPanel";

export class NDialog extends NElement
{
    private pOnClose: Function;

    constructor(title: string, message: string, onClose: Function, showCancel: boolean) {
        super("confirmDialog", {title: title, message:message, onClose: onClose, showCancel: showCancel});
        this.pOnClose = onClose;
    }

    createHTMLElement(id, config)  : NHTMLElement
    {
        let mainDiv = new NHTMLElement( "DIV");
        mainDiv.className = 'modal';
        let cancelBtnHTML = "";
        if ( config.showCancel == true ) {
            cancelBtnHTML = "<button class='mui-btn mui-btn--small' id='cancelBtn"+id+"' >" + COMP_LOCALE[COMP_LANG].CANCEL + "</button>";
        }
        mainDiv.innerHTML = 
        "<div id='"+id+"' class='modal-content'>"
            + "<span class='close'>&times;</span>"
            + "<h1 style='margin-top:30px'>" + config.title + "</h1>"
            + "<p style='margin-top:30px'>" + config.message + "</p>"
            + "<div style='text-align:right;margin-top:30'>"
                + cancelBtnHTML
                + "<button class='mui-btn mui-btn--primary mui-btn--small' id='okBtn"+id+"'>" + COMP_LOCALE[COMP_LANG].OK + "</button>"
            + "</div>"
        + "</div>";

        this.addActions(mainDiv);
        return mainDiv;
    }

    private addActions(mainDiv) 
    {
        let cancelBtn = mainDiv.querySelector("#cancelBtn"+this.domId);
        if ( cancelBtn ) {
            cancelBtn.addEventListener("click", () => this.onClose(false) );
        }

        let okBtn = mainDiv.querySelector("#okBtn"+this.domId);
        okBtn.addEventListener("click", () => this.onClose(true) );		
        
        let closeSpan = mainDiv.querySelector(".close");
        closeSpan.addEventListener("click", () => this.onClose(false) );		
    }

    private onClose (confirmed: boolean){        
        var dlg = document.getElementById(this.domId);
        if ( dlg != null ) {
            dlg.parentElement.removeChild(dlg);
        }
        if ( typeof this.pOnClose == "function" ) {
            this.pOnClose(confirmed);
        }
    }

    public show() 
    {
        var bodyElement = document.body;
        bodyElement.appendChild(this.getHTMLElement());
        this.getHTMLElement().style.display = "block";
        window.onclick = (event) => {
            if (event.target == this.getHTMLElement()) {
                bodyElement.removeChild(this.getHTMLElement());
            }
        }
    }
    
}
