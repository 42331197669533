import {NElement} from "./base/NElement";

export class NCheckbox extends NElement
{
    // label: string;
    // isChecked: boolean;
    mainDiv: any;
    constructor(label = "Checkbox", isChecked = false) {
        super("checkbox", {label:label, isChecked:isChecked});
        // this.label = label;
        // this.isChecked =  isChecked;
        // this.mainDiv = null;
    }


    createHTMLElement(id: string, config) 
    {
        this.mainDiv = document.createElement("DIV");  
        var strChecked = config.isChecked ? "checked" : "";
        this.mainDiv.className = 'checkbox';
        this.mainDiv.style.width = "100%";
        var innerHTML = "<input id='"+id+"Input' type='checkbox' " + strChecked +" style='margin-right:6px'>"
                    + "<label class='njs-label' for='"+id+"Input'>"+config.label+"</label>";
                    this.mainDiv.innerHTML = innerHTML;
        this.mainDiv.type = "checkbox";
        return this.mainDiv;
    }

    // getMainElement(){
    //     return this.mainDiv.getElementsByTagName("input")[0];
    // }

    isSelected (){
        let element = this.getMainElement();
        if ( element ){
            return element.checked;
        }
    }

    addOnClickEvent =  (event: any) => {
        let element = this.getMainElement();
        if ( element ){
            element.addEventListener("click", event);
        }
    }

    getMainElement(){
        let element = this.mainDiv.getElementsByTagName("input")[0]
        return element;        
    }

    setHorizontalAlignment = function(align){
        if ( align == "middle" ) {
            align = "center";
        }
        this.mainDiv.style.textAlign = align;
        // this.mainDiv.nsdjs.horizontalAlignment = align;
    };

    getValue = function() : string
    {
        let value = this.getMainElement().value;
        return value;
    }

}

