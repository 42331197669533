
import {NRow} from "../../components/src/base/NRow";
import {NColumn} from "../../components/src/base/NColumn";
import {NLabel} from "../../components/src/NLabel";
import {NButton} from "../../components/src/NButton";
import {NCheckbox} from "../../components/src/NCheckbox";
import {NIconButton} from "../../components/src/NIconButton";
import { LOCALE } from "../resources/locale";
import {NMainPanel} from "../../components/src/base/NMainPanel";
import {UIJustViewer} from "../../viewer/src/UIJustViewer";
import {UIModel} from "../../model/src/UIModel";
import { NElement } from "../../components/src/base/NElement";
import { ElementMenu } from "./ElementMenu";
import { ElementBox } from "./ElementBox";
import { DragDropManager } from "./DragDropManager";
import { NTextarea } from "../../components/src/NTextarea";
import { NCanvas } from "../../components/src/NCanvas";
import { NImage } from "../../components/src/NImage";
import { DNode } from "../../model/src/DNode";
import { NContainer } from "../../components/src/base/NContainer";
import { NEditor } from "./editors/NEditor";
import { NEditorFactory } from "./editors/NEditorFactory";
import { NTree } from "../../components/src/NTree";
import { NSpacer } from "../../components/src/NSpacer";
import { NFileChooser } from "../../components/src/NFileChooser";

export class UIBuilder
{
	LANG  = "EN" ;
	COMP_LANG = "EN" ;
	static MAINPANEL : NMainPanel;
	m_UIViewer:UIJustViewer = null;
	// m_page = null;
	m_pageCol:NColumn = null;
	m_lastSelectedNode: DNode = null;
	// m_lastMouseOverElement:any = null;
	m_pageColId:any = null;
	m_UIModel:UIModel;
	m_doAssignDBColumns = false;
	// m_assignDBColumns = true;
	m_isModelChanged = false;
	m_isPreviewMode = false;
	m_isViewer = false;
	m_nodeUID = 0;
	m_resizedRowMinY:any = null;
	m_propertiesColLabel:any = null;
	m_propertiesCol:any = null;
	txtFormName:any = null; 
	chkPreviewMode : NCheckbox ;
	m_dragDropManager:DragDropManager;
	m_modelPageTextarea : NTextarea;

	txtDBField = null;

    init ( mainDiv: any) 
    {
		mainDiv = document.getElementById(mainDiv);
		let formId: string = NMainPanel.generateUID();
		UIBuilder.MAINPANEL = new NMainPanel(mainDiv, formId);

		let pageWidth = "100%"; let toolWidth = "220px"; let propWidth = "400px"; let modelWidth = "400px";

		var menuRow =new NRow().setHeight('40px');
		UIBuilder.MAINPANEL.addRow(menuRow);

		let btnLoadModelFile = new NFileChooser("Load Model File", null, ".depar");
		btnLoadModelFile.addOnChangeEvent(this.onLoadModelFile); // TODO 
		menuRow.addElement(btnLoadModelFile);
		
		this.chkPreviewMode = new NCheckbox(LOCALE[this.LANG].PREVIEW_MODE)
		this.chkPreviewMode.style.marginLeft = "20px";
		this.chkPreviewMode.addOnClickEvent(this.onPreviewModeClicked); // TODO 
		menuRow.addElement(new NSpacer(20));
		menuRow.addElement(this.chkPreviewMode);

		var mainRow =new NRow().setHeight('95%');
		UIBuilder.MAINPANEL.addRow(mainRow);
		
		let elementbox = new NColumn().setWidth(toolWidth);
		elementbox.addResizer(null, null);
		mainRow.addColumn(elementbox);
		// elementbox.getHTMLElement().style.padding = '4px 4px 4px 4px';
		elementbox.getHTMLElement().style.backgroundColor = "#efefef";

		let lblWidgets = new NLabel(LOCALE[this.LANG].WIDGETS);
		lblWidgets.setHorizontalAlignment("center");
		lblWidgets.getHTMLElement().style.backgroundColor = "slategray";
		lblWidgets.getHTMLElement().style.color = "white";
		elementbox.addElement(lblWidgets);
		elementbox.addRow(new NRow().setHeight("6px"));

		let page:NColumn = new NColumn().setWidth(pageWidth);
		page.addResizer(null, null);
		mainRow.addColumn(page);
		page.getHTMLElement().style.padding = '30px 30px 800px 30px';
		page.getHTMLElement().style.overflowY = 'scroll';
		page.getHTMLElement().style.border = "1px solid #c1c0c0";
	
		let propCol = new NColumn().setWidth(propWidth);
		propCol.addResizer(null, null);
		mainRow.addColumn(propCol);

		let lblProperties = new NLabel(LOCALE[this.LANG].PROPERTIES);
		lblProperties.setHorizontalAlignment("center");
		lblProperties.getHTMLElement().style.backgroundColor = "slategray";
		lblProperties.getHTMLElement().style.color = "white";
		propCol.addElement(lblProperties);

		this.m_propertiesCol = new NColumn();
		this.m_propertiesCol.getHTMLElement().style.padding = '0 10px 10px 10px';
		let rowProp = new NRow().setHeight("100%");
		rowProp.addColumn(this.m_propertiesCol);
		propCol.addRow(rowProp);


		this.m_pageColId = page.getHTMLElement().id;
		this.m_pageCol = page;
		page.getHTMLElement().style.backgroundColor = "white";
		page.getHTMLElement().addEventListener("click", this.onMainDivClicked.bind(event));

		// mainRow.addSeparator(1);

		let modelCol = new NColumn().setWidth(modelWidth);
		mainRow.addColumn(modelCol);

		let modelMenuRow = new NRow();
		let btnRefreshModel:NButton = new NButton("Refresh Model"); 
		btnRefreshModel.addOnClickEvent(() => {
			this.m_modelPageTextarea.setValue( JSON.stringify(JSON.parse(this.getFormJSON()), null, 3) );
		});
		modelMenuRow.addElement(btnRefreshModel);

		var modelLbl = new NLabel("Model");
		modelLbl.setHorizontalAlignment("center");
		modelLbl.getHTMLElement().style.backgroundColor = "slategray";
		modelLbl.getHTMLElement().style.color = "white";
		modelCol.addElement(modelLbl);

		modelCol.addRow(modelMenuRow);

		this.m_modelPageTextarea = new NTextarea("Model", 65);
		modelCol.addElement(this.m_modelPageTextarea);
		// let tree:any = ["jebebe", "fasdfas", ["pot", "pat"]];
		// modelCol.addElement(new NTree(tree));
		modelCol.setStyle("backgroundColor" ,"#efefef");



		this.reinitPage(formId);

		if ( ! this.m_isViewer ) {
			this.createElementBox(elementbox);
			// this.createProperties(this.m_propertiesCol);
		}
        window.addEventListener("DOMContentLoaded", this.onPageResize);
        window.addEventListener("resize", this.onPageResize);

		return;
    }

	getFormJSON () {
		if ( this.m_UIModel ) {
			return this.m_UIModel.getUIModelAsString();
		} else {
			return null;
		}
	}
	
	getFormData() {
		return this.m_UIModel.getFormData();
	}

	setLocale (locale:any) {
		this.LANG="EN";
		// return;		
		// console.log("LANG = ", locale);
        if ( locale.toUpperCase().includes("TR")) this.LANG = "TR";
        if ( locale.toUpperCase().includes("DE")) this.LANG = "DE";    
	}
	
	reinitPage (formId:string)
	{
		this.m_UIModel = new UIModel(formId);
		this.m_UIViewer = new UIJustViewer(this.m_pageColId, this.m_UIModel);
		this.m_dragDropManager = new DragDropManager(this, this.m_UIModel);
		// this.m_UIViewer.setContainersColored(true);
		this.clearLastSelection();
		this.reloadData();
		if ( this.txtFormName ) {
			this.txtFormName.setValue("");
		}
	}

	loadForm (formJSONasStr:string, formName:string, assignDBColumns:boolean) 
	{
		this.m_UIModel = new UIModel();
		this.m_UIModel.setUIModelAsString(formJSONasStr);
		this.m_UIModel.setAssignDBColumns(assignDBColumns);
		this.m_UIViewer.setUIModel(this.m_UIModel); 
		this.m_doAssignDBColumns = assignDBColumns;
		this.reloadData();		
		this.m_isModelChanged = false;
		if ( this.txtFormName ) {
			this.txtFormName.setValue(formName);
		}
	}

	reloadData = (data = this.m_UIModel.getUIModel()) =>
    {
		this.m_UIViewer.setLiveMode(this.m_isPreviewMode);

		console.log("Reloading Data");
		if ( ! data ) return; 
		var yPos = this.m_pageCol.getHTMLElement().scrollTop;

		// this.m_UIViewer.setContainersColored(!this.m_isPreviewMode);
		var createdElements = this.m_UIViewer.reloadUI();
		createdElements.forEach( (element) => {
			this.assignElementActions(element);			
		});
		if ( ! this.m_isPreviewMode ) 
		{
			this.createAddContainerArea();
			if ( this.m_lastSelectedNode ) {
				var lastSelectedElement = this.getHTMLElementForNode(this.m_lastSelectedNode);
				if ( lastSelectedElement ) {
					lastSelectedElement.style.border = "1px solid #aaa";
				}
				// this.m_lastSelectedElement.getHTMLElement().style.border = "1px solid red";
			}
		}
		this.m_pageCol.getHTMLElement().scrollTop = yPos;

		this.m_modelPageTextarea.setValue( JSON.stringify(JSON.parse(this.getFormJSON()), null, 3) );
    }

	getFormName () {
		return this.txtFormName.getValue();
	}

	setPreviewMode (isPreviewMode:boolean) {
		this.m_isPreviewMode = isPreviewMode;
		this.reloadData();
	}

	isModelChanged (){
		return this.m_isModelChanged;
	}
	
	showJSON (){
		console.log("JSON  = ", this.m_UIModel.getUIModel());
	}
	
	onLoadModelFile = (event:any) => {
		console.log("Load model clicked");
		var file = event.target.files[0]; 
		console.log("file:", file);
		
		let fr = new FileReader();
		fr.onload = () => {
			this.m_UIModel.setUIModelAsString(fr.result);
			this.reloadData();
		}
		fr.readAsText(file);
	}

	onPreviewModeClicked  = (event:any) => { 
		console.log("this.m_isPreviewMode = ", this.m_isPreviewMode)
		console.log("this.chkPreviewMode.isSelected() = ", this.chkPreviewMode.isSelected())
		this.m_isPreviewMode = this.chkPreviewMode.isSelected();
		this.reloadData();
	}

    onPageResize  () {
    	var pageDiv = document.getElementById(this.m_pageColId);
    	// console.log("page.clientWidth uuuu= " + pageDiv.clientWidth);
    	// console.log("page.clientHeight = " + pageDiv.clientHeight);
    	// portrait
		// pageDiv.style.height = (pageDiv.clientWidth * 1.414) +"px";
    	// landscape
		// page.style.height = (page.clientWidth * 0.707) +"px";
    }

	createElementBox (colElementbox:any) 
    {
		let tb = new ElementBox( this.LANG, this.addNewContainer, this.addNewElement, this.m_dragDropManager.dragNewElementStarted);
		tb.addToColumn(colElementbox);
	}

    createProperties = function (colProps:NColumn, pNode) 
    {
		// colProps.innerHTML = "";
		colProps.clear();
		colProps.getHTMLElement().style.paddingTop = "20px";
		colProps.getHTMLElement().style.width = "100%";
		colProps.setElementPadding("0 0 10px 0");
		// let pe = new ElementEditor();
		let editor:NEditor = NEditorFactory.getEditor(pNode.type);
		editor.generateEditor(colProps, pNode, this.LANG, this.m_UIModel, this.reloadData);
    }

	// // !!!!!!! KEEP IT AS SAMPLE !!!!!!! //
	// setProperties = function ()
	// {
	// 	var arrFunctionFields = ["txtLabel", "txtValue", "txtHorizontalAlignment", "txtVerticalAlignment", "txtRows", "txtOptions"];
	// 	arrFunctionFields.forEach( (field)=>{
	// 		try {
	// 			var functionName = field.replace('txt', 'set');
	// 			console.log("functionName = ", functionName);
	// 			if ( this.m_lastSelectedElement[functionName] ) {
	// 				this.m_lastSelectedElement[functionName](eval(field).getValue());
	// 				var nodeName = field.replace('txt', '');
	// 				nodeName = nodeName.substring(0,1).toLowerCase() + nodeName.substring(1,nodeName.length);
	// 				this.m_UIModel.updateNode(this.m_lastSelectedElement.njsNodeId, nodeName, eval(field).getValue());
	// 			}				
	// 		} catch (error) {
	// 			// if field does not defined comes here
	// 		}
	// 	});
	// 	var arrStyleFields = ["txtWidth", "txtHeight", "txtBackgroundColor", "txtPadding", "txtMargin", "txtBorder", "txtColor" ];
	// 	arrStyleFields.forEach( (field)=>{
	// 		try {
	// 			var styleName = field.replace('txt', '');
	// 			styleName = styleName.substring(0,1).toLowerCase() + styleName.substring(1,styleName.length);
	// 			this.m_lastSelectedElement.style[styleName] = eval(field).getValue();
	// 			this.m_UIModel.updateNodeStyle(this.m_lastSelectedElement.njsNodeId, styleName, eval(field).getValue());	
	// 		} catch (error) {
	// 			// if field does not defined comes here
	// 		}
	// 	});
	// 	this.m_UIModel.updateNode(this.m_lastSelectedElement.njsNodeId, "columnName", txtColumnName.getValue());
	// 	this.reloadData();
	// }
	// // !!!!!!! KEEP IT AS SAMPLE !!!!!!! //

	// addCanvas (callback) {
	// 	// var canvas = this.m_UIModel.findNode("type", "canvas");
	// 	// if ( canvas ) {
	// 	// 	DialogManager.showMessageDialog("Uyarı", "Bir forma sadece bir adet imaj editör ekleyebilirsiniz.", null);
	// 	// } else {
	// 		var element = this.addNewElement("canvas", this.m_lastSelectedElement);
	// 		// element.initEditor(true, true);
	// 		this.reloadData();
	// 		// element.chooseImage( (base64Img) => {
	// 		// 	this.m_UIModel.updateNode(element.njsNodeId, "base64", base64Img);
	// 		// 	callback();
	// 		// });	
	// 	// }
	// }

	// addNewElementToLastSelection = (type) =>
	// {
	// 	this.addNewElement(type, this.m_lastSelectedElement);
	// }

	addNewRow = () :DNode => {
		this.m_lastSelectedNode = null;
		return this.addNewContainer();
	}

	addNewContainer = (parentNodeId:string = null) : DNode =>
	{
		let node = new DNode ()
		// node.type = "column";
		let parentNode:DNode = null;
		if ( parentNodeId ) {
			parentNode = this.m_UIModel.findNode("id", parentNodeId);
			if ( parentNode.type === "column" || parentNode.type === "row" ) {				
				node.type = parentNode.type == "row" ? "column" : "row";
				if ( node.type === "column" ) {
					node.style.width = "20%";					
				} else {
					node.style.height = "100px";
				}
				// node.size = parentNode.type == "row" ? "100px" : "20%";
			}
		} else {
			parentNode = this.m_lastSelectedNode;
			if ( ! parentNode  || 
				( parentNode && parentNode.type === "column" ) ) {
				node.type = "row";
				node.style.height = "100px";
			} else {
				node.type = "column";
				node.style.width = "20%";
			}
		}
		if ( parentNode && (parentNode.type == "row" || parentNode.type == "column") ) {
			parentNodeId = parentNode.id;
		}	
			node = this.m_UIModel.appendNewNode(0, parentNodeId, node );
			this.reloadData();
			return node;
	}
	  
	extendContainer = function(container) 
	{
		if ( container && container.njsNodeType != "row" ) {
			while(container.njsNodeType && container.njsNodeType != "row"){
				container = container.njsParentElement;					
			}
		}
		if ( container ) {
			// var screenBottom = this.m_UIViewer.getContainerMaxY(container);
			var screenBottom = container.getTotalChildHeight();
			// console.log("screenBottom = " , screenBottom);
			var rect = container.getBoundingClientRect();
			if ( screenBottom > rect.bottom ) {
				container.style.height = screenBottom - rect.top;
				this.m_UIModel.updateNodeStyle(container.njsNodeId, "height", container.style.height);
				// this.m_UIModel.updateNode(parentNodeId, "size", size);	
			}	
		}
	}

	addNewElement = (type) =>
	{
		let containerNodeId : string;
		let containerNode :DNode;
		if ( ! this.m_lastSelectedNode ) {
			containerNode = this.addNewContainer();
		} else {
			if ( this.m_lastSelectedNode.type != "column" && this.m_lastSelectedNode.type != "row" ) {
				return;
			}
			containerNode = this.m_lastSelectedNode;
		}
		if ( containerNode.type == "row") {
			containerNode = this.addNewContainer(containerNode.id);
		}
		if ( containerNode.type != "column" ) {
			throw new Error("In UI Builder elements could only be added in to column container");
		}
		containerNodeId = containerNode.id;
		let newNode = this.m_UIModel.createNodeProperties(type);
		this.m_UIModel.appendNewNode(0, containerNodeId, newNode);	


		// let parentContainer;
		// if ( lastSelectedElement.njsParentElement ) {
		// 	parentContainer = document.getElementById(lastSelectedElement.njsParentElement.id);
		// } else {
		// 	parentContainer = lastSelectedElement;
		// }
		// this.extendContainer(parentContainer);
		if ( type != "canvas" ){
			this.reloadData();
		}
		// return element;
	}

	assignElementActions = (element:NElement) =>
	{
		if ( ! this.m_isPreviewMode && element ) 
		{
			element.getHTMLElement().addEventListener("click", this.onElementSelected.bind(event, element));
			element.getHTMLElement().draggable = true;
			if ( element.type != "separator" ) { // container of separator has the menu
				let em = new ElementMenu();
				em.addMenu(element, this.LANG, this.m_UIModel, this.reloadData, this.onElementSelected);
				element.getHTMLElement().ondragstart= () => this.m_dragDropManager.dragElementStarted(event, element);
				element.getHTMLElement().ondrop = () => this.m_dragDropManager.onDropped(event, element);
				element.getHTMLElement().ondragover = () => this.m_dragDropManager.onDragOver(event, element);
				element.getHTMLElement().ondragleave = () => this.m_dragDropManager.onDragLeave(event, element);		
			}
	
			if ( element.type == "row" || element.type == "column" ) {
				// this.addResizer(<NContainer>element, element.type);		
				(<NContainer>element).addResizer(
					(width)=>{
						this.m_UIModel.updateNodeStyle(element.njsNodeId, "width", width);
					}
					,
					(height)=>{
						this.m_UIModel.updateNodeStyle(element.njsNodeId, "height", height);
					});
			}
	
			if ( element.type == "column") {
				element.getHTMLElement().style.borderLeft = "1px solid #eee";
				element.getHTMLElement().style.borderRight = "1px solid #eee";
				if ( (<NContainer>element).hasDivider ) {
					element.getHTMLElement().style.borderRight = "1px solid black";				
					element.getHTMLElement().style.paddingRight = "1px";				
				}
			} else if ( element.type == "row"){
				element.getHTMLElement().style.border = "1px solid #eee";
				if ( (<NContainer>element).hasDivider ) {
					element.getHTMLElement().style.borderBottom = "1px solid black";				
					element.getHTMLElement().style.paddingRight = "1px";				
				}
			// } else {
				// element.getHTMLElement().style.border = "1px solid #eee";
			}
			if ( element.type == "image") {
				(<NImage>element).setImageLoadedCallback(()=>{
					this.onImageLoaded(element);
				});
			}
			if ( element.type == "canvas") {
				(<NCanvas>element).setDrawingDisabled(true);
				(<NCanvas>element).setImageLoadedCallback(()=>{
					this.onImageLoaded(element);
				})
			}
			if ( element.type == "textfield" || element.type == "numberfield" || element.type == "passwordfield" || element.type == "textarea" 
			|| element.type == "checkbox" || element.type == "select"  || element.type == "table" || element.type  == "canvas" ) {
				element.getHTMLElement().addEventListener("focusout", ()=>this.onFocusOut(element));
			}

		}
		if ( element && element.type == "image") {
			(<NImage>element).setMenuVisible(!this.m_isPreviewMode);
		}

	}

	onFocusOut = function (element){
		this.m_UIModel.updateNode(element.njsNodeId, "value", element.getValue());
	}

	onImageLoaded = (element) => {
		if ( element.getSetting ) {
			this.m_UIModel.updateNode(element.njsNodeId, "setting", JSON.stringify(element.getSetting()));
		}
		if ( element.image ) {
			this.m_UIModel.updateNode(element.njsNodeId, "image", element.image.src);
		}
	}

	onMainDivClicked = () => function(){
    	this.clearLastSelection();
	}

	createAddContainerArea = function()
	{
		var addRowArea = new NRow().setHeight("120px");
		// this.m_UIViewer.getDrawingPage().addRow(addRowArea);
		this.m_pageCol.addRow(addRowArea);
		addRowArea.njsIsDropArea = true;
		addRowArea.getHTMLElement().ondrop = () => this.m_dragDropManager.onDropped(event, addRowArea);
		addRowArea.getHTMLElement().ondragover = () => this.m_dragDropManager.onDragOver(event, addRowArea);
		addRowArea.getHTMLElement().ondragleave = () => this.m_dragDropManager.onDragLeave(event, addRowArea);
		addRowArea.getHTMLElement().style.border = "none";
		addRowArea.setHorizontalAlignment("center");
		var btnAdd = new NIconButton(LOCALE[this.LANG].ADD_CONTAINER_TOOLTIP, "add", "top");
		btnAdd.addOnClickEvent(this.addNewRow);
		var col1 = new NColumn().setWidth("200px");
		addRowArea.addColumn(col1);
		col1.getHTMLElement().style.border = "none";
		col1.addElement(btnAdd);
		// btnAddDom.style.border = "none";
		col1.getHTMLElement().style.paddingTop = "35px";
	}


	// addResizer = function(container:NContainer, type)
	// {
	// 	var resizerPointDiv = document.createElement("DIV");
	// 	if ( type == "row") {
	// 		resizerPointDiv.className = "resizers resizer bottom-middle";
	// 	} else if ( type == "column") {
	// 		resizerPointDiv.className = "resizers resizer right-middle";
	// 	}
	// 	container.getHTMLElement().appendChild(resizerPointDiv);
	// 	// let element:NContainer = container;
	// 	let resizer = resizerPointDiv;
	// 	let minimum_size = 10;
	// 	let original_width = 0;
	// 	let original_height = 0;
	// 	let parent_width = 0;
	// 	let parent_height = 0;
	// 	let original_mouse_x = 0;
	// 	let original_mouse_y = 0;
	// 	let deltaTillHundred = 0;
	// 	let next_col_original_width;
	// 	let nextCol;
	// 	resizer.addEventListener('mousedown', function(e) {
	// 		e.preventDefault();
	// 		original_width = parseFloat(getComputedStyle(container.getHTMLElement(), null).getPropertyValue('width').replace('px', ''));
	// 		original_height = parseFloat(getComputedStyle(container.getHTMLElement(), null).getPropertyValue('height').replace('px', ''));

	// 		var rect = resizer.getBoundingClientRect();
	// 		// console.log(rect.top, rect.right, rect.bottom, rect.left);
	// 		// original_mouse_x = rect.right-6; // 6 is the half of resizer size
	// 		// original_mouse_y = rect.top+6;

	// 		original_mouse_x = e.pageX;
	// 		original_mouse_y = e.pageY;

	// 		if ( container.njsParentElement && container.njsParentElement.getHTMLElement ) {
	// 			parent_width = parseFloat(getComputedStyle(container.njsParentElement.getHTMLElement(), null).getPropertyValue('width').replace('px', ''));
	// 			parent_height = parseFloat(getComputedStyle(container.njsParentElement.getHTMLElement(), null).getPropertyValue('height').replace('px', ''));	
	// 		}

	// 		nextCol = container.getHTMLElement().nextSibling;
	// 		if ( nextCol ){
	// 			next_col_original_width = parseFloat(getComputedStyle(nextCol, null).getPropertyValue('width').replace('px', ''));
	// 		}

	// 		window.addEventListener('mousemove', resize)
	// 		window.addEventListener('mouseup', stopResize)
	// 	})
		
	// 	let resize = (e) => {
	// 		if (resizer.classList.contains('bottom-middle')) {
	// 			if ( this.m_resizedRowMinY == null) {
	// 				// this.m_resizedRowMinY = this.m_UIViewer.getContainerMaxY(container);
	// 				this.m_resizedRowMinY = container.getTotalChildHeight();
	// 			}
	// 			if ( !  this.m_resizedRowMinY) {
	// 				this.m_resizedRowMinY = 0;
	// 			}
	// 			var moveSize = e.pageY - original_mouse_y;
	// 			const height = original_height + moveSize;

	// 			if (height > minimum_size && (e.pageY > this.m_resizedRowMinY || moveSize > 0) && (parent_height == 0 || height < parent_height)) {
	// 				container.getHTMLElement().style.height = height + 'px'
	// 				this.m_UIModel.updateNodeStyle(container.njsNodeId, "height", container.getHTMLElement().style.height);
	// 			}
	// 		}
	// 		else if (resizer.classList.contains('right-middle')) {
	// 			var delta  = e.pageX - original_mouse_x;
	// 			// console.log("delta = " + delta);
	// 			var deltaInPercent = (delta / parent_width) * 100;
	// 			var width = original_width + delta
	// 			var widthPerc = (width / parent_width) * 100;
	// 			var isPercentageWidth = true;
	// 			var totalWidth = 0;
				
	// 			if ( isPercentageWidth ) {
	// 				// minimum_size = 1;
	// 				totalWidth = (<NRow>container.njsParentElement).getColumnsTotalWidth();
	// 				// console.log("totalWidth = " + totalWidth);
	// 			}
	// 			var tt = totalWidth + (deltaInPercent/10);
	// 			if ( width > minimum_size  && ( totalWidth < 100 || delta < 0) ) {
	// 				var newWidth = width + 'px';
	// 				if ( isPercentageWidth ) {
	// 					newWidth = widthPerc +  '%';
	// 				}
	// 				container.getHTMLElement().style.width = newWidth;
	// 				this.m_UIModel.updateNodeStyle(container.njsNodeId, "width", container.getHTMLElement().style.width);
	// 				// deltaTillHundred = delta;
	// 			}
	// 			if ( tt < 100 && delta > 0 ) {
	// 				deltaTillHundred = delta;
	// 			}
	// 			// console.log("deltaTillHundred = ", deltaTillHundred);
	// 			if ( totalWidth >= 99 && delta > 0 ) { // this part resizes next column. not using, but keep it. 
	// 				if ( nextCol && nextCol.njsNodeType == "column" ){
	// 					var next_col_width = next_col_original_width - (delta - deltaTillHundred);
	// 					if (next_col_width > minimum_size) {
	// 						// console.log("next_col_width = ", next_col_width);
	// 						// console.log("minimum_size = ", minimum_size);
	// 						nextCol.getHTMLElement().style.width = next_col_width + 'px';
	// 						if ( isPercentageWidth ) {
	// 							next_col_width = ( next_col_width / parent_width ) *100;
	// 							// var nextColPercWidth = (next_col_original_width / parent_width) * 100;
	// 							// next_col_width = nextColPercWidth - (totalWidth - 100);
	// 							// nextCol.style.width =  next_col_width + '%';
	// 						}
	// 						this.m_UIModel.updateNodeStyle(nextCol.njsNodeId, "width", nextCol.getHTMLElement().style.width);
	// 					}
	// 				}	
	// 			}
	// 		}			  
	// 	}
	// 	function stopResize() {
	// 		window.removeEventListener('mousemove', resize)
	// 		this.m_resizedRowMinY = null;
	// 	}
	// }
		
	// getContainerMaxY = function (container, heighestBottom = 0)
	// {
	// 	if ( container.type) {
	// 		let children = container.getHTMLElement().children;
	// 		for (var i = 0; i < children.length; i++) {
	// 			var child = children[i];
	// 			if ( child.njsNodeType && child.njsNodeType != "column" && child.njsNodeType != "separator" && ! child.njsIsEditor ) {
	// 				var rect = child.getBoundingClientRect();
	// 				// console.log(rect.top, rect.right, rect.bottom, rect.left);
	// 				if ( rect.bottom > heighestBottom ){
	// 					heighestBottom = rect.bottom;
	// 				}
	// 			}
	// 			if ( ! child.njsIsEditor) {
	// 				heighestBottom = this.getContainerMaxY(child, heighestBottom);
	// 			} 
	// 		}	
	// 	}
	// 	return heighestBottom;
	// }

	// getColumnsTotalWidth = function (container) 
	// {
	// 	if ( container.type == "row" ) {
	// 		let width = parseFloat(getComputedStyle(container.getHTMLElement(), null).getPropertyValue('width').replace('px', ''));

	// 		var children = container.getHTMLElement().children;
	// 		var totalWidthInPercentage = 0;
	// 		for (var i = 0; i < children.length; i++) {
	// 			var child = children[i];
	// 			if ( child.njsNodeType && child.njsNodeType == "column" ) {
	// 				var node = this.m_UIModel.findNode("id", child.njsNodeId);
	// 				var colWidth = 0;
	// 				if ( node.size && node.size.indexOf("px") > -1 ) {
	// 					colWidth = node.size.replace("px","");
	// 					colWidth = (colWidth / width) * 100;
	// 				} else if (node.style && node.style.width && node.style.width.indexOf("px") > -1 ) {
	// 					colWidth = node.style.width.replace("px","");
	// 					colWidth = (colWidth / width) * 100;
	// 				}

	// 				if ( node.size && node.size.indexOf("%") == -1 ) {
	// 					colWidth = node.size.replace("%","");
	// 				} else if (node.style && node.style.width && node.style.width.indexOf("%") > -1 ) {
	// 					colWidth = node.style.width.replace("%","");
	// 				}
	// 				// console.log("colWidth = ", colWidth);
	// 				totalWidthInPercentage += +colWidth;
					
	// 			}
	// 		}
	// 		return totalWidthInPercentage;
	// 	}
	
	// }

	onElementSelected = (element:NElement, event) => {
		// console.log("IN onElementSelected");
		if ( event ) {
			event.stopPropagation();
		}
		// if ( this.m_lastSelectedElement && this.m_lastSelectedElement.type === "canvas" ) {
		// 	this.onCanvasFocusOut(this.m_lastSelectedElement, null);
		// }

    	this.clearLastSelection();
		if ( element.type ) {
			var editor = document.getElementById(element.domId+"_edit");
			if ( editor ) {
				editor.style.display = "block";
			}
			if ( ! this.m_isPreviewMode ) {
				element.getHTMLElement().style.border = "1px solid #aaa";
				if  ( element.type ==  "row" && (<NContainer>element).hasDivider ) {
					element.getHTMLElement().style.borderBottom = "1px solid black";
				} else if  ( element.type ==  "column"  && (<NContainer>element).hasDivider ) {
					element.getHTMLElement().style.borderRight = "1px solid black";
				}

				// container.setStyle('border', '1px solid #aaa');
			}
			// this.m_lastSelectedElement = element;
			this.m_lastSelectedNode = this.m_UIModel.findNode ("id", element.njsNodeId);
			this.loadAttributes();
		}
    }

	loadAttributes = () => 
	{
		// if ( this.m_lastSelectedNode.type != "row" && this.m_lastSelectedNode.type != "column" 
		// 		// && this.m_lastSelectedElement.njsNodeType != "textfield" && this.m_lastSelectedElement.njsNodeType != "numberfield"  
		// ) {
			// this.m_propertiesCol.getHTMLElement().style.display = 'block';
			// this.m_propertiesColLabel.getHTMLElement().style.display = 'block';

			var node = this.m_UIModel.findNode("id", this.m_lastSelectedNode.id);
			if ( ! node.style ) node.style = {};
			this.createProperties(this.m_propertiesCol, node);
			// txtColumnName.setValue(node.columnName ? node.columnName : "") 
			if ( this.txtDBField ) {
				this.txtDBField.setValue(node.DBField ? node.DBField  : "");	
			}
		// } else {
		// 	// this.m_propertiesCol.getHTMLElement().style.display = 'none';
		// 	// this.m_propertiesColLabel.getHTMLElement().style.display = 'none';
		// 	this.m_propertiesCol.getHTMLElement().innerHTML = '';
		// }
	}

	// onContainerMouseOver = function (container, event)
	// {
	// 	if ( event ) {
	// 		event.stopPropagation();
	// 	}
	// 	if ( m_lastMouseOverElement ) {
	// 		var editor = document.getElementById(m_lastMouseOverElement.id+"_edit");
	// 		if ( editor ) {
	// 			editor.style.display = "none";
	// 		}
	// 		m_lastMouseOverElement = null;	
	// 	}

	// 	var editor = document.getElementById(container.id+"_edit");
	// 	if ( editor ) {
	// 		editor.style.display = "block";
	// 	}
	// 	m_lastMouseOverElement = container;
	// }


	
    clearLastSelection = () => {
    	if ( this.m_lastSelectedNode ) {
			var lastSelectedElement :HTMLElement = this.getHTMLElementForNode(this.m_lastSelectedNode);

			if ( ! this.m_isPreviewMode && lastSelectedElement ) {
				if ( this.m_lastSelectedNode.type ==  "column" ) {
					lastSelectedElement.style.border = "none";
					lastSelectedElement.style.borderLeft = "1px solid #eee";
					lastSelectedElement.style.borderRight = "1px solid #eee";
				} else if  ( this.m_lastSelectedNode.type ==  "row" ) {
					lastSelectedElement.style.border = "1px solid #eee";
				} else {
					lastSelectedElement.style.border = "none";
				}
				if ( this.m_lastSelectedNode.hasDivider ) {
					if  ( this.m_lastSelectedNode.type ==  "row" ) {
						lastSelectedElement.style.borderBottom = "1px solid black";
					} else if  ( this.m_lastSelectedNode.type ==  "column" ) {
						lastSelectedElement.style.borderRight = "1px solid black";
					}
				}	

				var editor = document.getElementById(lastSelectedElement.id+"_edit");
				if ( editor ) {
					editor.style.display = "none";
				}
			}
			this.m_lastSelectedNode = null;
    	}    	
    }

	getHTMLElementForNode(node: DNode): HTMLElement | null 
	{
		const element: Node = document.querySelector('[data-njs-node-id="'+node.id+'"]');
		return <HTMLElement>element;
	}
	
}