import {NElement} from "./base/NElement";
import { NHTMLElement } from "./base/NHTMLElement";

export class NTextField extends NElement
{
    // label: string;
    // value: string;
    // mainDiv: {[k: string]: any} = {};
    mainDiv: NHTMLElement;
    constructor(label=" ", value="") {
        super("textfield", {label:label, value:value});
        // this.label = label;
        // this.value = value;
    }

    createHTMLElement(id, config) 
    {
        this.mainDiv = new NHTMLElement("DIV");  
        this.mainDiv.className = 'textfield mui-textfield mui-textfield--float-label';
        if ( config.label == "" ) 
        {
            this.mainDiv.style.padding = "0";
        }
        
        let valueStr = config.value ? "value='"+config.value+"'" : "";
        let textfieldHTML = "<input type='text' class='textfield' "+valueStr+">";
        let labelHTML = config.label ?  "<label >"+config.label+"</label>" : "";
        this.mainDiv.innerHTML = textfieldHTML + labelHTML;
    
        this.mainDiv.id = id;
        return this.mainDiv;
    
    }

    getMainElement = function(){
        return this.mainDiv.getElementsByTagName("input")[0];
    }

    getValue = function() : string
    {
        return this.getMainElement().value;
    }

    setValue = function(value: string)
    {
        this.getMainElement().value = value;
        return this;
    }

}

