import { NColumn } from "../../../components/src/base/NColumn";
import { UIModel } from "../../../model/src/UIModel";
import { NButtonEditor } from "./NButtonEditor";
import { NCheckboxEditor } from "./NCheckboxEditor";
import { NContainerEditor } from "./NContainerEditor";
import { NEditor } from "./NEditor";
import { NImageEditor } from "./NImageEditor";
import { NLabelEditor } from "./NLabelEditor";
import { NSelectEditor } from "./NSelectEditor";
import { NSeparatorEditor } from "./NSeparatorEditor";
import { NTableEditor } from "./NTableEditor";
import { NTextareaEditor } from "./NTextareaEditor";
import { NTextfieldEditor } from "./NTextfieldEditor";

export class NEditorFactory 
{
    static getEditor(type: string):NEditor 
    {
        const typeToEditorMap = {
            "label": NLabelEditor,
            "textfield": NTextfieldEditor,
            "numberfield": NTextfieldEditor,
            "passwordfield": NTextfieldEditor,
            "textarea": NTextareaEditor,
            "button": NButtonEditor,
            "select": NSelectEditor,
            "checkbox": NCheckboxEditor,
            "separator": NSeparatorEditor,
            "table": NTableEditor,
            "row": NContainerEditor,
            "column": NContainerEditor,
            "image": NImageEditor
        };
        
        const EditorClass = typeToEditorMap[type];
        if (EditorClass) {
            return new EditorClass();
        } else {
            throw new Error("Unknown Editor : " + type);
        }
    }
}