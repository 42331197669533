import { NColumn } from "../../components/src/base/NColumn";
import { NIconButton } from "../../components/src/NIconButton";
import { NSpacer } from "../../components/src/NSpacer";
import { LOCALE } from "../resources/locale";

export class ElementBox
{
	private m_parentColumn: NColumn;
	private m_lang : string;
	private m_addNewContainerCallback : Function;
	private m_addNewElementCallback : Function;
	private m_dragStartedCallback : Function;

	constructor(lang:string, addNewContainerCallback:Function, addNewElementCallback: Function, dragStartedCallback:Function){
		this.m_lang = lang;
		this.m_addNewContainerCallback = addNewContainerCallback;
		this.m_addNewElementCallback = addNewElementCallback;
		this.m_dragStartedCallback = dragStartedCallback;
	}

    addToColumn = function (parentColumn:NColumn)
    {
		this.m_parentColumn = parentColumn;
		this.addElementToBox("square", LOCALE[this.m_lang].ADD_CONTAINER,  this.m_addNewContainerCallback, "container")
		this.addElementToBox("menuround", LOCALE[this.m_lang].ADD_MENU, this.m_addNewElementCallback, "menu")
		this.addElementToBox("tabs", LOCALE[this.m_lang].ADD_TABS, this.m_addNewElementCallback, "tabs")
		this.addElementToBox("table", LOCALE[this.m_lang].ADD_TABLE, this.m_addNewElementCallback, "table")
		this.addElementToBox("text", LOCALE[this.m_lang].ADD_TEXT, this.m_addNewElementCallback, "label")
		this.addElementToBox("rectangle", LOCALE[this.m_lang].ADD_BUTTON, this.m_addNewElementCallback, "button")
		this.addElementToBox("textfield", LOCALE[this.m_lang].ADD_TEXTFIELD, this.m_addNewElementCallback, "textfield")
		this.addElementToBox("textfield", LOCALE[this.m_lang].ADD_PASSWORDFIELD, this.m_addNewElementCallback, "passwordfield")
		this.addElementToBox("mail", LOCALE[this.m_lang].ADD_EMAILFIELD, this.m_addNewElementCallback, "emailfield")
		this.addElementToBox("number", LOCALE[this.m_lang].ADD_NUMBERFIELD, this.m_addNewElementCallback, "numberfield")
		this.addElementToBox("textarea", LOCALE[this.m_lang].ADD_TEXTAREA, this.m_addNewElementCallback, "textarea")
		this.addElementToBox("select", LOCALE[this.m_lang].ADD_SELECT, this.m_addNewElementCallback, "select")
		this.addElementToBox("checkbox", LOCALE[this.m_lang].ADD_CHECKBOX, this.m_addNewElementCallback, "checkbox")
		this.addElementToBox("radiobutton", LOCALE[this.m_lang].ADD_RADIOBUTTON, this.m_addNewElementCallback, "radiobutton")
		this.addElementToBox("slider", LOCALE[this.m_lang].ADD_SLIDER, this.m_addNewElementCallback, "slider")
		this.addElementToBox("calendar", LOCALE[this.m_lang].ADD_DATEPICKER, this.m_addNewElementCallback, "datepicker")
		this.addElementToBox("colorbucket", LOCALE[this.m_lang].ADD_COLORPICKER, this.m_addNewElementCallback, "colorpicker")
		this.addElementToBox("image", LOCALE[this.m_lang].ADD_IMAGE, this.m_addNewElementCallback, "image")
		this.addElementToBox("filechooser", LOCALE[this.m_lang].ADD_FILECHOOSER, this.m_addNewElementCallback, "filechooser")
		// this.addElementToBox("image", LOCALE[this.m_lang].ADD_IMAGE_EDITOR, this.m_addNewElementCallback, "canvas")
    }

	private addElementToBox = function(
		iconstring : string,  
		label: string,
		clickCallback: Function , 
		type: string)
		{
			let btnAdd = new NIconButton(label, iconstring, "top");
			this.m_parentColumn.addElement(btnAdd);
			btnAdd.getHTMLElement().draggable = true;
			if ( type == "container"){
				btnAdd.getHTMLElement().addEventListener("click", () => {clickCallback()} );		
			} else {
				btnAdd.getHTMLElement().addEventListener("click", () => {clickCallback(type)} );		
			}
			btnAdd.getHTMLElement().ondragstart= () => this.m_dragStartedCallback(event, type);
			this.m_parentColumn.addElement(new NSpacer(4));
		}


}