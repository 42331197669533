import { NFontChooser } from "../../../components/src/NFontChooser";
import { LABEL_HEIGHT } from "../../../components/src/NLabel";
import { NSelect } from "../../../components/src/NSelect";
import { NSpacer } from "../../../components/src/NSpacer";
import { NColumn } from "../../../components/src/base/NColumn";
import { UIModel } from "../../../model/src/UIModel";
import { NEditor } from "./NEditor";


export class NLabelEditor extends NEditor
{
    addEditors() 
    {
        this.addLabelEditor();

        this.colProps.addElement(new NSpacer(20));

        this.addTextAlignmentEditor();

        this.addHeightEditor();

        this.colProps.addElement(new NSpacer(10));

        this.addColorChooser(false, "#000000");

        this.addColorChooser(true, "#ffffff");

        this.addFontChooser();
        
        this.addBorderEditor();

        // var txtBackgroundColor = colProps.addElement(new NColorChooser( LOCALE[lang].BACKGROUND_COLOR , pNode.style.backgroundColor ? pNode.style.backgroundColor : "#ffffff"));
        // txtBackgroundColor.style.color="gray";
        // txtBackgroundColor.getHTMLElement().addEventListener("change", (event)=>{
        //     uiModel.updateNodeStyle(pNode.id, "backgroundColor", (event.target as HTMLTextAreaElement).value);
        //     reloadCallback();
        // });
        // var txtColor = colProps.addElement(new NColorChooser(LOCALE[lang].TEXT_COLOR, pNode.style.color ? pNode.style.color : "#000000"));
        // txtColor.style.color="gray";
        // txtColor.getHTMLElement().addEventListener("change", (event)=>{
        //     uiModel.updateNodeStyle(pNode.id, "color", (event.target as HTMLTextAreaElement).value);
        //     reloadCallback();
        // });

    }

}