import { COMP_LANG, COMP_LOCALE } from "../resources/locale";
import { NCheckbox } from "./NCheckbox";
import { NColorChooser } from "./NColorChooser";
import { NDialog } from "./NDialog";
import { NIconButton } from "./NIconButton";
import { NSelect } from "./NSelect";
import { NSpacer } from "./NSpacer";
import { NElement } from "./base/NElement";
import { NHTMLElement } from "./base/NHTMLElement";
import { NMainPanel } from "./base/NMainPanel";
import { NRow } from "./base/NRow";

export class NCanvas extends NElement
{
    mainDiv : NHTMLElement;
    canvas:HTMLCanvasElement;
    ctx:CanvasRenderingContext2D;
    njsCanvas:HTMLCanvasElement;
    chooserId : string;
    coordinates : any;
    image : HTMLImageElement = new Image();
    base64Img : string;
    strokeWidth:NSelect;
    markerNo : NSelect;
    colorChooser:NColorChooser;
    markerColorChooser : NColorChooser;
    choosingFile : boolean;
    editorHeight : number;
    btnOpen: NIconButton;
    m_isModelChanged:boolean;
    chkLocked:NCheckbox;
    latestUndoRedoBreakPoint:number;
    breakPointBeforeErase:number;
    njsIsDrawingDisabled:boolean;
    drawMarker:boolean;
    setting:any;
    lastKnownPos :{x:number; y:number};

    constructor(value:{}) {
        super("canvas", {value:value});
        // this.initEditor(true, false);
    }

    imageLoadedCallback:Function;

    createHTMLElement(id, config) : NHTMLElement
    {
        this.mainDiv = new NHTMLElement("DIV");

        this.chooserId =  id+"Chooser";
        var innerHTML=
            "<canvas style='overflow:scroll; width:100%;' >"
            + "Your browser does not support the HTML5 canvas tag."
            +"<input type='file' id='"+this.chooserId+"' style='display:none; ' />";
            + "</canvas>";

        this.mainDiv.innerHTML = innerHTML;

    //NSD    attachCommonMethods(mainDiv);
        // methods

        try {
            if ( config.value && config.value != "" ) {
                var valueP = JSON.parse(config.value);
                config.value = valueP;
            }            
        } catch (error) {
            console.log("error by parsing value of canvas", error);
        }

        // console.log("continuing ", value);
        if ( config.value && config.value.coordinates ) {
            this.coordinates = config.value.coordinates;
        } 
        if ( config.value && config.value.image ) {
            this.base64Img = config.value.image;

            // this.image.src = config.value.image;        
        }
        return this.mainDiv;
    }

    getMainElement = function(){
        return this.mainDiv.getElementsByTagName("canvas")[0];
    }

    setImageLoadedCallback(callback: Function) {
        this.imageLoadedCallback = callback;
    }

    initEditor = (editorEnabled = true, isSelectedImageBase = false) =>
    {
        this.mainDiv.style.border = "1px solid lightgray";
        
        this.canvas = this.mainDiv.getElementsByTagName("canvas")[0];           
        if ( editorEnabled && ! this.njsCanvas) {
            var editorDiv = document.createElement("DIV");  
            // editorDiv.id = editorId;
            this.editorHeight = 40;
            this.mainDiv.insertBefore(editorDiv, this.canvas);
            var editor = new NMainPanel(editorDiv);
            // editor.setBordersVisible(false);
            var row = editor.addRow(new NRow().setHeight(this.editorHeight+"px"));
            row.style.backgroundColor = "#efefef";
            row.style.padding = "5px 0px";

            this.btnOpen = new NIconButton("", "image").setStyle("width", "40px");
            this.btnOpen.addOnClickEvent( () => this.onOpenImage());
            row.addElement(this.btnOpen);

            var btnErase = new NIconButton("", "erase").setStyle("width", "40px");
            btnErase.addOnClickEvent( () => this.erase() );
            row.addElement(btnErase);

            var btnUndo = new NIconButton("", "undo").setStyle("width", "40px");
            btnUndo.addOnClickEvent( () => this.undo() );
            row.addElement(btnUndo);

            var btnRedo = new NIconButton("", "redo").setStyle("width", "40px");
            btnRedo.addOnClickEvent( () => this.redo());
            row.addElement(btnRedo);    

            row.addElement(new NSpacer(15));
            this.colorChooser = new NColorChooser("", "#4169e1"); // royalblue
            row.addElement(this.colorChooser); // royalblue
            var sOptions = "1@#@2@#@3@#@4@#@5@#@6@#@7@#@8@#@9@#@10";
            this.strokeWidth = new NSelect(COMP_LOCALE[COMP_LANG].LINE_HEIGHT+":", sOptions, sOptions, 3);
            this.strokeWidth.setStyle("minWidth","140px");
            row.addElement(this.strokeWidth);
            this.strokeWidth.setStyle("padding", "2px 10px");
            row.addElement(new NSpacer(35));
            this.markerColorChooser = new NColorChooser("", "#E31616");
            row.addElement(this.markerColorChooser);
            var sValues = "@#@1@#@2@#@3@#@4@#@5@#@6@#@7@#@8@#@9@#@10@#@11@#@12@#@13@#@14@#@15@#@16@#@17@#@18@#@19@#@20";
            this.markerNo = new NSelect(COMP_LOCALE[COMP_LANG].ADD_MARKER+":", sValues, sValues, 1);
            this.markerNo.setStyle("minWidth", "120px");
            row.addElement(this.markerNo);
            this.markerNo.setStyle("padding", "2px 10px");


            row.addElement(new NSpacer(25));
            this.chkLocked = new NCheckbox(COMP_LOCALE[COMP_LANG].LOCKED).setStyle("minWidth", "70px");
            row.addElement(this.chkLocked);
        }

    }
    

    getSetting = function(){
        return {lineWidth: this.strokeWidth.getValue(), lineColor: this.colorChooser.getValue(), markerNo:this.markerNo.getValue(), markerColor:this.markerColorChooser.getValue()};
    }
    setSetting = (setting) =>{
        this.setting = setting;
    }
    
    setDrawingDisabled = function(isDrawingDisabled){
        this.njsIsDrawingDisabled = isDrawingDisabled;
    }
    chooseImage = function(callback, editorEnabled = true)
    {
        var chooser = <HTMLInputElement> document.getElementById(this.chooserId);
        this.choosingFile = true;
        chooser.addEventListener("change", (event) => {
            var file = chooser.files[0]; 
            var reader = new FileReader();
            reader.onload = () => {
                var image = reader.result;
                this.loadImage(image, callback, editorEnabled);
                this.coordinates = null;
                // mainDiv.baseImage = image;
                this.image.src = image;
                this.imageLoadedCallback();
            };
            reader.readAsDataURL(file);
            
        } );		
        chooser.click();
        this.choosingFile = false;
    }

    onOpenImage =  () => {
        this.chooseImage( (base64Img) => {
            // if ( isSelectedImageBase ) {
            //     mainDiv.baseImage = base64Img;
            // } else {
                this.image.src = base64Img;
            // }
            this.m_isModelChanged = true;
        }, true);
    }

    loadImage = (base64Img, callback, editorEnabled = true) =>
    {
        this.choosingFile = false;
        // this.canvas = this.mainDiv.getElementsByTagName("canvas")[0];           
        var editorHeight = 0;
        if ( editorEnabled ) {
            editorHeight = 40;
        }
        this.ctx = this.canvas.getContext("2d");
        var img = new Image();
        this.latestUndoRedoBreakPoint = 1;
        this.breakPointBeforeErase = -1;
        this.drawMarker  = true;
        // last known position
        this.lastKnownPos = { x: 0, y: 0 };


        img.onload = () => 
        {
            // set size proportional to image
            this.canvas.width = 800;
            this.canvas.height = this.canvas.width * (img.height / img.width);
            this.ctx.drawImage(img, 0, 0, this.canvas.width, this.canvas.height);
            this.canvas.style.border = "1px solid lightgray";
            if ( ! this.njsIsDrawingDisabled ) {
                if ( ! this.njsCanvas ) {
                    this.canvas.addEventListener('mousemove', this.draw);
                    this.canvas.addEventListener('mousedown', this.onMouseDown);
                    this.canvas.addEventListener('click', this.drawIcon);
                    this.canvas.addEventListener('mouseenter', this.setPosition);                    
                }

                if ( ! this.coordinates ) {
                    this.clearCoordinates();
                    // mainDiv.coordinates = [];
                    // mainDiv.coordinates[0] = {breakPointCount: 0, lineWidth:strokeWidth.getValue(), strokeStyle:colorChooser.getValue() };
                }
                else {
                    this.latestUndoRedoBreakPoint = this.coordinates[0].breakPointCount + 1;
                    this.drawBuffer(0);
                }
                this.njsCanvas = this.canvas;
            } else {
                this.btnOpen.setDisabled(true);
            }
            this.njsParentElement.njsParentElement.getHTMLElement().style.height = this.canvas.height+editorHeight +"";
            if ( this.setting) {
                this.strokeWidth.setValue(this.setting.lineWidth);
                this.colorChooser.setValue(this.setting.lineColor);
                this.markerNo.setValue(this.setting.markerNo);
                this.markerColorChooser.setValue(this.setting.markerColor);        
            }
            if ( callback ) {
                callback(this.canvas.toDataURL("image/jpeg"));
            }

            
        };
        img.src = base64Img;
        this.image = img;
        // mainDiv.base64Img = base64Img;
    }
    clearCoordinates = () => {
        this.coordinates = [];
        this.coordinates[0] = {breakPointCount: 0, lineWidth:this.strokeWidth.getValue(), strokeStyle:this.colorChooser.getValue() };
        this.breakPointBeforeErase = -1;
        this.latestUndoRedoBreakPoint = 1;
        this.lastKnownPos = { x: 0, y: 0 };
    }

        // onOpenImage = function (){
        //     mainDiv.chooseImage( (base64Img) => {
        //         // console.log('image base64 = ', base64Img);
        //         mainDiv.base64Img = base64Img;
        //     }, true, true);
        // }

    onMouseDown = (e) => {
        this.clearArray();
        // console.log("mouse down... coords : ", mainDiv.coordinates);
        this.latestUndoRedoBreakPoint =  this.latestUndoRedoBreakPoint + 1;
        this.coordinates[this.coordinates.length] = {breakPoint:this.coordinates[0].breakPointCount, lineWidth:this.strokeWidth.getValue(), strokeStyle:this.colorChooser.getValue()}
        this.setPosition(e);
    }

    clearArray = () => {
        this.breakPointBeforeErase =  -1;
        var breakPointCount = this.coordinates[0].breakPointCount;
        if ( this.latestUndoRedoBreakPoint <  breakPointCount ) {
            var newCoordinates = [];
            for ( var i = 0; i < this.coordinates.length; i++ )
            {
                var c = this.coordinates[i];
                if ( c.breakPoint && c.breakPoint === this.latestUndoRedoBreakPoint ) {
                    break;
                }
                newCoordinates[i] = c;
            }
            this.coordinates = newCoordinates;
            this.coordinates[0].breakPointCount = this.latestUndoRedoBreakPoint;
        } else {
            this.coordinates[0].breakPointCount = breakPointCount + 1;
        }
    }

    setPosition = (e) => {
        var rect = this.mainDiv.getBoundingClientRect();
        var widthRatio = this.canvas.width / rect.width;
        var heightRatio = this.canvas.height / (rect.height-this.editorHeight);
        this.lastKnownPos.x = (e.clientX - rect.x) * widthRatio;
        this.lastKnownPos.y = (e.clientY - rect.y - this.editorHeight) * heightRatio;
    }
        
    drawIcon = (e) =>{
        // console.log("choosingFile " +choosingFile);
        if ( this.chkLocked.isSelected() || this.choosingFile ) return;
        if ( this.drawMarker == true ) {
            this.ctx.fillStyle = this.markerColorChooser.getValue();
            this.ctx.beginPath();
            this.ctx.arc(this.lastKnownPos.x, this.lastKnownPos.y, 10, 0, 2 * Math.PI);
            this.ctx.fill();    
            this.ctx.font = "bold 14px Arial";
            this.ctx.fillStyle = "white";
            var marker = this.markerNo.getValue();
            if ( +marker >= 10 ) {
                this.ctx.fillText(marker, this.lastKnownPos.x-8, this.lastKnownPos.y+5);
            } else {
                this.ctx.fillText(marker, this.lastKnownPos.x-4, this.lastKnownPos.y+5);
            }
            var buffer = {x1:Math.round(this.lastKnownPos.x), y1:Math.round(this.lastKnownPos.y)};
            this.coordinates[this.coordinates.length-1].markerText = marker;
            this.coordinates[this.coordinates.length-1].markerColor = this.markerColorChooser.getValue();
            this.coordinates[this.coordinates.length] = buffer;
            if ( +marker < 20 && marker != "" ) { 
                marker = marker + 1;
                this.markerNo.setValue(marker)
            } else if (marker != "") {
                this.markerNo.setValue("1");
                this.markerColorChooser.setValue("#111A92"); // random color
                // markerColorChooser.setValue("#" + ((1<<24)*Math.random() | 0).toString(16)); // random color
            }
        }
        this.drawMarker = true;

        var evt = new MouseEvent("mouseup");
        this.canvas.dispatchEvent(evt);
        // console.log(mainDiv.coordinates);
    }

    draw = (e) => {
        if ( this.chkLocked.isSelected() ) return;
        if (e.buttons !== 1) return;
        this.drawMarker = false;
        
        this.ctx.beginPath(); // begin
    
        this.ctx.lineWidth = +this.strokeWidth.getValue();
        this.ctx.lineCap = 'round';
        this.ctx.strokeStyle = this.colorChooser.getValue();
        this.ctx.moveTo(this.lastKnownPos.x, this.lastKnownPos.y); // from
        var buffer : {x1:number; y1:number; x2:number; y2:number};

        buffer = {x1:Math.round(this.lastKnownPos.x), y1:Math.round(this.lastKnownPos.y), x2:null, y2:null};
        this.setPosition(e);
        this.ctx.lineTo(this.lastKnownPos.x, this.lastKnownPos.y); // to
        buffer.x2 = +Math.round(this.lastKnownPos.x);
        buffer.y2 = +Math.round(this.lastKnownPos.y);
        this.ctx.stroke(); // draw it!
        this.coordinates[this.coordinates.length] = buffer;
    }

    erase =  () => {
        if ( this.chkLocked.isSelected() ) return;

        const dlg = new NDialog("Sil?", "Çizimleri silmek istiyor musunuz?", (confirmed)=>{
            if ( confirmed ) {
                // breakPointBeforeErase = latestUndoRedoBreakPoint;
                // latestUndoRedoBreakPoint = 1;
                // ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                // drawBuffer(latestUndoRedoBreakPoint);              
                
                this.ctx.drawImage(this.image, 0, 0, this.canvas.width, this.canvas.height);
                this.clearCoordinates();
            }
        }, true)
        dlg.show();

    }
        
    undo = () => {
        if ( this.chkLocked.isSelected() ) return;
        if ( this.breakPointBeforeErase != -1 ) {
            this.latestUndoRedoBreakPoint = this.breakPointBeforeErase;
        }
        if ( this.latestUndoRedoBreakPoint > 1 ) {
            this.latestUndoRedoBreakPoint = this.latestUndoRedoBreakPoint -1;
        }
        this.ctx.drawImage(this.image, 0, 0, this.canvas.width, this.canvas.height);
        this.drawBuffer(this.latestUndoRedoBreakPoint);
        this.breakPointBeforeErase = -1;
    }
        
    redo = () => {
        if ( this.chkLocked.isSelected() ) return;
        if ( this.latestUndoRedoBreakPoint <= this.coordinates[0].breakPointCount ) {
            this.latestUndoRedoBreakPoint = this.latestUndoRedoBreakPoint + 1;
        }
        this.ctx.drawImage(this.image, 0, 0, this.canvas.width, this.canvas.height);
        this.drawBuffer(this.latestUndoRedoBreakPoint);
    }
        
    drawBuffer = ( breakPoint ) =>
    {
        // console.log(mainDiv.coordinates);
        this.ctx.lineWidth = this.coordinates[0].lineWidth;
        this.ctx.strokeStyle = this.coordinates[0].strokeStyle;
        this.ctx.lineCap = 'round';
        for ( var i = 1; i < this.coordinates.length; i++ )
        {
            var c = this.coordinates[i];
            if ( c.breakPoint ) {
                if ( c.breakPoint == breakPoint  ||  breakPoint <= 0 ) {
                    return;
                }

                this.ctx.beginPath(); // begin
                if ( c.markerColor) {
                    this.ctx.fillStyle = c.markerColor;
                } else {                                
                    this.ctx.lineWidth = c.lineWidth;
                    this.ctx.strokeStyle = c.strokeStyle;    
                }
            } else {
                if ( c.x2 ) {
                    this.ctx.moveTo(c.x1, c.y1); 
                    this.ctx.lineTo(c.x2, c.y2); // to
                    this.ctx.stroke(); // draw it!                                        
                } else {
                    this.ctx.fillStyle = c.markerColor;
                    this.ctx.arc(c.x1, c.y1, 10, 0, 2 * Math.PI);
                    this.ctx.fill();    
                    this.ctx.font = "bold 14px Arial";
                    this.ctx.fillStyle = "white";
                    var markerText = this.coordinates[i-1].markerText;
                    if ( markerText >= 10 ) {
                        this.ctx.fillText(markerText, c.x1-8, c.y1+5);
                    } else {
                        this.ctx.fillText(markerText, c.x1-4, c.y1+5);
                    }    
                }
            }
        }
    }
        // function save() {
        //     document.getElementById("canvasimg").style.border = "2px solid";
        //     var dataURL = canvas.toDataURL();
        //     document.getElementById("canvasimg").src = dataURL;
        //     document.getElementById("canvasimg").style.display = "inline";
        // }

        
}





