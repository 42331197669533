export const COMP_LOCALE : {[k: string]: any} = {};
export const COMP_LANG : string = "EN";


COMP_LOCALE.TR = {
    LINE_HEIGHT:"Çizgi Kalınlığı",
    ADD_MARKER: "Marker",
    LOCKED:"Kilitli",
    OK:"Tamam",
    CANCEL:"İptal",
    SELECT_LABEL:"Seçim Başlığı",
    OPTION:"Seçim",
    LABEL:"Yazı",
}
COMP_LOCALE.EN = {
    LINE_HEIGHT:"Line height",
    ADD_MARKER: "Marker",
    LOCKED:"Locked",
    OK:"OK",
    CANCEL:"Cancel",
    SELECT_LABEL:"Select Label",
    OPTION:"Option",
    LABEL:"Label",
}
COMP_LOCALE.DE = {
    LINE_HEIGHT:"Linienhöhe",
    ADD_MARKER: "Marker",
    LOCKED:"Gesperrt",
    OK:"OK",
    CANCEL:"Abbrechen",
    SELECT_LABEL:"Auswahl Text",
    OPTION:"Auswahl",
    LABEL:"Text",
}
