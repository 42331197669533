import { COMP_LOCALE, COMP_LANG } from "../resources/locale";
import {NElement} from "./base/NElement";
import { NHTMLElement } from "./base/NHTMLElement";

export class NSelect extends NElement
{
    // label: string;
    // strOptions: string;
    // strValues: string;
    // value: string;
    // mainDiv: {[k: string]: any} = {};
    mainDiv: any;
    constructor(label = COMP_LOCALE[COMP_LANG].LABEL + " : ", strOptions, strValues, value) 
    {
        // if ( !strOptions && ! strValues && ! label ) {
        //     strOptions = LOCALE[LANG].OPTION+" 1 @#@ "+LOCALE[LANG].OPTION+" 2 @#@ "+LOCALE[LANG].OPTION+" 3" ;
        //     strValues = LOCALE[LANG].OPTION+" 1 @#@ "+LOCALE[LANG].OPTION+" 2 @#@ "+LOCALE[LANG].OPTION+" 3" ;
        //     label = LOCALE[LANG].SELECT_LABEL + " : " ;
        // }

        super("select", {label: label, strOptions:strOptions, strValues: strValues, value:value});
        // this.label = label;
        // this.strOptions = strOptions;
        // this.strValues = strValues;
        // this.value = value;
    }

    createHTMLElement(id, config)  : NHTMLElement
    {
        if ( ! config.strOptions ) {
            config.strOptions = COMP_LOCALE[COMP_LANG].OPTION+" 1 @#@ "+COMP_LOCALE[COMP_LANG].OPTION+" 2 @#@ "+COMP_LOCALE[COMP_LANG].OPTION+" 3" ;
        }
        if ( ! config.strValues ) {
            config.strValues = COMP_LOCALE[COMP_LANG].OPTION+" 1 @#@ "+COMP_LOCALE[COMP_LANG].OPTION+" 2 @#@ "+COMP_LOCALE[COMP_LANG].OPTION+" 3" ;
        }
        if ( ! config.label ) {
            config.label = "" ;
        }


        if ( ! config.strOptions ){ return  }

        this.mainDiv = new NHTMLElement( "DIV" );  
        this.mainDiv.className = "elementMainDiv";

        var valueArr = config.strValues.split('@#@');
        var optionArr = config.strOptions.split('@#@');
        if ( valueArr.length != optionArr.length ) {
            valueArr == optionArr;
        }
        var options = "";
        for ( var i = 0; i < valueArr.length ; i++ ) {
            if ( valueArr[i].trim() != "" ) {
                var selected = valueArr[i].trim() == config.value ? "selected" : "";
                options += "<option value='"+valueArr[i].trim()+"'  "+selected+">"+optionArr[i].trim()+"</option>";
            }
        }
        
        var selectHTML = "<select class='select' >" + options + "</select>";
        var labelHTML = "<label class='njs-label'>"+config.label+"</label>";
        this.mainDiv.innerHTML = labelHTML + selectHTML;  

        this.mainDiv.type = "select";
        this.mainDiv.id = id;
        // this.mainDiv.options = this.strOptions; 


        return this.mainDiv;
    }

    getMainElement = function(){
        return this.mainDiv.getElementsByTagName("select")[0];
    }

    getValue() : string {
        return this.getMainElement().value;
    }

    setValue(value: string){
        this.getMainElement().value = value;
        return this;
    }

}

