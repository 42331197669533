
export class DNode 
{
	type : string;
	id : string;
	domId : string;
	value : string;
	label : string;
	size : string;
	style : DStyle;
	nodes : DNode[];
	options: [];
	rowLabels : [];
	columnLabels : [];

	rows:number; // textarea rows
	rowCount:number; // table row count
	columnCount:number; // table column count
	hasDivider : boolean;

	DBField : string;

	constructor()
	{
		this.style = new DStyle();
		this.nodes = [];
	}
}

export class DStyle
{
	width : string;
	height : string;
	lineColor: string;
	lineHeight: number;
	backgroundColor: string;
	backgroundImage: string;
	backgroundSize: string;
	backgroundPattern: string;
	color: string;
	textAlign: string;
	fontSize: string;
	textDecoration: string;
	horizontalAlignment: string;
	verticalAlignment: string;
	borderColor: string;
	borderWidth: string;
	borderStyle: string;
}