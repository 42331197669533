import { DNode } from "./DNode";

export class UIModel 
{
	UI_DATA_TYPE = {label:'string', button:'string', textfield:'string', textarea:'text', checkbox:'boolean', 
					numberfield:'number', passwordfield:'string', select:'string', 
					image:'mediumtext', canvas:'mediumtext', table:'text'}
	m_model;
	doAssignDBColumns = false;

	constructor(pFormId?: string){
		let formId = pFormId ?? "";
		this.m_model = {
			version: "1.0.10",
			formId: formId, 
			lastDBFields:{string:0, text:0, number:0, boolean:0, mediumtext:0}, 
			lastNodeId:0,
			pages:[
				{nodes:[]}
			]
		};
		}


    setFormID = function (formId) {
        this.m_model.formId = formId;
    }

    getFormID = function () {
        return this.m_model.formId;
    }

    getUIModel = function () {
        return this.m_model;
    }

    getUIModelAsString = function () {
        return JSON.stringify(this.m_model);;
    }

    setUIModel = function (data) {
        this.m_model = data;
    }

    setUIModelAsString = function (data) {
        this.m_model = JSON.parse(data);
    }

    setAssignDBColumns = function (isAssign) {
        this.doAssignDBColumns = isAssign;
    }

	getFormData = function() {
		var columnValues = this.generateColumnValues();
		// console.log("columnValues = ", columnValues);
		return columnValues;
	}

    getPages = function() {
        return this.m_model.pages;
    }

	findParent = function (nodeId) {
		return this.findNode("id", nodeId, this.m_model.pages[0], true);
	}

	findNextNode = function(nodeId){
		return this.findNode("id", nodeId, this.m_model.pages[0], false, 1);
	}

	findNodeById = function (nodeId) {
		return this.findNode("id", nodeId);
	}

	findNode = function (key , value, pContainer = this.m_model.pages[0], findParent = false, posDif = 0)
	{
		if ( pContainer.nodes ) {
			var arrNodes = pContainer.nodes;
			for ( var i = 0; i < arrNodes.length ; i++ ) {			
				let node = arrNodes[i];
				if ( node )  {
					if ( node[key] == value ) {
						if ( findParent ) {
							return pContainer;
						} else {
							var newPos = i + posDif;
							if ( newPos >= 0 && newPos < arrNodes.length ) {
								return arrNodes[newPos];
							} else if (posDif > 0 ) {
								return null;
							} else {
								return node;
							}
						}
					}	
					var resp = this.findNode(key, value, node, findParent, posDif);
					if ( resp ) return resp;
				}
			}		
		}
	}

	appendNewNode = function (pageNo, parentNodeId, node:DNode) : DNode
	{
		var page =  this.m_model.pages[pageNo];
		let parentNode: any;
		if ( parentNodeId == null ) {
			parentNode = page;
		} else {
			parentNode = this.findNode("id", parentNodeId);
		}
		if ( ! parentNode.nodes ) parentNode.nodes = [];
		if ( parentNode.type == node.type ) {
			return;
		}
		// let newId :number;
		if ( node.id == null ) {
			let newId  = this.generateNodeId();
			node.id = newId;	
		// } else {
		// 	newId = node.id;
		}
		var seqNo = parentNode.nodes.length;
		parentNode.nodes[seqNo] = node;
		return node;
	}

	appendNodeTo = function (nodeId, apNode, insertBefore) 
	{
		var targetContainer = this.findParent(nodeId);
		this.adddNodeToContainer(targetContainer, apNode,  nodeId, insertBefore);
	}

	adddNodeToContainer = function (targetContainer, apNode, nodeId = null, insertBefore = false) 
	{
		if ( ! targetContainer || ! targetContainer.nodes){
			return false;
		}
		var arrNodes = targetContainer.nodes;
		if (  nodeId ) {
			for ( var i = 0; i < arrNodes.length; i++ ) {
				if ( arrNodes[i].id == nodeId){
					var nodePos = i;
					break;
				}
			}			
			if ( insertBefore == false ) {
				nodePos = nodePos + 1;
			}
		} else {
			nodePos = arrNodes.length;
		}
		arrNodes.splice(nodePos, 0, apNode);

		return true;
	}

	removeNodeById = function (nodeId) 
	{
		var oldLastDBColumns =  this.m_model.lastDBFields;
		var container = this.findParent(nodeId);
		var nodes = container.nodes;
		var indexToDelete = -1;
		for ( var i = 0; i < nodes.length; i++ ) {
			var item = nodes[i];
			if ( item.id == nodeId) {
				indexToDelete = i;
				break;
			}
		}
		nodes.splice(indexToDelete, 1);
		if ( this.doAssignDBColumns == true ) {
			this.reAssignColumnNames();
		}
		// console.log("Columns changed = " , JSON.stringify(oldLastDBColumns) != JSON.stringify(this.m_model.lastDBFields))
		let m_isModelChanged: any;
		m_isModelChanged = m_isModelChanged || JSON.stringify(oldLastDBColumns) != JSON.stringify(this.m_model.lastDBFields);
		// reloadData();
	}

	updateNode = function (nodeId, key, value) {
		var node = this.findNode ("id", nodeId);
		node[key] = value;
	}

	updateNodeStyle = function (nodeId, key, value) {
		var node = this.findNode ("id", nodeId);
		if ( node ) {
			if ( ! node.style ) node.style = {};
			node.style[key] = value;
		}
	}


	setFormData = function(values) 
	{
		for (const key in values) {
			var node = this.findNode("DBField", key);
			if ( node ) {
				node.value = values[key];
			}
		}
		// reloadData();
	}

	generateNodeId  = function () :number
	{
		var newId = this.m_model.lastNodeId + 1;
		this.m_model.lastNodeId = newId;
		return newId;
	}

// ------------do this part belongs to here ??? especially createNodeProperties --------------------------------- //

	generateColumnValues = function ( arrContainer = this.m_model.pages[0].nodes, response = {})
	{
		for ( var i = 0; i < arrContainer.length ; i++ ) {			
			let container = arrContainer[i];
			if ( container )  {
				if ( container.DBField && container.DBField != "" ) {
					var value = container.value;
					if ( container.type == "numberfield" && value == "") {
						value = null;
					}
					response[container.DBField] = value;
				}
				if ( container.nodes ) {
					this.generateColumnValues(container.nodes, response);
				}
			}
		}
		return response;
	}

	reAssignColumnNames = function (arrContainer = this.m_model.pages[0].nodes, resetCount = true){
		if ( resetCount ) {
			this.m_model.lastDBFields = {string:0, text:0, number:0, boolean:0, mediumtext:0};
		}
		for ( var i = 0; i < arrContainer.length ; i++ ) {			
			let container = arrContainer[i];
			if ( container )  {
				if ( container.DBField && container.DBField != "" ) {
					container.DBField = this.getNewColumnName(container.type);
				}
				if ( container.nodes ) {
					this.reAssignColumnNames(container.nodes, false);
				}
			}
		}
	}

    getNewColumnName = function (type) 
	{
		type = this.UI_DATA_TYPE[type];
		if ( type ) {
			var DBColNameFirstChar = type.substring(0,1).toLowerCase();
			if ( this.m_model.lastDBFields ) {
				var colNum = this.m_model.lastDBFields[type] + 1;
				this.m_model.lastDBFields[type] = colNum;
				// if ( colNum < 20 ) {
					var newColName = DBColNameFirstChar + colNum;
					return newColName;
					// updateNode(m_lastSelectedElement.njsNodeId, "DBField", newColName);
				// }
			}	
		}
	}

	createNodeProperties = function(type) : DNode
	{
		var nodeId = this.generateNodeId();
        // let properties: {[k: string]: any} = {};
        let node = new DNode;
		node.id = nodeId;
		node.type = type;
		if ( type == "row" || type == "column" ) {
			if ( type == "row" ) {
				node.style.height = "100px";			
			} else if ( type == "column" ) {
				node.style.width = "20%";			
			}
		} else if ( type == "spacer"  ){
			node.size = "20px";
		} else if ( type == "separator" ){
			node.size = "20px";
			node.style.lineColor = "gray";
			node.style.lineHeight = 1;
		} else {
			var colName = this.getNewColumnName(type);
			node.value = "";
			node.DBField = colName;
			if ( type == "label"){
				// node.label = "";
				// node.style.backgroundColor = "#ffffff";
			} else if ( type == "select"){
				// node.options = LOCALE[LANG].OPTION+" 1 @#@ "+LOCALE[LANG].OPTION+" 2 @#@ "+LOCALE[LANG].OPTION+" 3";
				// node.values = LOCALE[LANG].OPTION+" 1 @#@ "+LOCALE[LANG].OPTION+" 2 @#@ "+LOCALE[LANG].OPTION+" 3";
				// node.label = LOCALE[LANG].SELECT_LABEL + " : ";
				// node.style = {};
				// node.style.backgroundColor = "#ffffff";			
			} else if ( type == "textarea"){
					node.rows = 5;
			} else if ( type == "table"){
				// node = {id:nodeId, type:type, DBField:colName, 
				// 	rowCount: 3, columnCount:5, 
				node.DBField = colName;
				node.rowCount = 3;
				node.columnCount = 5;

					// rowLabels: LOCALE[LANG].ROW + " 1 @#@ "+LOCALE[LANG].ROW+" 2 @#@ "+LOCALE[LANG].ROW+" 3", 
					// columnLabels: LOCALE[LANG].TABLE +" @#@ "+LOCALE[LANG].COLUMN+" 1 @#@ "+LOCALE[LANG].COLUMN+" 2 @#@ "+LOCALE[LANG].COLUMN+" 3 @#@ "+LOCALE[LANG].COLUMN+" 4"};
				// }
			}
		}
		// if  ( type == "textfield" || type == "numberfield" || type == "checkbox" || type == "select" ) {
		if  ( type == "checkbox" ) {
				// node.label = LOCALE[LANG].CHECKBOX;
				// node.style = {};
				node.style.backgroundColor = "white";			
		}
		// if  ( type == "textfield" || type == "numberfield" || type == "textarea" ) {
		// 	// node.style = {};			
		// 	node.style.backgroundColor = "#fbfbfb";			
		// }
		return node;
	}



}

