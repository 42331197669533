import { NIconButton } from "./NIconButton";
import { NElement } from "./base/NElement";
import { NHTMLElement } from "./base/NHTMLElement";
import { NMainPanel } from "./base/NMainPanel";
import { NRow } from "./base/NRow";

export class NImage extends NElement
{
    mainDiv : NHTMLElement;
    canvas:HTMLCanvasElement;
    ctx:CanvasRenderingContext2D;
    njsCanvas:HTMLCanvasElement;
    chooserId : string;
    image : HTMLImageElement;
    base64Img : string;
    choosingFile : boolean;
    editorHeight : number;
    // btnOpen: NIconButton;
    menuDiv : NHTMLElement;

    constructor(value="") {
        super("image", {value:value});
        this.initMenu();
    }

    imageLoadedCallback:Function;

    createHTMLElement(id, config) : NHTMLElement
    {
        this.mainDiv = new NHTMLElement("DIV");
        this.image = new Image();
        this.mainDiv.appendChild(this.image);
        this.chooserId =  id+"Chooser";
        // var innerHTML=
        //     "<canvas style='overflow:scroll; width:100%; background:#eee' >"
        //     + "Your browser does not support the HTML5 canvas tag."
        //     +"<input type='file' id='"+this.chooserId+"' style='display:none; ' />";
        //     + "</canvas>";

        // this.mainDiv.innerHTML = innerHTML;
        // this.canvas = this.mainDiv.getElementsByTagName("canvas")[0];
        console.log("config = " , config);
        console.log("config.value = " , config.value);
        if ( config.value ) {
            var url:string = config.value ;
            if ( ! url.startsWith("URL(")) { 
                url = "URL("+config.value+")";
            }
            this.mainDiv.style.backgroundImage = url;
        }
        return this.mainDiv;
    }

    getMainElement = function(){
        // return this.mainDiv.getElementsByTagName("canvas")[0];
        return this.image;
    }

    setImageLoadedCallback(callback: Function) {
        this.imageLoadedCallback = callback;
    }

    initMenu = () =>
    {
        // this.mainDiv.style.border = "1px solid lightgray";
        

        this.canvas = this.mainDiv.getElementsByTagName("canvas")[0];
        this.menuDiv = new NHTMLElement("DIV");
        // this.menuDiv = document.createElement("DIV");  
        this.mainDiv.insertBefore(this.menuDiv, this.canvas);
        var editor = new NMainPanel(this.menuDiv);
        // editor.setBordersVisible(false);
        var row = editor.addRow(new NRow());
        row.style.backgroundColor = "#efefef";
        row.style.padding = "5px 0px";

        // this.btnOpen = new NIconButton("", "image").setStyle("width", "40px");
        // this.btnOpen.addOnClickEvent( () => this.onOpenImage());
        // row.addElement(this.btnOpen);

    }
    
    chooseImage = function(callback)
    {
        var chooser = <HTMLInputElement> document.getElementById(this.chooserId);
        this.choosingFile = true;
        chooser.addEventListener("change", (event) => {
            var file = chooser.files[0]; 
            var reader = new FileReader();
            reader.onload = () => {
                var image = reader.result;
                this.loadImage(image, callback);
                this.coordinates = null;
                // mainDiv.baseImage = image;
                this.image.src = image;
                this.imageLoadedCallback();
            };
            reader.readAsDataURL(file);
            
        } );		
        chooser.click();
        this.choosingFile = false;
    }

    onOpenImage =  () => {
        this.chooseImage( (base64Img) => {
            // this.image.src = base64Img;
        });
    }

    loadImage = (base64Img, callback) =>
    {
        return;
        console.log("in loadimage function");
        this.choosingFile = false;
        this.ctx = this.canvas.getContext("2d");
        var img = new Image();


        img.onload = () => 
        {
            console.log("loading image...");
            // set size proportional to image
            this.canvas.width = 800;
            this.canvas.height = this.canvas.width * (img.height / img.width);
            this.ctx.drawImage(img, 0, 0, this.canvas.width, this.canvas.height);
            // this.canvas.style.border = "1px solid lightgray";
            // this.btnOpen.setDisabled(true);
            this.njsParentElement.njsParentElement.style.height = this.canvas.height +"px";
            if ( callback ) {
                callback(this.canvas.toDataURL("image/jpeg"));
            }

            
        };
        img.src = base64Img;
        this.image = img;
        // mainDiv.base64Img = base64Img;
        console.log("end of image load");
    }

    setMenuVisible = (isVisible) => {
        this.menuDiv.style.display = isVisible ? "block" : "none";
    }
        
}





