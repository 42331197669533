import { NColorChooser } from "../../../components/src/NColorChooser";
import { LABEL_HEIGHT, NLabel } from "../../../components/src/NLabel";
import { NSeparator } from "../../../components/src/NSeparator";
import { NSpacer } from "../../../components/src/NSpacer";
import { NColumn } from "../../../components/src/base/NColumn";
import { UIModel } from "../../../model/src/UIModel";
import { LOCALE } from "../../resources/locale";
import { NEditor } from "./NEditor";


export class NContainerEditor extends NEditor
{
    addEditors() 
    {
        this.addSizeEditor();

        this.colProps.addElement(new NSpacer(20));

        if ( this.pNode.type == "row" ) {
            this.addHorizontalAlignmentEditor();
        } else {
            this.addVerticalAlignmentEditor();
        }

        this.colProps.addElement(new NSpacer(20));
        this.colProps.addElement(new NLabel("Background").setStyle("background-color", "lightsteelblue").setStyle("color", "white").setHorizontalAlignment("center").setHeight(LABEL_HEIGHT.MINI));        
        this.colProps.addElement(new NSpacer(20));

        this.addColorChooser(true, "#ffffff");

        this.addBackgroundImage();

        this.addBorderEditor();

        this.addFontChooser();

        // BUGGY !!!!!
                // // HorizontalAlignment
                // let hOptions = LOCALE[lang].LEFT+"@#@"+LOCALE[lang].CENTER+"@#@"+LOCALE[lang].RIGHT;
                // let hValues = "left@#@center@#@right";
                // let lstHorizontalAlignment = new NSelect(LOCALE[lang].HORIZONTAL_ALIGNMENT +" : ", hOptions, hValues, pNode.style.horizontalAlignment );
                // colProps.addElement(lstHorizontalAlignment);
                // lstHorizontalAlignment.getHTMLElement().addEventListener("change", (event)=>{
                //     uiModel.updateNodeStyle(pNode.id, "horizontalAlignment", (event.target as HTMLTextAreaElement).value);
                //     reloadCallback();
                // });
                // lstHorizontalAlignment.setStyle("color", "gray");
            
                // // VerticalAlignment
                // let vOptions = LOCALE[lang].TOP+"@#@"+LOCALE[lang].MIDDLE+"@#@"+LOCALE[lang].BOTTOM;
                // let vValues = "top@#@middle@#@bottom";
                // let lstVerticalAlignment = new NSelect(LOCALE[lang].VERTICAL_ALIGNMENT +" : ", vOptions, vValues, pNode.style.verticalAlignment );
                // colProps.addElement(lstVerticalAlignment);
                // lstVerticalAlignment.getHTMLElement().addEventListener("change", (event)=>{
                //     uiModel.updateNodeStyle(pNode.id, "verticalAlignment", (event.target as HTMLTextAreaElement).value);
                //     reloadCallback();
                // });
                // lstVerticalAlignment.setStyle("color", "gray");
        // BUGGY !!!!!
        

    }

}