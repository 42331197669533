

export class Fontselect 
{
    private original: HTMLSelectElement;
    private options: FontselectOptions;
    private active: boolean;
    private element: HTMLDivElement;
    private arrow: HTMLDivElement;
    private select: HTMLAnchorElement;
    private drop: HTMLDivElement;
    private results: HTMLUListElement;
    private visibleInterval: any;
  
    constructor(original: HTMLSelectElement, options: FontselectOptions) {
      this.original = original;
      this.options = options;
      this.active = true;
      this.setupHtml();
      this.getVisibleFonts();
      this.bindEvents();
  
      const font = this.original.value;
      if (font) {
        this.updateSelected();
        this.addFontLink(font);
      }
    }
  
    private bindEvents() {
      const self = this;
      // Close dropdown automatically on clicks outside dropdown
      document.addEventListener('click', (event) => {
        if (self.active && !self.element.contains(event.target as Node)) {
          self.toggleDrop();
        }
      });
  
      for (const li of this.results.querySelectorAll('li')) {
        li.addEventListener('click', this.selectFont.bind(this));
        li.addEventListener('mouseenter', this.activateFont.bind(this));
        li.addEventListener('mouseleave', this.deactivateFont.bind(this));
      }
  
      this.select.addEventListener('click', this.toggleDrop.bind(this));
      this.arrow.addEventListener('click', this.toggleDrop.bind(this));
    }
  
    public toggleDrop() {
      if (this.active) {
        this.element.classList.remove('font-select-active');
        this.drop.style.display = 'none';
        clearInterval(this.visibleInterval);
      } else {
        this.element.classList.add('font-select-active');
        this.drop.style.display = 'block';
        this.moveToSelected();
        this.visibleInterval = setInterval(this.getVisibleFonts.bind(this), 500);
      }
  
      this.active = !this.active;
    }
  
    private selectFont(event: Event) {
      const target = event.currentTarget as HTMLLIElement;
      const font = target.dataset.value;
      this.original.value = font;
      this.original.dispatchEvent(new Event('change'));
      this.updateSelected();
      this.toggleDrop();
    }
  
    private moveToSelected() {
      let li: HTMLLIElement;
      const font = this.original.value;
  
      if (font) {
        li = this.results.querySelector(`li[data-value="${font}"]`);
      } else {
        li = this.results.querySelector('li');
      }
  
      this.results.scrollTop = li.offsetTop;
      li.classList.add('active');
    }
  
    private activateFont(event: Event) {
      const target = event.currentTarget as HTMLLIElement;
      for (const li of this.results.querySelectorAll('li.active')) {
        li.classList.remove('active');
      }
      target.classList.add('active');
    }
  
    private deactivateFont(event: Event) {
      const target = event.currentTarget as HTMLLIElement;
      target.classList.remove('active');
    }
  
    private updateSelected() {
      const font = this.original.value;
      const readableFont = this.toReadable(font);
      const style = this.toStyle(font);
      this.select.querySelector('span').textContent = readableFont;
      this.select.style.fontFamily = style['font-family'];
      this.select.style.fontWeight = style['font-weight'];
    }
  
    public setupHtml() {
      this.original.innerHTML = '';
      this.original.style.display = 'none';
      this.element = document.createElement('div');
      this.element.id = `fontSelect-${this.original.id}`;
      this.element.classList.add(this.options.style);
  
      this.arrow = document.createElement('div');
      this.arrow.innerHTML = '<b></b>';
      this.select = document.createElement('a');
      this.select.innerHTML = `<span>${this.options.placeholder}</span>`;
  
      this.drop = document.createElement('div');
      this.drop.classList.add('fs-drop');
  
      this.results = document.createElement('ul');
      this.results.classList.add('fs-results');
      this.results.innerHTML = this.fontsAsHtml();
      this.drop.appendChild(this.results);
  
      this.element.appendChild(this.select);
      this.select.appendChild(this.arrow);
      console.log("this.drop : ", this.drop);
      this.element.appendChild(this.drop);
      this.original.parentNode?.insertBefore(this.element, this.original.nextSibling);
      // this.element.style.display = 'block'; // NSD
      this.drop.style.display = 'block';
    }
  
    private fontsAsHtml() {
      let h = '';
      for (const font of fonts) {
        const r = this.toReadable(font);
        const s = this.toStyle(font);
        h += `<li data-value="`+font+`" style="font-family: ${s['font-family']}; font-weight: ${s['font-weight']}">${r}</li>`;
      }
      return h;
    }
  
    private toReadable(font: string) {
      return font.replace(/[\+|:]/g, ' ');
    }
  
    private toStyle(font: string) {
      const t = font.split(':');
      return { 'font-family': this.toReadable(t[0]), 'font-weight': t[1] || '400' };
    }
  
    private getVisibleFonts() {
      if (this.drop.style.display === 'none') return;
  
      const fs = this;
      const top = this.results.scrollTop;
      let bottom = top + this.results.clientHeight;
  
      if (this.options.lookahead) {
        const li = this.results.querySelector('li');
        if (li) {
          const liHeight = li.clientHeight;
          bottom += liHeight * this.options.lookahead;
        }
      }
  
      for (const li of this.results.querySelectorAll('li')) {
        const ft = li.offsetTop + top;
        const fb = ft + li.clientHeight;
  
        if (fb >= top && ft <= bottom) {
          const font = li.dataset.value;
          fs.addFontLink(font);
        }
      }
    }
  
    private addFontLink(font: string) {
      const link = this.options.api + font;
  
      if (!document.querySelector(`link[href*='${font}']`)) {
        const lastLink = document.querySelector('link:last-of-type');
        if (lastLink) {
          const newLink = document.createElement('link');
          newLink.href = link;
          newLink.rel = 'stylesheet';
          newLink.type = 'text/css';
          lastLink.parentNode?.insertBefore(newLink, lastLink.nextSibling);
        }
      }
    }
  }
  
  interface FontselectOptions {
    style: string;
    placeholder: string;
    lookahead: number;
    api: string;
  }
  
  const fonts: string[] = [
      "Arial",  
      "Verdana",
      "Tahoma",
      "Trebuchet MS",
      "Times New Roman",
      "Georgia",
      "Garamond",
      "Courier New",
      "Brush Script MT",
      "Aclonica",
      "Allan",
      "Annie Use Your Telescope",
      "Anonymous Pro",
      "Allerta Stencil",
      "Allerta",
      "Amaranth",
      "Anton",
      "Architects Daughter",
      "Arimo",
      "Artifika",
      "Arvo",
      "Asset",
      "Astloch",
      "Bangers",
      "Bentham",
      "Bevan",
      "Bigshot One",
      "Bowlby One",
      "Bowlby One SC",
      "Brawler",
      "Cabin",
      "Calligraffitti",
      "Candal",
      "Cantarell",
      "Cardo",
      "Carter One",
      "Caudex",
      "Cedarville Cursive",
      "Cherry Cream Soda",
      "Chewy",
      "Coda",
      "Coming Soon",
      "Copse",
      "Corben",
      "Cousine",
      "Covered By Your Grace",
      "Crafty Girls",
      "Crimson Text",
      "Crushed",
      "Cuprum",
      "Damion",
      "Dancing Script",
      "Dawning of a New Day",
      "Didact Gothic",
      "Droid Sans",
      "Droid Sans Mono",
      "Droid Serif",
      "EB Garamond",
      "Expletus Sans",
      "Fontdiner Swanky",
      "Forum",
      "Francois One",
      "Geo",
      "Give You Glory",
      "Goblin One",
      "Goudy Bookletter 1911",
      "Gravitas One",
      "Gruppo",
      "Hammersmith One",
      "Holtwood One SC",
      "Homemade Apple",
      "Inconsolata",
      "Indie Flower",
      "IM Fell DW Pica",
      "IM Fell DW Pica SC",
      "IM Fell Double Pica",
      "IM Fell Double Pica SC",
      "IM Fell English",
      "IM Fell English SC",
      "IM Fell French Canon",
      "IM Fell French Canon SC",
      "IM Fell Great Primer",
      "IM Fell Great Primer SC",
      "Irish Grover",
      "Irish Growler",
      "Istok Web",
      "Josefin Sans",
      "Josefin Slab",
      "Judson",
      "Jura",
      "Just Another Hand",
      "Just Me Again Down Here",
      "Kameron",
      "Kenia",
      "Kranky",
      "Kreon",
      "Kristi",
      "La Belle Aurore",
      "Lato",
      "League Script",
      "Lekton",  
      "Limelight",  
      "Lobster",
      "Lobster Two",
      "Lora",
      "Love Ya Like A Sister",
      "Loved by the King",
      "Luckiest Guy",
      "Maiden Orange",
      "Mako",
      "Maven Pro",
      "Meddon",
      "MedievalSharp",
      "Megrim",
      "Merriweather",
      "Metrophobic",
      "Michroma",
      "Miltonian Tattoo",
      "Miltonian",
      "Modern Antiqua",
      "Monofett",
      "Molengo",
      "Mountains of Christmas",
      "Muli", 
      "Neucha",
      "Neuton",
      "News Cycle",
      "Nixie One",
      "Nobile",
      "Nova Cut",
      "Nova Flat",
      "Nova Mono",
      "Nova Oval",
      "Nova Round",
      "Nova Script",
      "Nova Slim",
      "Nova Square",
      "Nunito:light",
      "Nunito",
      "OFL Sorts Mill Goudy TT",
      "Old Standard TT",
      "Open Sans",
      "Open Sans Condensed",
      "Orbitron",
      "Oswald",
      "Over the Rainbow",
      "Reenie Beanie",
      "Pacifico",
      "Patrick Hand",
      "Paytone One", 
      "Permanent Marker",
      "Philosopher",
      "Play",
      "Playfair Display",
      "Podkova",
      "PT Sans",
      "PT Sans Narrow",
      "PT Sans Narrow:regular,bold",
      "PT Serif",
      "PT Serif Caption",
      "Puritan",
      "Quattrocento",
      "Quattrocento Sans",
      "Radley",
      "Raleway:100",
      "Redressed",
      "Rock Salt",
      "Rokkitt",
      "Ruslan Display",
      "Schoolbell",
      "Shadows Into Light",
      "Shanti",
      "Sigmar One",
      "Six Caps",
      "Slackey",
      "Smythe",
      "Sniglet",
      "Special Elite",
      "Stardos Stencil",
      "Sue Ellen Francisco",
      "Sunshiney",
      "Swanky and Moo Moo",
      "Syncopate",
      "Tangerine",
      "Tenor Sans",
      "Terminal Dosis Light",
      "The Girl Next Door",
      "Tinos",
      "Ubuntu",
      "Ultra",
      "Unkempt",
      "UnifrakturCook:bold",
      "UnifrakturMaguntia",
      "Varela",
      "Varela Round",
      "Vibur",
      "Vollkorn",
      "VT323",
      "Waiting for the Sunrise",
      "Wallpoet",
      "Walter Turncoat",
      "Wire One",
      "Yanone Kaffeesatz",
      "Yeseva One",
      "Zeyada"
];