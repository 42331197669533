
import {NElement} from "./base/NElement";
import { NHTMLElement } from "./base/NHTMLElement";

export class NNumberField extends NElement
{
    mainDiv: NHTMLElement;
    constructor(label=" ", value="") {
        super("numberfield", {label:label, value:value});
    }

    createHTMLElement(id, config) 
    {
        this.mainDiv = new NHTMLElement("DIV");  
        this.mainDiv.className = 'textfield mui-textfield mui-textfield--float-label';
        if ( config.label == "" ) 
        {
            this.mainDiv.style.padding = "0";
        }
        
        let valueStr = config.value ? "value='"+config.value+"'" : "";
        var inputDOM = "<input type='number' class='textfield' step='any' "+valueStr+">";
        var labelDOM = config.label ?  "<label >"+config.label+"</label>" : "";
        this.mainDiv.innerHTML = inputDOM + labelDOM;

        this.mainDiv.id = id;
        return this.mainDiv;
    }

    getMainElement = function(){
        return this.mainDiv.getElementsByTagName("input")[0];
    }

    getValue = function() : string
    {
        return this.getMainElement().value;
    }

    setValue = function(value: string)
    {
        this.getMainElement().value = value;
        return this;
    }


}
