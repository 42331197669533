import { NButton } from "../../../components/src/NButton";
import { NSelect } from "../../../components/src/NSelect";
import { NSpacer } from "../../../components/src/NSpacer";
import { NTable } from "../../../components/src/NTable";
import { NColumn } from "../../../components/src/base/NColumn";
import { UIModel } from "../../../model/src/UIModel";
import { LOCALE } from "../../resources/locale";
import { NEditor } from "./NEditor";


export class NTableEditor extends NEditor
{
    addEditors() {

        var rValues = "@#@1@#@2@#@3@#@4@#@5@#@6@#@7@#@8@#@9@#@10@#@11@#@12@#@13@#@14@#@15@#@16@#@17@#@18@#@19@#@20@#@21@#@22@#@23@#@24@#@25@#@26@#@27@#@28@#@29@#@30";
        var rowCount = this.colProps.addElement(new NSelect(LOCALE[this.lang].ROW_COUNT + " : ", rValues, rValues, this.pNode.rowCount+""));
        rowCount.getHTMLElement().addEventListener("change", (event)=>{
            this.uiModel.updateNode(this.pNode.id, "rowCount", (event.target as HTMLTextAreaElement).value);
            // this.m_isModelChanged = true;
            this.reloadCallback();
            this.colProps.clear();
            this.generateEditor(this.colProps, this.pNode, this.lang, this.uiModel,this.reloadCallback);
        });
        var tblRowLabels = new NTable(this.pNode.rowCount, 1, "", LOCALE[this.lang].ROW_LABELS);
        this.colProps.addElement(tblRowLabels);
        tblRowLabels.setValue(this.pNode.rowLabels);

        this.colProps.addElement(new NSpacer(30));


        var cValues = "@#@1@#@2@#@3@#@4@#@5@#@6@#@7@#@8@#@9@#@10@#@11@#@12@#@13@#@14@#@15@#@16@#@17@#@18@#@19@#@20@#@21@#@22@#@23@#@24@#@25@#@26@#@27@#@28@#@29@#@30";
        var columnCount = this.colProps.addElement(new NSelect(LOCALE[this.lang].COLUMN_COUNT +" : ", cValues, cValues, this.pNode.columnCount+""));
        columnCount.getHTMLElement().addEventListener("change", (event)=>{
            this.uiModel.updateNode(this.pNode.id, "columnCount", (event.target as HTMLTextAreaElement).value);
            // this.m_isModelChanged = true;
            this.reloadCallback();
            this.colProps.clear();
            this.generateEditor(this.colProps, this.pNode, this.lang, this.uiModel,this.reloadCallback);
        });

        var tblColumnLabels = new NTable(this.pNode.columnCount, 1, "", LOCALE[this.lang].COLUMN_LABELS);
        this.colProps.addElement(tblColumnLabels);
        tblColumnLabels.setValue(this.pNode.columnLabels);

        // var rowSetProps = this.colProps.addRow(new NRow().setHeight("40px"));
        this.colProps.addElement(new NSpacer(15));
        var btnSetOptions = this.colProps.addElement(new NButton(LOCALE[this.lang].APPLY));
        // rowSetProps.setHorizontalAlignment("right");
        btnSetOptions.getHTMLElement().addEventListener("click", (event)=>{
            this.uiModel.updateNode(this.pNode.id, "columnLabels", tblColumnLabels.getValue());
            this.uiModel.updateNode(this.pNode.id, "rowLabels", tblRowLabels.getValue());
            // this.m_isModelChanged = true;
            this.reloadCallback();
        });

        this.colProps.addElement(new NSpacer(30));

        this.addFontChooser();

    }

}