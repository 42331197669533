import {NElement} from "./base/NElement";
import { NHTMLElement } from "./base/NHTMLElement";

export class NFileChooser extends NElement
{
    mainDiv: NHTMLElement;
    fileChooser: HTMLInputElement;

    constructor(label=" ", value="", accept="") {
        super("filechooser", {label:label, value:value, accept: accept});
    }

    createHTMLElement(id, config) 
    {
        this.mainDiv = new NHTMLElement("DIV");  
        this.mainDiv.className = 'njs-label';
        this.fileChooser = <HTMLInputElement> new NHTMLElement("INPUT");
        this.fileChooser.type  = "file";
        this.fileChooser.accept  = config.accept;
        // fileChooser = "<input type='file' "+config.label+">";
        this.mainDiv.append(this.fileChooser);
    
        this.mainDiv.id = id;
        return this.mainDiv;
    
    }

    getMainElement = function(){
        return this.mainDiv.getElementsByTagName("input")[0];
    }

    addOnChangeEvent =  (event: any) => {
        this.fileChooser.addEventListener("change", event);
    }
    

}

