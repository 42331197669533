
import { NElement } from "./base/NElement";
import { NHTMLElement } from "./base/NHTMLElement";

export class NButton extends NElement
{
    // label: string;
    button: HTMLButtonElement;
    constructor(label = "Button") {
        super("button", {label:label});
        // this.label = label;
        // this.button = {};
    }

    createHTMLElement(id, config) : NHTMLElement
    {
        // let mainDiv: {[k: string]: any} = {};
        let mainDiv: NHTMLElement = new NHTMLElement("DIV");
    
        this.button = <HTMLButtonElement>document.createElement("BUTTON");  
        // this.button.className = 'mui-btn mui-btn--primary mui-btn--small';
        this.button.className = 'njs-button';
        this.button.style.width = "100%";
        this.button.style.height = "40px";
        this.button.style.margin = "0px";
        this.button.innerHTML = config.label;
        // mainDiv.type = "button";
        // mainDiv.id = id;
        mainDiv.appendChild(this.button);
        // methods
        return mainDiv;
    }

    getMainElement(){
        return this.button;
    }

    addOnClickEvent =  (event: any) => {
        this.button.addEventListener("click", event);
    }
    
    setDisabled = (enabled:boolean) => {
        this.button.disabled = enabled;
    }

}