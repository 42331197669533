import { NColorChooser } from "../../../components/src/NColorChooser";
import { NSpacer } from "../../../components/src/NSpacer";
import { NColumn } from "../../../components/src/base/NColumn";
import { NRow } from "../../../components/src/base/NRow";
import { UIModel } from "../../../model/src/UIModel";
import { LOCALE } from "../../resources/locale";
import { NEditor } from "./NEditor";


export class NButtonEditor extends NEditor
{
    addEditors() 
    {
        this.addLabelEditor();

        this.colProps.addElement(new NSpacer(20));

        this.addColorChooser(false, null);

        this.addColorChooser(true, null);

        this.addFontChooser();

    }

}