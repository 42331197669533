import { NColorChooser } from "../../../components/src/NColorChooser";
import { NColumn } from "../../../components/src/base/NColumn";
import { NRow } from "../../../components/src/base/NRow";
import { UIModel } from "../../../model/src/UIModel";
import { LOCALE } from "../../resources/locale";
import { NEditor } from "./NEditor";


export class NCheckboxEditor extends NEditor
{
    addEditors() 
    {
        this.addLabelEditor();

        this.addFontChooser();

    }

}