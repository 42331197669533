export class NHTMLElement extends HTMLElement
{
    // type: string = null;    
    // getMainElement: Function = null;
    // addOnClickEvent: Function = null;
    // parentHTMLElement: NHTMLElement = null;
    // dom: string = null;
    // nsdjs: {
    //     horizontalAlignment:string ,
    //     verticalAlignment:string
    // } = null;

    // njsNodeId: string;
    // njsNodeType: string;
    // njsParentElement: NHTMLElement;
    // njsParentNodeId: string;
    // njsIsDropArea: boolean;

    constructor(tag) {
        super();
        let el =  document.createElement(tag);
        el.nsdjs = {};
        return el;
    }
    
}


customElements.define('n-html-element', NHTMLElement);
