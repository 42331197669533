
import {NElement} from "./base/NElement";

export class NColorChooser extends NElement
{
    // label: string;
    // value: string;
    mainDiv: any;
    constructor(label="Label", value = "#4169e1") {
        super("colorpicker", {label:label, value:value});
        // this.label = label;
        // this.value = value;
    }

    createHTMLElement(id, config) 
    {
        this.mainDiv = document.createElement("DIV");  
        var colorHTML =  "<input type='color' value='"+config.value+"'>";
        var labelHTML =  "<label class='njs-label' style='padding-left:6px'>"+config.label+"</label>";
        this.mainDiv.innerHTML = colorHTML + labelHTML;
        // this.mainDiv.id = id;
        return this.mainDiv ;
    }

    getMainElement = function(){
        return this.mainDiv.getElementsByTagName("input")[0];
    }

    getValue() : string {
        return this.getMainElement().value;
    }

    setValue(value: string){
        this.getMainElement().value = value;
        return this;
    }

}
