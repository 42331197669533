import {NElement} from "./base/NElement";
import { NHTMLElement } from "./base/NHTMLElement";

export class NIconButton extends NElement
{
    // label: string;
    // icon;
    // align;
    // tooltipText
    button: NHTMLElement;
    constructor(label="Button", icon: any, align = 'left', tooltipText=undefined) {
        super("iconbutton", {label:label, icon:icon, align:align, tooltipText:tooltipText});
        // this.label = label;
        // this.icon = icon;
        // this.align = align;
        // this.tooltipText = tooltipText;
    }

    createHTMLElement(id, config) 
    {
        // let button: {[k: string]: any} = {};
        // let button: NHTMLElement;

        this.button = new NHTMLElement( "BUTTON");  
        // button.type = "iconbutton";
        this.button.id = id;
        this.button.className = "NSDJS iconButton tooltip";
        this.button.style.width = "100%";
    
        var pLabel1 = config.label;
        var pLabel2; 
        var label2 = "";
        if ( config.label.length > 14 ) {
            var res = config.label.split(' ');
            if ( res.length == 2 ) {
                pLabel1 = res[0];
                pLabel2 = res[1];
            }
        }
        let label= " <label style='float:left; margin:0 8; line-height:22px'>"+config.label+"</label>";
        let buttonHeight = "48px";
        if ( pLabel2 ){
            label2= "<div><label style='left:0; right:0; margin:0 8; line-height:22px'>"+pLabel2+"</label></div>";
            buttonHeight = "80px";
        }
        // button = "<button  style='width:100%;'>";
        let topClass = "";
        if ( config.label != "" ) {
            topClass = "top";
        }
        if ( config.icon ) {
            if ( config.align == 'right' ) {
                config.icon = "<div class='njs-"+config.icon+"-ico "+topClass+"' style='float:right'></div>";
            } else if (config.align == 'top') {
                config.icon = "<div style='text-align:-webkit-center; height:22px'><div class='njs-"+config.icon+"-ico "+topClass+"' ></div></div>";
                // button = "<button  style='height:"+buttonHeight+"; background:white; color:gray'>";
                this.button.style.height = buttonHeight;
                this.button.style.background = "white";
                this.button.style.color = "gray";
            } else {
                config.icon = "<div class='njs-"+config.icon+"-ico "+topClass+"' style='float:left;'></div>";
            }
        } else {
            config.icon = "";
        }
        if ( config.label != "" ) {
            if ( config.align == 'right' ) {
                label= " <label style='float:right; margin:0 8; line-height:22px'>"+config.label+"</label>";
            } else if (config.align == 'top') {
                label= "<label style='left:0; right:0; margin:0 8; line-height:22px'>"+pLabel1+"</label>";
            }
        } else {
            label="";
        }
        var tooltip = "";
        if ( typeof config.tooltipText != "undefined" ) {
            tooltip = "<span class='tooltiptext'>"+config.tooltipText+"</span>"
        }
        var innerHTML = 
            // button
            config.icon
            + label
            + label2
            + tooltip
            // +"</button>";
    
        this.button.innerHTML = innerHTML;
    
        // this.id = id;
        // addOnClickEvent(this);
        // this.type = "iconbutton"
    
        // this.button.addOnClickEvent =  (event: any) => {
        //     this.button.addEventListener("click", event);
        // }
        return this.button;
    
    }


    // getMainElement(){
    //     return this.getHTMLElement();
    // }

    setDisabled = (enabled:boolean) => {
        var button = this.button.getElementsByTagName("button")[0];
        // NSD TODO 
        // button.disabled = enabled;
    }


    addOnClickEvent =  (event: any) => {
        this.button.addEventListener("click", event);
    }

}


