import { NCheckbox } from "../../../components/src/NCheckbox";
import { NColorChooser } from "../../../components/src/NColorChooser";
import { NFontChooser } from "../../../components/src/NFontChooser";
import { LABEL_HEIGHT, NLabel } from "../../../components/src/NLabel";
import { NNumberField } from "../../../components/src/NNumberField";
import { NSelect } from "../../../components/src/NSelect";
import { NSeparator } from "../../../components/src/NSeparator";
import { NSpacer } from "../../../components/src/NSpacer";
import { NTextField } from "../../../components/src/NTextField";
import { NColumn } from "../../../components/src/base/NColumn";
import { NRow } from "../../../components/src/base/NRow";
import { DNode } from "../../../model/src/DNode";
import { UIModel } from "../../../model/src/UIModel";
import { LOCALE } from "../../resources/locale";

export abstract class NEditor 
{
    protected colProps:NColumn;
    protected pNode:DNode;
    protected lang:string;
    protected uiModel:UIModel;
    protected reloadCallback:Function;

    generateEditor(colProps:NColumn, pNode:DNode, lang:string, uiModel:UIModel, reloadCallback:Function) 
    {
        this.colProps = colProps;
        this.pNode = pNode;
        this.lang = lang;
        this.uiModel = uiModel;
        this.reloadCallback = reloadCallback;
        
        this.addTitle();
        // this.colProps.addElement(new NSeparator());
        colProps.addElement(new NSpacer(20));

        this.addDOMIdEditor();
        
        colProps.addElement(new NSpacer(10));

        this.addEditors();
    }

    protected abstract addEditors();

    addTitle()
    {
        let lblTitle = new NLabel(this.pNode.type.toUpperCase());
        lblTitle.setStyle("backgroundColor", "#eee");
        lblTitle.setHorizontalAlignment("middle");
        this.colProps.addElement(lblTitle);
    }

    addDOMIdEditor()
    {
        let txtLabel:NTextField = new NTextField(LOCALE[this.lang].DOM_ID, "");
        this.colProps.addElement(txtLabel);
        if ( this.pNode.domId )  txtLabel.setValue(this.pNode.domId);
        txtLabel.getHTMLElement().addEventListener("keyup", (event)=>{
            this.uiModel.updateNode(this.pNode.id, "domId", (event.target as HTMLTextAreaElement).value);
            // reloadCallback();
        });
    }

    addSizeEditor()
    {
        let txtLabel = new NTextField( this.pNode.type == "row"? LOCALE[this.lang].HEIGHT : LOCALE[this.lang].WIDTH, " ");
        this.colProps.addElement(txtLabel);
        if ( this.pNode.style )  txtLabel.setValue( this.pNode.type == "row" ? this.pNode.style.height :  this.pNode.style.width);
        txtLabel.getHTMLElement().addEventListener("keyup", (event)=>{
            this.uiModel.updateNodeStyle(this.pNode.id, this.pNode.type == "row" ? "height" : "width", (event.target as HTMLTextAreaElement).value);
            this.reloadCallback();
        });
    }


    addLabelEditor()
    {
        let txtLabel = new NTextField(LOCALE[this.lang].LABEL, "");
        this.colProps.addElement(txtLabel);
        if ( this.pNode.label )  txtLabel.setValue(this.pNode.label);
        txtLabel.getHTMLElement().addEventListener("keyup", (event)=>{
            this.uiModel.updateNode(this.pNode.id, "label", (event.target as HTMLTextAreaElement).value);
            this.reloadCallback();
        });
    }

    addHeightEditor() {
        let sOptions = "Mini@#@Midi@#@Normal@#@Big@#@Bigger";
        let sValues = LABEL_HEIGHT.MINI+"@#@"+LABEL_HEIGHT.MIDI+"@#@"+LABEL_HEIGHT.NORMAL+"@#@"+LABEL_HEIGHT.BIG+"@#@"+LABEL_HEIGHT.BIGGER;
        let currentHeight = this.pNode.style.height ? this.pNode.style.height : LABEL_HEIGHT.NORMAL;
        let list :NSelect = new NSelect("Height : ", sOptions, sValues, currentHeight);
        this.colProps.addElement(list);
        list.getHTMLElement().addEventListener("change", (event)=>{
            this.uiModel.updateNodeStyle(this.pNode.id, "height", (event.target as HTMLTextAreaElement).value);
            this.uiModel.updateNodeStyle(this.pNode.id, "lineHeight", (event.target as HTMLTextAreaElement).value);
            this.reloadCallback();
        });

    }

    addTextAlignmentEditor()
    {
        let sOptions = LOCALE[this.lang].LEFT+"@#@"+LOCALE[this.lang].CENTER+"@#@"+LOCALE[this.lang].RIGHT+"@#@"+LOCALE[this.lang].JUSTIFY;
        let sValues = "left@#@center@#@right@#@justify";
        let lstHorizontalAlignment = new NSelect(LOCALE[this.lang].TEXT_ALIGNMENT +" : ", sOptions, sValues, this.pNode.style.textAlign );
        this.colProps.addElement(lstHorizontalAlignment);
        lstHorizontalAlignment.getHTMLElement().addEventListener("change", (event)=>{
            this.uiModel.updateNodeStyle(this.pNode.id, "textAlign", (event.target as HTMLTextAreaElement).value);
            this.reloadCallback();
        });
        lstHorizontalAlignment.setStyle("color", "gray");
    }

    addVerticalAlignmentEditor()
    {
        let sOptions = LOCALE[this.lang].TOP+"@#@"+LOCALE[this.lang].CENTER+"@#@"+LOCALE[this.lang].BOTTOM;
        let sValues = "top@#@center@#@bottom";
        let lstAlignment = new NSelect(LOCALE[this.lang].CONTENT_ALIGNMENT +" : ", sOptions, sValues, this.pNode.style.verticalAlignment );
        this.colProps.addElement(lstAlignment);
        lstAlignment.getHTMLElement().addEventListener("change", (event)=>{
            this.uiModel.updateNodeStyle(this.pNode.id, "verticalAlignment", (event.target as HTMLTextAreaElement).value);
            this.reloadCallback();
        });
        lstAlignment.setStyle("color", "gray");

    }

    addHorizontalAlignmentEditor()
    {
        let sOptions = LOCALE[this.lang].LEFT+"@#@"+LOCALE[this.lang].CENTER+"@#@"+LOCALE[this.lang].RIGHT;
        let sValues = "left@#@center@#@right";
        let lstAlignment = new NSelect(LOCALE[this.lang].CONTENT_ALIGNMENT +" : ", sOptions, sValues, this.pNode.style.horizontalAlignment );
        this.colProps.addElement(lstAlignment);
        lstAlignment.getHTMLElement().addEventListener("change", (event)=>{
            this.uiModel.updateNodeStyle(this.pNode.id, "horizontalAlignment", (event.target as HTMLTextAreaElement).value);
            this.reloadCallback();
        });
        lstAlignment.setStyle("color", "gray");

                        // let hOptions = LOCALE[lang].LEFT+"@#@"+LOCALE[lang].CENTER+"@#@"+LOCALE[lang].RIGHT;
                // let hValues = "left@#@center@#@right";
                // let lstHorizontalAlignment = new NSelect(LOCALE[lang].HORIZONTAL_ALIGNMENT +" : ", hOptions, hValues, pNode.style.horizontalAlignment );
                // colProps.addElement(lstHorizontalAlignment);
                // lstHorizontalAlignment.getHTMLElement().addEventListener("change", (event)=>{
                //     uiModel.updateNodeStyle(pNode.id, "horizontalAlignment", (event.target as HTMLTextAreaElement).value);
                //     reloadCallback();
                // });
                // lstHorizontalAlignment.setStyle("color", "gray");

    }

    addColorChooser(isBackground:boolean, defaultColor:string)
    {
        let txtColor :NColorChooser = new NColorChooser( LOCALE[this.lang].BACKGROUND_COLOR, this.pNode.style.backgroundColor ? this.pNode.style.backgroundColor : defaultColor);
        if ( ! isBackground ) {
            txtColor = new NColorChooser( LOCALE[this.lang].TEXT_COLOR, this.pNode.style.color ? this.pNode.style.color : defaultColor);
        }
        this.colProps.addElement(txtColor);
        txtColor.style.color="gray";
        const propName = isBackground ? "backgroundColor" : "color";
        txtColor.getMainElement().addEventListener('input', (event)=> {
            this.uiModel.updateNodeStyle(this.pNode.id, propName, (event.target as HTMLTextAreaElement).value);
            this.reloadCallback();
        });

    }

    addFontChooser()
    {
        this.colProps.addElement(new NSpacer(20));
        this.colProps.addElement(new NLabel("Text").setStyle("background-color", "lightsteelblue").setStyle("color", "white").setHorizontalAlignment("center").setHeight(LABEL_HEIGHT.MINI));        
        this.colProps.addElement(new NSpacer(20));

        let fontChooser = new NFontChooser();
        fontChooser.getMainElement().addEventListener("change", (event)=>{
            this.uiModel.updateNodeStyle(this.pNode.id, "fontFamily", (event.target as HTMLTextAreaElement).value);
            this.reloadCallback();
        });
        this.colProps.addElement(fontChooser);

        this.colProps.addElement(new NSpacer(10));

        let rowFont1 = new NRow();

        let chkBold = new NCheckbox("Bold");
        chkBold.addOnClickEvent(()=>{
            this.uiModel.updateNodeStyle(this.pNode.id, "fontWeight", chkBold.isSelected() ? "bold" : "" );
            this.reloadCallback();
        })
        chkBold.getHTMLElement().style.width = "90px";
        rowFont1.addElement(chkBold);

        let chkItalic = new NCheckbox("Italic");
        chkItalic.addOnClickEvent(()=>{
            this.uiModel.updateNodeStyle(this.pNode.id, "fontStyle", chkItalic.isSelected() ? "italic" : "");
            this.reloadCallback();
        })
        chkItalic.getHTMLElement().style.width = "90px";
        rowFont1.addElement(chkItalic);

        let rowFont2 = new NRow();

        let fontSizer = new NNumberField("Font Size", this.pNode.style.fontSize);
        fontSizer.setValue(this.pNode.style.fontSize);
        fontSizer.getHTMLElement().addEventListener("change", (event)=>{
            this.uiModel.updateNodeStyle(this.pNode.id, "fontSize", (event.target as HTMLTextAreaElement).value + "px");
            this.reloadCallback();
        });
        rowFont2.addElement(fontSizer);
        rowFont2.addElement(new NLabel(" px").setHeight(LABEL_HEIGHT.BIG));

        let rowFont3 = new NRow();

        let sOptions = "None@#@Underline@#@Overline@#@Line-through@#@Underline+Overline";
        let sValues = "none@#@underline@#@overline@#@line-through@#@underline overline";
        let lstStyle :NSelect = new NSelect("Decoration : ", sOptions, sValues, this.pNode.style.borderStyle);
        this.colProps.addElement(lstStyle);
        lstStyle.getHTMLElement().addEventListener("change", (event)=>{
            this.uiModel.updateNodeStyle(this.pNode.id, "textDecoration", (event.target as HTMLTextAreaElement).value);
            this.reloadCallback();
        });
        rowFont3.addElement(lstStyle);


        this.colProps.addElement(rowFont1);
        this.colProps.addElement(rowFont2);
        this.colProps.addElement(rowFont3);

    }

    addBackgroundImage() 
    {
        let txtURL:NTextField = new NTextField("Backgound image URL", "");
        this.colProps.addElement(txtURL);
        if ( this.pNode.style.backgroundImage )  txtURL.setValue(this.pNode.style.backgroundImage);
        txtURL.getHTMLElement().addEventListener("keyup", (event)=>{
            var url:string =  (event.target as HTMLTextAreaElement).value.trim() ;
            if ( ! url.startsWith("URL(")) { 
                url = "URL("+url+")";
            }

            this.uiModel.updateNodeStyle(this.pNode.id, "backgroundImage", url );
            this.uiModel.updateNodeStyle(this.pNode.id, "backgroundRepeat", "no-repeat" );
            this.reloadCallback();
        });

        this.colProps.addElement(new NSpacer(20));

        let sOptions = "auto@#@contain@#@cover@#@stretch";
        let sValues = "auto@#@contain@#@cover@#@100% 100%";
        let lstSize :NSelect = new NSelect("Background image size : ", sOptions, sValues, this.pNode.style.backgroundSize);
        this.colProps.addElement(lstSize);
        lstSize.getHTMLElement().addEventListener("change", (event)=>{
            this.uiModel.updateNodeStyle(this.pNode.id, "backgroundSize", (event.target as HTMLTextAreaElement).value);
            this.reloadCallback();
        });

        this.colProps.addElement(new NSpacer(10));
        let chkFixed = new NCheckbox("Fix background image");
        this.colProps.addElement(chkFixed);
        chkFixed.addOnClickEvent(()=>{
            this.uiModel.updateNodeStyle(this.pNode.id, "background-attachment", chkFixed.isSelected() ? "fixed" : "" );
            this.reloadCallback();
        })

        this.colProps.addElement(new NSpacer(10));

        let pOptions = "none@#@Wavy@#@Rhombus@#@Zigzag";
        let pValues = "none@#@wavy@#@rhombus@#@zigzag";
        let lstPattern :NSelect = new NSelect("Background pattern : ", pOptions, pValues, this.pNode.style.backgroundPattern);
        this.colProps.addElement(lstPattern);
        lstPattern.getHTMLElement().addEventListener("change", (event)=>{
            this.uiModel.updateNodeStyle(this.pNode.id, "backgroundPattern", (event.target as HTMLTextAreaElement).value);
            this.reloadCallback();
        });

    }

    addBorderEditor() 
    {
        this.colProps.addElement(new NSpacer(20));
        this.colProps.addElement(new NLabel("Border").setStyle("background-color", "lightsteelblue").setStyle("color", "white").setHorizontalAlignment("center").setHeight(LABEL_HEIGHT.MINI));        
        this.colProps.addElement(new NSpacer(20));

        let txtColor :NColorChooser = new NColorChooser( "Border Color", this.pNode.style.borderColor ? this.pNode.style.borderColor : "none");
        this.colProps.addElement(txtColor);
        txtColor.style.color="gray";
        txtColor.getMainElement().addEventListener('input', (event)=> {
            this.uiModel.updateNodeStyle(this.pNode.id, "borderColor", (event.target as HTMLTextAreaElement).value);
            this.reloadCallback();
        });

        let wOptions = "1@#@2@#@3@#@4@#@5@#@6@#@7@#@8@#@9@#@10";
        let wValues = "1px@#@2px@#@3px@#@4px@#@5px@#@6px@#@7px@#@8px@#@9px@#@10px";
        let lstWidth :NSelect = new NSelect("Border width : ", wOptions, wValues, this.pNode.style.borderWidth);
        this.colProps.addElement(lstWidth);
        lstWidth.getHTMLElement().addEventListener("change", (event)=>{
            this.uiModel.updateNodeStyle(this.pNode.id, "borderWidth", (event.target as HTMLTextAreaElement).value);
            this.reloadCallback();
        });

        let sOptions = "none@#@solid@#@dotted@#@dashed@#@double";
        let sValues = "none@#@solid@#@dotted@#@dashed@#@double";
        let lstStyle :NSelect = new NSelect("Border style : ", sOptions, sValues, this.pNode.style.borderStyle);
        this.colProps.addElement(lstStyle);
        lstStyle.getHTMLElement().addEventListener("change", (event)=>{
            if ( ! this.pNode.style.borderWidth ) {
                this.uiModel.updateNodeStyle(this.pNode.id, "borderWidth", "1px");
            }
            this.uiModel.updateNodeStyle(this.pNode.id, "borderStyle", (event.target as HTMLTextAreaElement).value);
            this.reloadCallback();
        });

    }



}