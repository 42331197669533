
import {NElement} from "./base/NElement";
import {NTextField} from "./NTextField";
import {NLabel} from "./NLabel";
import {NMainPanel} from "./base/NMainPanel";
import { NHTMLElement } from "./base/NHTMLElement";
import { NRow } from "./base/NRow";
import { NColumn } from "./base/NColumn";

export class NTable extends NElement
{
    // rowCount: number;
    // colCount: number;
    rowLabels: string;
    columnLabels: string;
    // mainDiv: {[k: string]: any} = {};
    mainDiv: NHTMLElement;
    constructor(rowCount, colCount, rowLabels, columnLabels) {
        super("table", {rowCount:rowCount, colCount:colCount, rowLabels:rowLabels, columnLabels:columnLabels});
        // this.rowCount = rowCount;
        // this.colCount = colCount;
        // this.rowLabels = rowLabels;
        // this.columnLabels = columnLabels;
    }

    createHTMLElement(id, config) 
    {
        // var id = "nsdjsTblId" + NBase.elCount++;
        // this.mainDiv.id = id;
        this.type = "table";
        config.rowCount = +config.rowCount + 1; // add first row for column labels
        // console.log("columnLabels = ", columnLabels);
        if ( config.columnLabels ) {
            var columnLabelsArr = config.columnLabels.split('@#@');
            // console.log("columnLabelsArr = ", columnLabelsArr);
        }

        if ( config.rowLabels ) {
            var rowLabelsArr = config.rowLabels.split('@#@');
        }

        this.mainDiv = new NHTMLElement( "DIV");  
        var table = new NMainPanel(this.mainDiv); 
        var columnLabelsEmpty = true;
        var firstRow : NRow = null;
        for ( var r = 0; r < config.rowCount; r++ ) {
            var row = table.addRow( new NRow().setHeight("32px") );
            if ( r == 0 ) {
                var firstRow = row;
                row.getHTMLElement().style.backgroundColor = "#efefef";
                row.getHTMLElement().style.borderTop = "1px solid gray";
                row.getHTMLElement().style.textAlign = "center";
            }
            for ( var c = 0; c < config.colCount; c++ ) {
                var col = row.addColumn( new NColumn().setWidth((100/config.colCount) + "%") )
                col.getHTMLElement().style.borderRight = "1px solid gray"
                if ( c == 0 ) {
                    col.getHTMLElement().style.borderLeft = "1px solid gray"
                    if ( r > 0 ) {
                        if ( rowLabelsArr && rowLabelsArr[r-1]) {
                            col.addElement(new NLabel("&nbsp;"+rowLabelsArr[r-1].trim() ));
                            col.getHTMLElement().style.borderBottom = "1px solid gray"
                            col.getHTMLElement().style.backgroundColor = "#efefef"
                        } else {
                            col.addElement(new NTextField(""));
                            col.getHTMLElement().style.backgroundColor = "white"
                        }
                    } else {
                        if ( rowLabelsArr && rowLabelsArr[0] && rowLabelsArr[0] != "" ) {
                            col.getHTMLElement().style.textAlign = "left";
                            col.getHTMLElement().style.paddingLeft = "2px";
                        }
                    }
                }
                if ( r == 0 ) {
                    if ( columnLabelsArr  && columnLabelsArr[c]) {
                        col.addElement(new NLabel(columnLabelsArr[c].trim() ));
                        columnLabelsEmpty = false;
                    }
                    col.getHTMLElement().style.borderBottom = "1px solid gray"
                } else {
                    if ( c != 0 ) {
                        col.addElement(new NTextField(""));
                        col.getHTMLElement().style.backgroundColor = "white"
                    }
                    if ( r == config.rowCount -1 ){
                        col.getHTMLElement().style.borderBottom = "1px solid gray"
                    }
                }
            }
        }
        if( columnLabelsEmpty && firstRow )
        {
            firstRow.style.height = '0px';
        }
        
        // this.mainDiv.dom =  this.mainDiv.innerHTML;
        this.mainDiv.id =  id;
        this.rowLabels = config.rowLabels;
        this.columnLabels = config.columnLabels;
        return  this.mainDiv;
    }

    getValue = function()
    {
        var arrInputs = this.mainDiv.getElementsByTagName("input");;
        var strValues;
        for ( var i = 0; i < arrInputs.length; i++ ) {
            if ( typeof strValues == 'undefined' ) {
                strValues = arrInputs[i].value ;
            } else {
                strValues = strValues + "@#@" + arrInputs[i].value ;
            }
        }
        return strValues;
    }

    setValue = function(pValue)
    {
        var arrInputs = this.mainDiv.getElementsByTagName("input");;
        if ( typeof pValue != 'undefined' ) {
            var arrValues = pValue.split('@#@');
            for ( var i = 0; i < arrInputs.length; i++ ) {
                if ( arrValues && arrValues[i] ) {
                    arrInputs[i].value  = " "+arrValues[i].trim();
                }
            }    
        }    
    }

}
