import { NContainer } from "./NContainer";
import {NMainPanel} from "./NMainPanel";
// import { NHTMLElement } from "./NHTMLElement";

export abstract class NElement {

    type : string ;

    private htmlElement: HTMLElement;
    // private htmlElement: {[k: string]: any} = {};
    // private nHtmlElement: NHTMLElement;
    // private mainDiv: HTMLElement;
    nsdjs = {};
    // parentElement: NElement = null;

    njsNodeType: string;
    njsNodeId: string;
    njsParentNodeId: string;
    njsParentElement: NContainer;
    njsIsDropArea: boolean;

    style: {[k: string]: any} = {};
    domId: string;
    // njsIsDropArea: boolean;
    draggable: boolean;

    originalBorderRight:string; // set back to original after selection
    originalBorderTop:string; // set back to original after selection

    constructor(type:string, config:any = null) 
    {
        this.type = type;
        if ( config && config.domId ) {
            this.domId = config.domId;
        } else {
            this.domId =  NMainPanel.getFormId()+"_"+type + NMainPanel.getElementCount();
        }
        this.htmlElement = this.createHTMLElement(this.domId, config);
        this.njsNodeType = type;
        this.htmlElement.id = this.domId;
    }
    

    abstract createHTMLElement(id:string, config:{}) : HTMLElement;

    // abstract getDefaultProperties() : {};

    getHTMLElement = ():HTMLElement =>
    {
        return this.htmlElement;
    }

    createNJSProps = ():void =>
    {
        // this.getHTMLElement().nsdjs.ehkjaer = "ffdafdf";
        
    }

    setStyle (name:string, value:string) {
        var mainElement = this.getHTMLElement();
        mainElement.style[name] = value;
        return this;
    }

    setAllStyle = function(style:string) {
        var mainElement =  this.getHTMLElement();
        Object.assign(mainElement.style, style);
        return this;
    }    

    clear = () => {
        this.getHTMLElement().innerHTML = "";
    }

}



