import { NColorChooser } from "../../../components/src/NColorChooser";
import { NSelect } from "../../../components/src/NSelect";
import { NColumn } from "../../../components/src/base/NColumn";
import { NRow } from "../../../components/src/base/NRow";
import { UIModel } from "../../../model/src/UIModel";
import { LOCALE } from "../../resources/locale";
import { NEditor } from "./NEditor";


export class NSeparatorEditor extends NEditor
{
    addEditors() 
    {
        var cValues = "@#@1@#@2@#@3@#@4@#@5@#@6@#@7@#@8@#@9@#@10";
        var lineHeight = this.colProps.addRow(new NRow().setHeight("40px")).addColumn(new NColumn().setWidth('100%')).addElement(new NSelect(LOCALE[this.lang].LINE_HEIGHT + " : ", cValues, cValues, this.pNode.style.lineHeight+""));
        lineHeight.getHTMLElement().addEventListener("change", (event)=>{
            this.uiModel.updateNodeStyle(this.pNode.id, "lineHeight", (event.target as HTMLTextAreaElement).value);
            this.reloadCallback();
        });
        var lineColor = this.colProps.addRow(new NRow().setHeight("40px")).addColumn(new NColumn().setWidth('100%')).addElement(new NColorChooser(LOCALE[this.lang].LINE_COLOR, this.pNode.style.lineColor));
        lineColor.getHTMLElement().addEventListener("change", (event)=>{
            this.uiModel.updateNodeStyle(this.pNode.id, "lineColor", (event.target as HTMLTextAreaElement).value);
            this.reloadCallback();
        });

    }

}