import { NButton } from "../../../components/src/NButton";
import { NTable } from "../../../components/src/NTable";
import { NColumn } from "../../../components/src/base/NColumn";
import { NRow } from "../../../components/src/base/NRow";
import { UIModel } from "../../../model/src/UIModel";
import { LOCALE } from "../../resources/locale";
import { NEditor } from "./NEditor";


export class NSelectEditor extends NEditor
{
    addEditors() 
    {
        this.addLabelEditor();
        
        var tblOptions = new NTable(11, 1, "", LOCALE[this.lang].OPTIONS_LIST);
        this.colProps.addRow(new NRow().setHeight("20px")); //space
        this.colProps.addRow(new NRow().setHeight("400px")).addColumn(new NColumn().setWidth('100%')).addElement(tblOptions);
        tblOptions.setValue(this.pNode.options);

        var rowSetProps = this.colProps.addRow(new NRow());
        var btnSetOptions = rowSetProps.addElement(new NButton(LOCALE[this.lang].APPLY));
        rowSetProps.setHorizontalAlignment("right");
        btnSetOptions.getHTMLElement().addEventListener("click", (event)=>{
            this.uiModel.updateNode(this.pNode.id, "options", tblOptions.getValue());
            this.uiModel.updateNode(this.pNode.id, "values", tblOptions.getValue());
            this.reloadCallback();
        });

        this.addFontChooser();


    }

}