
import {NElement} from "./base/NElement";
import { NHTMLElement } from "./base/NHTMLElement";

export class NTextarea extends NElement
{
    mainDiv: NHTMLElement;
    textarea : HTMLTextAreaElement;
    constructor(label?, rows?) {
        super("textarea", {label: label ?? "", rows: rows ?? 5} );
    }

    createHTMLElement(id, config) 
    {
        this.mainDiv = new NHTMLElement("DIV");
        this.mainDiv.className = 'textareaDiv mui-textfield';
        // let textarea: {[k: string]: any} = {};

        this.textarea = document.createElement("textarea");  
        // textarea.placeHolder = "";
        this.textarea.rows = config.rows;
        this.textarea.className = 'textarea';    
        this.textarea.innerHTML = config.label;
        // this.textarea.style.width = "98%"; // check! should fill the parent. But 100% overgoes. 

        this.mainDiv.appendChild(this.textarea);

        this.mainDiv.id = id;
        return this.mainDiv;

    }

    getMainElement = function(){
        return this.textarea;
    }

    getValue = function() : string
    {
        return this.textarea.value;
    }

    setValue = function(value: string)
    {
        this.textarea.value = value;
        return this;
    }

    setHeight = function(height: string ) {
        this.mainDiv.style.height = height;
        this.textarea.style.height = "100%";
        return this;        
    }


}

