
import { NColumn } from "./NColumn";
import {NContainer} from "./NContainer";
import { NHTMLElement } from "./NHTMLElement";

export class NRow extends NContainer
{
    m_columns : NColumn[] = new Array();

    constructor() {
        super("row");
    }

    createHTMLElement(id:string) : NHTMLElement
    {
        // let htmlElement: {[k: string]: any} = {};
        let nHtmlElement: NHTMLElement =  new NHTMLElement("DIV");
        nHtmlElement.className = "rowInner";
        // htmlElement.njsNodeType = "row";
        // nHtmlElement.type = "row";
        nHtmlElement.style.boxSizing = "border-box";
    
        // htmlElement.nsdjs = {};
        return nHtmlElement;
    
    }

    addColumn = function(col:NColumn): NColumn{
        this.addElement(col);
        // let colHE = col.getHTMLElement();
        col.njsParentElement = this;
        // this.getHTMLElement().appendChild(colHE);
        this.m_columns.push(col);
        return col;
    };

    setHeight = function(height = "100px") :NRow{
        this.getHTMLElement().style.height = height;
        return this;
    }

    // setVerticalAlignment = function(align)
    // {
    //     this.getHTMLElement().classList.remove('rowInnerMiddle');
    //     this.getHTMLElement().classList.remove('rowInnerBottom');
    //     if ( align == "middle" || align == "center" ) {
    //         this.getHTMLElement().classList.add('rowInnerMiddle');
    //     } else if ( align == "bottom" ) {
    //         this.getHTMLElement().classList.add('rowInnerBottom');
    //     }
    //     // if ( ! this.getHTMLElement().nsdjs) this.getHTMLElement().nsdjs = {};
    //     this.getHTMLElement().nsdjs.verticalAlignment = align;
    //     return this;
    // };

    onNewElementAdded = function(element) {
        // nothing to do here for now        
    }

    setHorizontalAlignment = function(align){
        if ( align == "middle" ) {
            align = "center";
        } else if ( align == "right" ) {
            align = "flex-end";
        }
        this.getHTMLElement().style.justifyContent = align;
        // if ( ! this.getHTMLElement().nsdjs) this.getHTMLElement().nsdjs = {};
        this.getHTMLElement().nsdjs.horizontalAlignment = align;
        return this;
    };

    setSticky = function(isSticky, stickyPadding = "0 0 0 0")
    {
        if ( isSticky == true ) {
            this.getHTMLElement().classList.add('mui-btn--primary');
            // var dummyAfterHeader = document.createElement("DIV");  
            // dummyAfterHeader.id = this.getHTMLElement().id+"Dummy";
            // this.getHTMLElement().parentNode.parentNode.insertBefore(dummyAfterHeader, this.getHTMLElement().nextSibling)
            window.onscroll = function(p1) 
            {
                var offsetTop = this.getHTMLElement().offsetTop;
                
                if (window.pageYOffset > offsetTop) {
                    this.getHTMLElement().classList.add("sticky");
                    this.getHTMLElement().style.padding = stickyPadding;
                    // dummyAfterHeader.style.height = this.getHTMLElement().style.height;
                } else {
                    this.getHTMLElement().classList.remove("sticky");
                    this.getHTMLElement().style.padding = 0;
                    // dummyAfterHeader.style.height = 0;
                }
                // var y = dummyAfterHeader.getBoundingClientRect().y;
                // if (  y > 0 ) {
                //     var h = parseInt(dummyAfterHeader.style.height,10);
                //     dummyAfterHeader.style.height = (h - y) + "px";
                // }
            }                
        }
        return this;
    };
    
    getTotalChildHeight = function ()
	{
        let maxHeight = 0;
        if ( this.m_columns ) {
            for (var i = 0; i < this.m_columns.length; i++) {
                console.log("this.m_columns[i] = " , this.m_columns[i]);
                console.log("this.m_columns[i].getTotalChildHeight() = " + this.m_columns[i].getTotalChildHeight());
                if ( this.m_columns[i].getTotalChildHeight() > maxHeight ) {
                    maxHeight = this.m_columns[i].getTotalChildHeight();
                }
            }
        }
        return maxHeight;
    }


    getColumnsTotalWidth = function () 
	{
		if ( this.type == "row" ) {
			let width = parseInt(getComputedStyle(this.getHTMLElement(), null).getPropertyValue('width').replace('px', ''));
            console.log("getColumnsTotalWidth width: " + width);
			var totalWidth = 0;
            if ( this.m_columns ) {
                for (var i = 0; i < this.m_columns.length; i++) {
                    var col = this.m_columns[i].getHTMLElement();
                    // var colWidth = 0;
                    // console.log("getColumnsTotalWidth col.style.width: " + col.style.width);
                    let missingPixel = 0; // somehow width is computed 1 pixel less. If you sum all of the columns you do not reach total width unless you add 1 px
                    let col_width = parseInt(getComputedStyle(col, null).getPropertyValue('width').replace('px', '')) + missingPixel; // 
                    console.log("getColumnsTotalWidth col_width: " + col_width);
                    // colWidth = (col_width / width) * 100;

                    // if ( col.style.width && col.style.width.indexOf("px") > -1 ) {
                    //     colWidth = col.style.width.replace("px","");
                    //     colWidth = (colWidth / width) * 100;
                    // }

                    // if ( col.style.width && col.style.width.indexOf("%") > -1 ) {
                    //     colWidth = col.style.width.replace("%","");
                    // }
                    // console.log("getColumnsTotalWidth colWidth in %: " + colWidth);
                    totalWidth += +col_width;
                }    
            }
 			return (totalWidth / width)*100;
		}
	
	}

}