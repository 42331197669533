
import { Fontselect } from "../../builder/src/FontSelect";
import { NElement } from "./base/NElement";
import { NHTMLElement } from "./base/NHTMLElement";

export class NFontChooser extends NElement
{

    private fontSelect:Fontselect;
    // label: string;
    input: HTMLSelectElement;
    constructor(label = "Button") {
        super("button", {label:label});
    }

    createHTMLElement(id, config) : NHTMLElement
    {
        let mainDiv: NHTMLElement = new NHTMLElement("DIV");

        this.input = <HTMLSelectElement>document.createElement("INPUT");  
        this.input.id = "font";
        mainDiv.appendChild(this.input);


        // // const fontSelect = document.getElementById('font') as HTMLSelectElement;
        // const paragraphs = document.querySelectorAll('p');

        // // Replace + signs with spaces for CSS
        // const font = this.input.value.replace(/\+/g, ' ');
    
        // // Split font into family and weight
        // const fontArray = font.split(':');
    
        // // Set family on paragraphs
        // for (const paragraph of paragraphs) {
        //     paragraph.style.fontFamily = fontArray[0];
        // }



        this.fontSelect = new Fontselect(this.input, {
            style: 'font-select',
            placeholder: 'Select a font',
            lookahead: 2,
            api: '//fonts.googleapis.com/css?family=',
        });

        this.fontSelect.toggleDrop();
          
        // this.input.addEventListener('click', () => {

        //     new Fontselect(this.input, {
        //         style: 'font-select',
        //         placeholder: 'Select a font',
        //         lookahead: 2,
        //         api: '//fonts.googleapis.com/css?family=',
        //     });
    
        // });

        // document.addEventListener("DOMContentLoaded", (event) => { 
        //     new Fontselect(this.input, {
        //         style: 'font-select',
        //         placeholder: 'Select a font',
        //         lookahead: 2,
        //         api: '//fonts.googleapis.com/css?family=',
        //     });
        //     //do work
        //   });
          
        return mainDiv;
    }

    getMainElement(){
        return this.input;
    }



}