import { NTextField } from "../../../components/src/NTextField";
import { NEditor } from "./NEditor";


export class NImageEditor extends NEditor
{
    addEditors() 
    {

        let txtURL:NTextField = new NTextField("Image URL", "");
        this.colProps.addElement(txtURL);
        if ( this.pNode.value )  txtURL.setValue(this.pNode.value);
        txtURL.getHTMLElement().addEventListener("keyup", (event)=>{
            this.uiModel.updateNode(this.pNode.id, "value", (event.target as HTMLTextAreaElement).value.trim());
        });

    }

}