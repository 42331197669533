
import {NElement} from "./NElement";
import {NSeparator} from "../NSeparator";
import { NRow } from "./NRow";

export abstract class NContainer extends NElement
{
    hasDivider = false;
    totalChildHeight = 0;

    constructor(type:string) {
        super(type, null);
    }

    // abstract setVerticalAlignment(align);
    // abstract setHorizontalAlignment(align);
    abstract onNewElementAdded(element:NElement);
    abstract getTotalChildHeight();

    addElement = (comp:NElement) =>
    {
        // console.log("addComponent", comp);
        if ( comp instanceof NElement ){
            let htmlElement = comp.getHTMLElement();
            this.getHTMLElement().appendChild(htmlElement)
            var rect = htmlElement.getBoundingClientRect();
            if ( comp.type != "column" ) {
                this.totalChildHeight += rect.height;
            }
    
            if ( comp.njsParentElement) {
                const totalChildHeight = this.getTotalChildHeight();
                if ( comp.njsParentElement.type == "row") {
                    const rowHeight = comp.njsParentElement.getHTMLElement().clientHeight;
                    if ( rowHeight < totalChildHeight ){
                        comp.njsParentElement.getHTMLElement().style.height = totalChildHeight +"px";
                    }
                    htmlElement.style.width = "100%";
                } else if ( comp.njsParentElement.type == "column") {
                    const rowHeight = comp.njsParentElement.njsParentElement.getHTMLElement().clientHeight;
                    if ( rowHeight < totalChildHeight ){
                        comp.njsParentElement.njsParentElement.getHTMLElement().style.height = totalChildHeight +"px";
                    }
                }
            }
            // comp.njsParentElement  = this;
            this.onNewElementAdded(comp);
            return comp;    
        } else {
            throw new Error("Parameter type should be NComponent.");
        }
    };

    setPadding = (padding = "0") => {
        this.getHTMLElement().style.padding = padding;
        return this;
    }

    addSeparator = (size:number = 10) => {
        if ( this.type == "row" ) {
            this.addElement(new NSeparator(size, 'gray', 1, true));
        } else {
            this.addElement(new NSeparator(size, 'gray', 1, false));
        }
    };
    getVerticalAlignment = function()
    {   
        return this.nsdjs.verticalAlignment;
    }
    getHorizontalAlignment = function()
    {   
        return this.nsdjs.horizontalAlignment;
    }
    setElementPadding = function(padding){
        this.elementPadding = padding;
    }

    setHasDivider = function(hasDivider) { 
        this.hasDivider = hasDivider;
    }
    
    // getTotalChildHeight = function ( heighestBottom = 0)
	// {
    //     return this.totalChildHeight;
    //     let children = this.getHTMLElement().children;
    //     console.log("getTotalChildHeight children = ", children);
    //     for (var i = 0; i < children.length; i++) {
    //         var child = children[i];
    //         var rect = child.getBoundingClientRect();
    //         if ( rect.bottom > heighestBottom ){
    //             heighestBottom = rect.bottom;
    //         }
    //         // if ( child.njsNodeType && child.njsNodeType != "column" && child.njsNodeType != "separator" && ! child.njsIsEditor ) {
    //         //     var rect = child.getBoundingClientRect();
    //         //     if ( rect.bottom > heighestBottom ){
    //         //         heighestBottom = rect.bottom;
    //         //     }
    //         // }
    //     }	
    //     console.log("getTotalChildHeight heighestBottom = ", heighestBottom);
	// 	return heighestBottom;
	// }



    public addResizer = function(updateWidthCallback: Function, updateHeightCallback: Function)
	{
		// let element = container;
		if (this.type == "row") {
			this.getHTMLElement().style.borderBottom = "1px solid black"
		} else if (this.type == "column") {
			this.getHTMLElement().style.borderRight = "1px solid black"
		}
		// let resizer = container;
		let minimum_size = 10;
		let original_width = 0;
		let original_height = 0;
		let parent_width = 0;
		let parent_height = 0;
		let original_mouse_x = 0;
		let original_mouse_y = 0;
		// let deltaTillHundred = 0;
		let next_col_original_width;
		let nextCol;
		let next_row_original_height;
		let nextRow;
        let container = this;
        let isOnResizeLine = false;
        let isOnResizePoint = false;

        this.getHTMLElement().addEventListener('mousemove', function(e) 
        {
			// let container_width = parseFloat(getComputedStyle(container.getHTMLElement(), null).getPropertyValue('width').replace('px', ''));
			let container_height = parseFloat(getComputedStyle(container.getHTMLElement(), null).getPropertyValue('height').replace('px', ''));
            let container_right = container.getHTMLElement().getBoundingClientRect().right;
            if ( ( container.type == "column" && e.layerX < +container_right - 5 ) || 
                 ( container.type == "row" && e.layerY < +container_height - 5 ) ) 
            {
                container.getHTMLElement().style.cursor = "default";
                isOnResizeLine = false;
                return; // the line should be clicked to resize
            } else {
                if ( container.type == "row") {
                    container.getHTMLElement().style.cursor = "ns-resize";
                } else if ( container.type == "column") {
                    container.getHTMLElement().style.cursor = "ew-resize";
                }
                isOnResizeLine = true;
            }
        })

        this.getHTMLElement().addEventListener('mousedown', onMouseDown);


        if ( updateWidthCallback && updateHeightCallback ) {
            var resizerPointDiv = document.createElement("DIV");
            if ( this.type == "row") {
                resizerPointDiv.className = "resizers resizer bottom-middle";
            } else if ( this.type == "column") {
                resizerPointDiv.className = "resizers resizer right-middle";
            }
            this.getHTMLElement().appendChild(resizerPointDiv);
            resizerPointDiv.addEventListener('mousemove', function(e) 
            {
                isOnResizePoint = true;
            }); 
            resizerPointDiv.addEventListener('mouseleave', function(e) 
            {
                isOnResizePoint = false;
            }); 

            resizerPointDiv.addEventListener('mousedown', onMouseDown);    
        }
        function onMouseDown(e) 
        {        
            console.log("onMouseDown isOnResizeLine = ", isOnResizeLine);
			original_width = parseFloat(getComputedStyle(this, null).getPropertyValue('width').replace('px', ''));
			original_height = parseFloat(getComputedStyle(this, null).getPropertyValue('height').replace('px', ''));

            // if ( isOnResizeLine || isOnResizePoint ) {
    		// 	e.preventDefault();
            // }
            
            if ( ! isOnResizePoint && (( container.type == "column" && e.layerX < +original_width - 5 ) || 
                ( container.type == "row" && e.layerY < +original_height - 5 )) ) 
            {
                return ;// the line should be clicked to resize
            }

            e.preventDefault();

            original_mouse_x = e.pageX;
			original_mouse_y = e.pageY;

			if ( container.njsParentElement && container.njsParentElement.getHTMLElement ) {
				parent_width = parseFloat(getComputedStyle(container.njsParentElement.getHTMLElement(), null).getPropertyValue('width').replace('px', ''));
				parent_height = parseFloat(getComputedStyle(container.njsParentElement.getHTMLElement(), null).getPropertyValue('height').replace('px', ''));	
			}

			nextCol = this.nextSibling;
			if ( nextCol ){
				next_col_original_width = parseFloat(getComputedStyle(nextCol, null).getPropertyValue('width').replace('px', ''));
			}

            nextRow  = this.nextSibling;
            if ( nextRow ){
                next_row_original_height = parseFloat(getComputedStyle(nextRow, null).getPropertyValue('height').replace('px', ''));
            }    

			window.addEventListener('mousemove', resize)
			window.addEventListener('mouseup', stopResize)
		}
		
		let resize = (e) => 
        {
            let dividerStopDist = 30;
			if (this.type == "row") 
            {
                // console.log("e.pageY = " + e.pageY);
                // console.log("original_mouse_y = " + original_mouse_y);
                // console.log("nextRow.getBoundingClientRect().bottom = " + nextRow.getBoundingClientRect().bottom);
                var delta  = e.pageY - original_mouse_y;
                if ( ! updateHeightCallback &&  // live mode
                ( e.pageY >= nextRow.getBoundingClientRect().bottom - dividerStopDist ||
                 e.pageY <= this.getHTMLElement().getBoundingClientRect().top + dividerStopDist ) )
                {
                    return;
                }

				if ( this.m_resizedRowMinY == null) {
					this.m_resizedRowMinY = this.getHTMLElement().getBoundingClientRect().top + this.getTotalChildHeight();
                    console.log("m_resizedRowMinY = " + this.m_resizedRowMinY);
				}
				if ( !  this.m_resizedRowMinY) {
					this.m_resizedRowMinY = 0;
				}
				var moveSize = e.pageY - original_mouse_y;
				const height = original_height + moveSize;

				if (height > minimum_size && (e.pageY > this.m_resizedRowMinY || moveSize > 0) && (parent_height == 0 || height < parent_height)) {
					this.getHTMLElement().style.height = height + 'px'
                    if ( updateHeightCallback ) {
                        updateHeightCallback(container.getHTMLElement().style.height);
                        // this.m_UIModel.updateNodeStyle(container.njsNodeId, "height", container.getHTMLElement().style.height);
                    }
				}

                // console.log("delta = ", delta);
                if ( ! updateHeightCallback && nextRow ) // live mode
                {
                    var next_row_height = next_row_original_height - delta ;
                    if (next_row_height > minimum_size) {
                        nextRow.style.height = next_row_height + 'px';
                        var isPercentageHeight = false;
                        if ( isPercentageHeight ) {
                            next_row_height = ( next_row_height / parent_width ) *100;
                            nextRow.style.height =  next_row_height + '%';
                        }
                        console.log("nextRow.style.height : ", nextRow.style.height)
					}	
				}

			}
			else if (this.type == "column") 
            {    
                console.log("column resizing updateWidthCallback = ", updateWidthCallback);
				var delta  = e.pageX - original_mouse_x;

                if ( ! updateWidthCallback &&  // live mode
                    ( e.pageX >= nextCol.getBoundingClientRect().right - dividerStopDist ||
                     e.pageX <= this.getHTMLElement().getBoundingClientRect().left + dividerStopDist ) )
                {
                    console.log('returning');
                    return;
                }

				var deltaInPercent = (delta / parent_width) * 100;
				var width = original_width + delta
				var widthPerc = (width / parent_width) * 100;
				// var isPercentageWidth = true;
				var totalWidthInPercentage = 0;
				
				// if ( isPercentageWidth ) {
					totalWidthInPercentage = (<NRow>container.njsParentElement).getColumnsTotalWidth();
				// }
				// var tt = totalWidthInPercentage + (deltaInPercent/10);
                console.log('width : ' + width + " minimum_size: " + minimum_size );
                console.log("total_width : " + totalWidthInPercentage + " delta:"+delta);                
				if ( width > minimum_size  && ( totalWidthInPercentage < 100 || delta < 0) ) {
					var newWidth = width + 'px';
                    if ( this.getHTMLElement().style.width.indexOf("%") > -1 ) {
						newWidth = widthPerc +  '%';
                    }
					// if ( isPercentageWidth ) {
					// 	newWidth = widthPerc +  '%';
					// }
                    console.log('newWidth = ' + newWidth);

					this.getHTMLElement().style.width = newWidth;

                    if ( updateWidthCallback ) {
                        updateWidthCallback(container.getHTMLElement().style.width);
                    }
				}
				// if ( tt < 100 && delta > 0 ) {
					// deltaTillHundred = delta;
				// }

                if ( (! updateWidthCallback || totalWidthInPercentage >= 100 ) && nextCol ) // live mode
                {
                    var next_col_width = next_col_original_width - delta ;
                    if (next_col_width > minimum_size) {
                        nextCol.style.width = next_col_width + 'px';
                        if ( nextCol.style.width.indexOf("%") > -1 ) {
                        // if ( isPercentageWidth ) {
                            next_col_width = ( next_col_width / parent_width ) *100;
                            nextCol.style.width =  next_col_width + '%';
                        }
                        console.log("nextCol.style.width : ", nextCol.style.width)
					}	
				}
			}			  
		}
		function stopResize() {
			window.removeEventListener('mousemove', resize)
			this.m_resizedRowMinY = null;
		}
	}


}



