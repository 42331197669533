import {NMainPanel} from "../../components/src/base/NMainPanel";
import {NLabel} from "../../components/src/NLabel";
import {NTextField} from "../../components/src/NTextField";
import {NSeparator} from "../../components/src/NSeparator";
import {NColumn} from "../../components/src/base/NColumn";
import {NRow} from "../../components/src/base/NRow";
import {UIModel} from "../../model/src/UIModel";
import { NNumberField } from "../../components/src/NNumberField";
import { NCheckbox } from "../../components/src/NCheckbox";
import { NSelect } from "../../components/src/NSelect";
import { NTable } from "../../components/src/NTable";
import { NSpacer } from "../../components/src/NSpacer";
import { NTextarea } from "../../components/src/NTextarea";
import { NCanvas } from "../../components/src/NCanvas";
import { NImage } from "../../components/src/NImage";
import { NButton } from "../../components/src/NButton";
import { NElement } from "../../components/src/base/NElement";
import { NContainer } from "../../components/src/base/NContainer";
import { NPasswordField } from "../../components/src/NPasswordField";
import { NFileChooser } from "../../components/src/NFileChooser";
import { NColorChooser } from "../../components/src/NColorChooser";
import { NEmailField } from "../../components/src/NEmailField";

export class UIJustViewer 
{
	m_UIModel:UIModel;
	drawingPage = null;
	drawingPageId;
	m_isLiveMode = true;

	constructor(pDivId = null, puiModel:UIModel = null) 
    {
		this.m_UIModel = puiModel;
		this.drawingPageId = pDivId;
	}

	init = function (pDivId, puiModel)
	{
		this.drawingPageId = pDivId;	
		this.setuiModel(puiModel);	
		this.reloadUI();
	}

	setLiveMode = function (isLiveMode){
		this.m_isLiveMode = isLiveMode;
	}

	setUIModel = function(puiModel)
	{
		if ( typeof puiModel == "string" ) {
			this.m_UIModel = new UIModel();
			this.m_UIModel.setUIModelAsString(puiModel);	
		} else {
			this.m_UIModel = puiModel;
		}
	}

	reloadUI = function()
	{
		var pDiv = document.getElementById(this.drawingPageId);
		if ( this.m_UIModel ) {
			this.drawingPage = new NMainPanel(pDiv, this.m_UIModel.getFormID());
			var pages = this.m_UIModel.getPages();
			for ( var i = 0 ; i < pages.length ; i++ )
			{
				var page = pages[i];
				if ( page && page.nodes ) 
				{
					let createdElements = [];
					this.createElements(this.drawingPage, page.nodes, createdElements);
					return createdElements;
				}
			}
		}
	}

	private createElements = function (parent, arrNodes, createdElements=[]) 
	{
		for ( var i = 0; i < arrNodes.length ; i++ ) {			
			let node = arrNodes[i];
			if ( ! node ) {continue;}
			var containerDOM = this.addElement(node.type, node, parent);
			createdElements.push(containerDOM);
			if ( node.nodes) {
				this.createElements(containerDOM, node.nodes, createdElements)
			}
		}
	}

	private addElement = function(type, properties, parent ) 
	{
		let element: NElement;
		if ( type == "label"){
			element = new NLabel(properties.label);
		} else if ( type == "button"){
			element = new NButton(properties.label);
		} else if ( type == "textfield"){
			element = new NTextField(properties.label, properties.value);
		} else if ( type == "numberfield"){
			element = new NNumberField(properties.label, properties.value);
		} else if ( type == "passwordfield"){
			element = new NPasswordField(properties.label, properties.value);
		} else if ( type == "emailfield"){
			element = new NEmailField(properties.label, properties.value);
		} else if ( type == "textarea"){
			element = new NTextarea(properties.value, properties.rows);
		} else if ( type == "checkbox"){
			element = new NCheckbox(properties.label, properties.value);
		} else if ( type == "filechooser"){
			element = new NFileChooser(properties.label, properties.value);
		} else if ( type == "select"){
			element = new NSelect(properties.label, properties.options, properties.values, properties.value);
		} else if ( type == "colorpicker"){
			element = new NColorChooser(properties.label, properties.value);
		} else if ( type == "image"){
			element = new NImage(properties.value);
		} else if ( type == "canvas"){
			element = new NCanvas(properties);
		} else if ( type == "table"){
			element = new NTable( properties.rowCount, properties.columnCount, properties.rowLabels, properties.columnLabels);
		} else if ( type == "spacer"){
			element = new NSpacer(properties.size);
		} else if ( type == "separator" && parent.type == "row"){
			element = new NSeparator(properties.size, properties.style.lineColor, properties.style.lineHeight);
		} else if ( type == "separator" && parent.type == "column"){
			element = new NSeparator(properties.size, properties.style.lineColor, properties.style.lineHeight, true);
		}

		if ( type == "row" || type == "column"){
			element = this.addNewContainer(0, parent, properties);	
			if ( type == "row" ) {
				(<NRow>element).setHorizontalAlignment(properties.style.horizontalAlignment);
			} else {
				(<NColumn>element).setVerticalAlignment(properties.style.verticalAlignment);		
			}
			(<NContainer>element).setHasDivider(properties.hasDivider === true);
			if ( properties.hasDivider === true && this.m_isLiveMode ) {
				(<NContainer>element).addResizer(null, null)
			}		
		} else {
			element = this.addElementToParent(0, element, properties, parent);
		}

		if ( element && properties.style){
			Object.assign(element.getHTMLElement().style, properties.style);
			if ( type == "textfield" || type == "textarea" || type == "separator") {
				element.setAllStyle(properties.style);
			}
			if ( properties.style.fontFamily ) {
				this.addFontLink(properties.style.fontFamily);
			}
			if ( properties.style.backgroundPattern) {
				element.getHTMLElement().className +=  " " + properties.style.backgroundPattern;
			}
			if ( properties.style.fontSize) {
				element.getHTMLElement().style.fontSize = "calc("+ properties.style.fontSize +  " + 0.39062vw)"   ;
			}
		}
		if ( element && element.domId ) {
			element.getHTMLElement().id = element.domId;
		}
		return element;
	}

	private addFontLink(font: string) {
		let api = '//fonts.googleapis.com/css?family=';
		const link = api + font;
	
		if (!document.querySelector(`link[href*='${font}']`)) {
		  const lastLink = document.querySelector('link:last-of-type');
		  if (lastLink) {
			const newLink = document.createElement('link');
			newLink.href = link;
			newLink.rel = 'stylesheet';
			newLink.type = 'text/css';
			lastLink.parentNode?.insertBefore(newLink, lastLink.nextSibling);
		  }
		}
	}

	private addElementToParent = function (pageNo=0, element, properties, parent:NContainer)
	{
		if ( element && parent && (parent.type == "row" || parent.type == "column" )) 
		{
			// element.getHTMLElement().njsNodeType = element.type;
			// element.getHTMLElement().njsNodeId = properties.id;
			element.getHTMLElement().dataset.njsNodeId = properties.id; // use dataset to be able to use querySelector in getHTMLElementForNode method
			// element.getHTMLElement().njsParentNodeId = parent.njsNodeId;
			// element.getHTMLElement().njsParentElement = parent;

			element.njsNodeType = element.type;
			element.njsNodeId = properties.id;
			element.njsParentNodeId = parent.njsNodeId;
			element.njsParentElement = parent;

			if ( element.type == "image" || element.type == "canvas" ) {
				try {
					if ( properties.value ) {
						properties.value = JSON.parse(properties.value);
					}
				} catch (error) {}
				if ( properties.image) {
					element.loadImage(properties.image);
					element.coordinates = properties.value.coordinates;		
				} else if ( properties.baseImage ){
					element.loadImage(properties.baseImage);
				}
				if ( properties.setting ) {
					element.setSetting(JSON.parse(properties.setting));
				}
			}
			if ( element.type == "table" ) {
				element.setValue(properties.value);
			}

			parent.addElement(element);

			if ( element.type == "table"  || element.type == "canvas"  ){
				if ( element.getHTMLElement().style.height ) {
					parent.getHTMLElement().style.height = element.getHTMLElement().style.height;				
				}
			}

			// this.extendContainer(parent);
			return element;
		}
	}

	private addNewContainer = function (pageNo=0, parent=null, properties)
	{
		let size = (parent && parent.type == "row") ?  "20%" : "100px";
		if ( properties.style ) {
			size =  (parent && parent.type == "row") ?  properties.style.width : properties.style.height;
		}
		let container;
		if ( parent ) 
		{
			var parentNodeId = null;
			if ( parent.getHTMLElement ) {
				parentNodeId =  parent.getHTMLElement().njsNodeId;
			}
			if ( parent.type == "row" ) {
				container = new NColumn().setWidth(size);
				parent.addColumn(container);
			} else {
				container = new NRow().setHeight(size);
				parent.addRow(container);
			} 
		} else {
			container = new NRow().setHeight(size);
			this.drawingPage.addRow(container);
		}
		container.getHTMLElement().dataset.njsNodeId = properties.id; // use dataset to be able to use querySelector in getHTMLElementForNode method

		container.njsNodeType = container.type;
		container.njsNodeId = properties.id;
		container.njsParentNodeId = parentNodeId;
		container.njsParentElement = parent;


		container.style.position = "relative";
		return container;
	}





}

