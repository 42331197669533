import {NContainer} from "./NContainer";
import { NHTMLElement } from "./NHTMLElement";
import { NRow } from "./NRow";

export class NColumn extends NContainer
{
    constructor() {
        super("column");
    }

    createHTMLElement() 
    {
        // let htmlElement: {[k: string]: any} = {};
        let nHtmlElement : NHTMLElement  = new NHTMLElement("DIV");;
        nHtmlElement.className = "columnInner";
        // htmlElement.njsNodeType = "column";
        // nHtmlElement.type = "column";
        nHtmlElement.style.boxSizing = "border-box";
        // nHtmlElement.style.border = "1px solid red";
    
        // htmlElement.nsdjs = {};
        return nHtmlElement;
    }
    
    addRow (row: NRow): NRow{
        this.addElement(row);
        // let rowHE = row.getHTMLElement();
        row.njsParentElement  = this;
        // this.getHTMLElement().appendChild(rowHE)
        return row;
    };

    setVerticalAlignment = function(align)
    {
        new ResizeObserver(this.onResize).observe(this.getHTMLElement());

        this.getHTMLElement().nsdjs.verticalAlignment = align;
        this.updatePaddingTop();

        return;
        this.getHTMLElement().classList.remove('columnInnerMiddle');
        this.getHTMLElement().classList.remove('columnInnerBottom');
        if ( align == "middle" || align == "center" ) {
            this.getHTMLElement().classList.add('columnInnerMiddle');
        } else if ( align == "bottom" ) {
            this.getHTMLElement().classList.add('columnInnerBottom');
        }
        this.getHTMLElement().nsdjs.verticalAlignment = align;
    };

    onNewElementAdded = function(element) 
    {
        this.updatePaddingTop();
    }

    updatePaddingTop = function()
    {
        let id = this.getHTMLElement().id;
        // console.log(" updatePaddingTop id = " + id);
        let align = this.getHTMLElement().nsdjs.verticalAlignment;
        if ( align ) {
            let totalChildHeight = this.getTotalChildHeight();
            // console.log(" updatePaddingTop totalChildHeight = " + totalChildHeight);
            var rect = this.getHTMLElement().getBoundingClientRect();
            let elementHeight= rect.height;
    
            if ( align == "top") {
                this.getHTMLElement().style.paddingTop = 0;
            } else if ( align == "center") {
                let padding = (elementHeight - totalChildHeight) / 2;
                this.getHTMLElement().style.paddingTop = padding + "px";
            } else if ( align == "bottom") {
                let padding = (elementHeight - totalChildHeight) ;
                this.getHTMLElement().style.paddingTop = padding + "px";
            }    
        }
    }

    getTotalChildHeight = function ()
	{
        return this.totalChildHeight;
    }

    // setHorizontalAlignment = function(align){
    //     // if ( align == "middle" ) {
    //     //     align = "center";
    //     // }
    //     // this.getHTMLElement().style.textAlign = align;
    //     // this.getHTMLElement().nsdjs.horizontalAlignment = align;
    // };

    setWidth = function(width =  "50%"): NColumn {
        this.getHTMLElement().style.width = width;
        return this;
    }

    onResize = (element:HTMLElement) =>
    {
        // console.log("onResize: " , element[0].target.children);
        this.updatePaddingTop();
        // // calculate height of the inner elements
        // // set padding-top to this column to center elements vertically
        // var innerElements = element[0].target.children;

        // // Step 3: Calculate the total height
        // var totalHeight = 0;

        // for (var i = 0; i < innerElements.length; i++) {
        //     totalHeight += innerElements[i].offsetHeight;
        // }
        // console.log("totalHeight: " + totalHeight);
    }

    // getDefaultProperties(): {} {
    //     let properties = {id:nodeId, type:type};
    //     properties.style = {};			
    //     properties.style.width = "20%";			
    // }

    // getMainElement(){
    //     return this.getHTMLElement();
    // }

}


