export const LOCALE : {[k: string]: any} = {};
export const LANG : string = "EN";


LOCALE.TR = {
    FORM_NAME: "Form Adı",
    DOM_ID: "DOM ID",
    WIDGETS: "Araçlar",
    PROPERTIES: "Özellikler",
    ADD_CONTAINER: "Alan",
    ADD_TEXT: "Yazı",
    ADD_TEXTFIELD: "Yazı Alanı",
    ADD_NUMBERFIELD: "Rakam Alanı",
    ADD_PASSWORDFIELD: "Şifre Alanı",
    ADD_EMAILFIELD: "Email Alanı",
    ADD_FILECHOOSER: "Dosya Seçici",
    ADD_TEXTAREA: "Metin Alanı",
    ADD_TABS: "Sekmeler",
    ADD_MENU: "Menu",
    ADD_CHECKBOX: "Onay Kutusu",
    ADD_RADIOBUTTON: "Radyo butonu",
    ADD_SLIDER: "Slider",
    ADD_SELECT: "Seçim Kutusu",
    ADD_DATEPICKER: "Tarih Seçimi",
    ADD_COLORPICKER: "Renk Seçimi",
    ADD_IMAGE: "İmaj",
    ADD_IMAGE_EDITOR: "İmaj Editörü",
    ADD_TABLE: "Tablo",
    ADD_CONTAINER_TOOLTIP: "Yeni Alan Ekle",
    ADD_SEPARATOR_TOOLTIP: "Ayırıcı Ekle",
    DELETE_TOOLTIP:"Sil",
    DRAG_TOOLTIP:"Taşı",
    SET_DIVIDER_TOOLTIP:"Ayırıcı Yap",
    SELECT_PARENT_TOOLTIP:"Bir üst objeyi seç",
    PREVIEW_MODE: "Önizleme",
    ADD_MARKER: "Marker",
    OK:"Tamam",
    CANCEL:"İptal",
    CLOSE:"Kapat",
    APPLY:"Uygula",
    COLUMN:"Sütun",
    ROW:"Satır",
    TABLE:"Tablo",
    LABEL:"Yazı",
    CHECKBOX:"Onay Kutusu",
    OPTIONS_LIST:"Seçim Listesi",
    LINE_HEIGHT:"Çizgi Kalınlığı",
    LINE_COLOR:"Çizgi Rengi",
    ROW_COUNT:"Satır Sayısı",
    COLUMN_COUNT:"Sütun Sayısı",
    ROW_LABELS:"Satır Başlıkları",
    COLUMN_LABELS:"Sütun Başlıkları",
    TEXTAREA_ROWS:"Görünür Satır Sayısı",
    TEXT_ALIGNMENT:"Yazı hizası",
    HORIZONTAL_ALIGNMENT:"Yatay hizalama",
    VERTICAL_ALIGNMENT:"Dikey hizalama",
    CONTENT_ALIGNMENT:"Icerik hizalama",
    LEFT:"Sol",
    CENTER:"Orta",
    RIGHT:"Sağ",
    JUSTIFY:"Kapla",
    TOP:"Üst",
    MIDDLE:"Orta",
    BOTTOM:"Alt",
    BACKGROUND_COLOR:"Fon rengi",
    TEXT_COLOR:"Yazı Rengi",
    WIDTH:"Width",
    HEIGHT:"Height",
}
LOCALE.EN = {
    FORM_NAME: "Form Name",
    DOM_ID: "DOM ID",
    WIDGETS: "Widgets",
    PROPERTIES: "Properties",
    ADD_CONTAINER: "Container",
    TEXT: "Text",
    ADD_TEXT: "Text",
    ADD_TEXTFIELD: "Textfield",
    ADD_NUMBERFIELD: "Numberfield",
    ADD_PASSWORDFIELD: "Passwordfield",
    ADD_EMAILFIELD: "Emailfield",
    ADD_FILECHOOSER: "File Chooser",
    ADD_TEXTAREA: "Textarea",
    ADD_TABS: "Tabs",
    ADD_MENU: "Menu",
    ADD_CHECKBOX: "Checkbox",
    ADD_RADIOBUTTON: "Radio Button",
    ADD_SLIDER: "Slider",
    ADD_SELECT: "Select",
    ADD_DATEPICKER: "Datepicker",
    ADD_COLORPICKER: "Colorpicker",
    ADD_IMAGE: "Image",
    ADD_IMAGE_EDITOR: "Image Editor",
    ADD_TABLE: "Table",
    ADD_CONTAINER_TOOLTIP: "Add New Container",
    ADD_SEPARATOR_TOOLTIP: "Add Separator",
    DELETE_TOOLTIP:"Delete",
    DRAG_TOOLTIP:"Drag",
    SET_DIVIDER_TOOLTIP:"Set as Divider",
    SELECT_PARENT_TOOLTIP:"Select Parent",
    PREVIEW_MODE: "Preview Mode",
    ADD_MARKER: "Marker",
    OK:"OK",
    CANCEL:"Cancel",
    CLOSE:"Close",
    APPLY:"Apply",
    COLUMN:"Column",
    ROW:"Row",
    TABLE:"Table",
    LABEL:"Label",
    CHECKBOX:"Checkbox",
    OPTIONS_LIST:"Option List",
    LINE_HEIGHT:"Line height",
    LINE_COLOR:"Line color",
    ROW_COUNT:"Row count",
    COLUMN_COUNT:"Column count",
    ROW_LABELS:"Row labels",
    COLUMN_LABELS:"Column labels",
    TEXTAREA_ROWS:"Visible Row Count",
    TEXT_ALIGNMENT:"Text Alignment",
    HORIZONTAL_ALIGNMENT:"Horizontal Alignment",
    VERTICAL_ALIGNMENT:"Vertical Alignment",
    CONTENT_ALIGNMENT:"Content Alignment",
    LEFT:"Left",
    CENTER:"Center",
    RIGHT:"Right",
    JUSTIFY:"Justify",
    TOP:"Top",
    MIDDLE:"Middle",
    BOTTOM:"Bottom",
    BACKGROUND_COLOR:"Background Color",
    TEXT_COLOR:"Text Color",
    WIDTH:"Width",
    HEIGHT:"Height",
}
LOCALE.DE = {
    FORM_NAME: "Form Name",
    DOM_ID: "DOM ID",
    WIDGETS: "Widgets",
    PROPERTIES: "Properties",
    ADD_CONTAINER: "Container ",
    ADD_TEXT: "Text",
    ADD_TEXTFIELD: "Textfeld",
    ADD_NUMBERFIELD: "Nummerfeld",
    ADD_PASSWORDFIELD: "Passwortfeld",
    ADD_EMAILFIELD: "Emailfeld",
    ADD_FILECHOOSER: "Dateiselektor",
    ADD_TEXTAREA: "Textbereich",
    ADD_TABS: "Tabs",
    ADD_MENU: "Menu",
    ADD_CHECKBOX: "Kontrollkästchen",
    ADD_RADIOBUTTON: "Optionsfeld",
    ADD_SLIDER: "Slider",
    ADD_SELECT: "Auswahlfeld",
    ADD_DATEPICKER: "Datumsauswahl",
    ADD_COLORPICKER: "Farbauswahl",
    ADD_IMAGE: "Bild",
    ADD_IMAGE_EDITOR: "Bild Editor",
    ADD_TABLE: "Tabelle",
    ADD_CONTAINER_TOOLTIP: "Container einfügen",
    ADD_SEPARATOR_TOOLTIP: "Separator einfügen",
    DELETE_TOOLTIP:"Löschen",
    DRAG_TOOLTIP:"Drag",
    SET_DIVIDER_TOOLTIP:"Set Divider",
    SELECT_PARENT_TOOLTIP:"Elternelement wählen",
    PREVIEW_MODE: "Vorschau",
    ADD_MARKER: "Marker",
    OK:"OK",
    CANCEL:"Abbrechen",
    CLOSE:"Schließen",
    APPLY:"Übernehmen",
    COLUMN:"Spalte",
    ROW:"Zeile",
    TABLE:"Tabelle",
    LABEL:"Text",
    CHECKBOX:"Checkbox",
    OPTIONS_LIST:"Auswahllist",
    LINE_HEIGHT:"Linienhöhe",
    LINE_COLOR:"Linienfarbe",
    ROW_COUNT:"Zeilenanzahl",
    COLUMN_COUNT:"Spaltenanzahl",
    ROW_LABELS:"Zeilenüberschrift",
    COLUMN_LABELS:"Spaltenüberschrift",
    TEXTAREA_ROWS:"Sichtbare Zeilenanzahl",
    TEXT_ALIGNMENT:"Text Ausrichtung",
    HORIZONTAL_ALIGNMENT:"Horizontal Alignment",
    VERTICAL_ALIGNMENT:"Vertical Alignment",
    CONTENT_ALIGNMENT:"Kontent Alignment",
    LEFT:"Links",
    CENTER:"Mitte",
    RIGHT:"Rechts",
    JUSTIFY:"Ausrichten",
    TOP:"Top",
    MIDDLE:"Middle",
    BOTTOM:"Bottom",
    BACKGROUND_COLOR:"Hintergrundfarbe",
    TEXT_COLOR:"Textfarbe",
    WIDTH:"Width",
    HEIGHT:"Height",
}
