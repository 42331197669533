import {NElement} from "./base/NElement";
import { NHTMLElement } from "./base/NHTMLElement";


export class NSpacer extends NElement
{
    // size: any;

    constructor(size) {
        super("spacer", {size:size});
        // this.size = size;
    }

    createHTMLElement(id, config)
    {
        // let htmlElement: {[k: string]: any} = {};
        let htmlElement: NHTMLElement;

        htmlElement = new NHTMLElement( "DIV");  
        config.size = (""+config.size).replace("px", "");
        htmlElement.style.minHeight = config.size+"px";
        htmlElement.style.minWidth = config.size+"px";
        htmlElement.id = id;
        return htmlElement ;
    
    }

    getMainElement(){
        return this.getHTMLElement();
    }

}

