import { COMP_LOCALE, COMP_LANG } from "../resources/locale";
import {NElement} from "./base/NElement";
import { NHTMLElement } from "./base/NHTMLElement";

export enum LABEL_HEIGHT {
    MINI = "20px",
    MIDI = "30px",
    NORMAL = "40px",
    BIG = "50px",
    BIGGER = "60px"
}

export class NLabel extends NElement
{
    // lvalue: string;
    // width : string ;
    mainDiv: HTMLElement;

    constructor(label = COMP_LOCALE[COMP_LANG].LABEL, width = "100%") {
        super("label", {label:label, width:width});
        // this.lvalue = lvalue;
        // this.width = width;
    }

    createHTMLElement(id, config) : NHTMLElement
    {
        this.mainDiv = document.createElement("DIV");  
        this.mainDiv.className = 'njs-label';
        this.mainDiv.innerHTML = "<label>" + config.label + "</label>";
        this.mainDiv.style.width = config.width;
        this.mainDiv.id = id;
        return this.mainDiv ;
    }
    
    // getMainElement = function(){
    //     return this.mainDiv.getElementsByTagName("label")[0];
    // }

    addOnClickEvent =  (event: any) => {
        this.mainDiv.addEventListener("click", event);
    }

    setHorizontalAlignment (align){
        if ( align == "middle" ) {
            align = "center";
        }
        this.mainDiv.style.textAlign = align;
        return this;
    };

    setHeight(height: LABEL_HEIGHT){
        this.mainDiv.style.height = height;
        this.mainDiv.style.lineHeight = height;        
        return this;
    }

}
