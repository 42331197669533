import { NElement } from "../../components/src/base/NElement";
import { DNode } from "../../model/src/DNode";
import { UIModel } from "../../model/src/UIModel";
import { UIBuilder } from "./UIBuilder";


export class DragDropManager 
{

    dragImg = new Image();
    m_lastDraggedOverElement:any = null;
	m_dragggingElementType:any = null;
	m_dragggingElementId:any = null;
    m_UIBuilder:UIBuilder = null;
    m_UIModel:UIModel = null;

	constructor(uiBuilder:UIBuilder, uiModel:UIModel) {
        this.m_UIBuilder = uiBuilder;
        this.m_UIModel = uiModel;
		this.dragImg.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAUCAIAAABAqPnNAAABhGlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV9ba4tUHOxQikOG6mRBVMRRq1CECqFWaNXB5NIvaGJIUlwcBdeCgx+LVQcXZ10dXAVB8APEzc1J0UVK/F9SaBHjwXE/3t173L0D/M0aU82eMUDVLCObTgn5wooQekUQcYTRi5jETH1WFDPwHF/38PH1LsmzvM/9OfqVoskAn0A8w3TDIl4nntq0dM77xFFWkRTic+JRgy5I/Mh12eU3zmWH/TwzauSyc8RRYqHcxXIXs4qhEk8SJxRVo3x/3mWF8xZntVZn7XvyF0aK2vIS12kOIY0FLEKEABl1VFGDhSStGikmsrSf8vDHHb9ILplcVTByzGMDKiTHD/4Hv7s1SxPjblIkBQRfbPtjGAjtAq2GbX8f23brBAg8A1dax7/RBKY/SW90tMQRMLANXFx3NHkPuNwBYk+6ZEiOFKDpL5WA9zP6pgIweAv0rbq9tfdx+gDkqKvMDXBwCIyUKXvN493h7t7+PdPu7wcZR3KDzFIN1gAAAAlwSFlzAAAuIwAALiMBeKU/dgAAAAd0SU1FB+UFGggKLlUzrOcAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAbElEQVRIx2NsqKhgYGBgYGBIzciYPWMGAyoYKEHGhoqK1IwMhsEEZs+YwcQwKAHj0wcPBqGzmPk4OIxNTEYjkSgw6qxRZ9EEpGZkDMYCYvaMGUyY9cBoJI46azQnjubEQZ22WCCBRnwDlz4iAEgYSNvpkPMxAAAAAElFTkSuQmCC';
    }

	dragNewElementStarted = (ev, type) => {
		ev.dataTransfer.setData("addNewDragElement", type);
		this.m_dragggingElementType = type;
		this.m_dragggingElementId = null;
	}

    dragElementStarted = (ev, element) => {
		ev.stopPropagation();
		this.m_dragggingElementType = element.njsNodeType;
		this.m_dragggingElementId = element.njsNodeId;
		ev.dataTransfer.setData("moveDragElementNodeId", element.njsNodeId);
		ev.dataTransfer.setDragImage(this.dragImg, 50, 20);
	}

    onDragOver = (ev, targetElement) =>
    {
        // console.log("targetElement = ", targetElement)
        // console.log("m_dragggingElementType = ", this.m_dragggingElementType)
        if ( this.m_dragggingElementType == "row" && this.m_dragggingElementId ){
            var isDraggingElementRootRow = false;
            var targetContainer = this.m_UIModel.findParent(this.m_dragggingElementId);
            if ( ! targetContainer.id ) 
                isDraggingElementRootRow = true;
        }
        if ( ( this.m_dragggingElementType == "column" && ( targetElement.type == "row" || targetElement.type == "column" ) ) 
            || ( this.m_dragggingElementType == "row" && targetElement.type == "row" ) 
            || ( this.m_dragggingElementType != "row" && this.m_dragggingElementType != "column" ) 
        ) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        if (  this.m_lastDraggedOverElement && this.m_lastDraggedOverElement != targetElement ){
            this.m_lastDraggedOverElement.getHTMLElement().style.borderTop = this.m_lastDraggedOverElement.originalBorderTop;
            this.m_lastDraggedOverElement.getHTMLElement().style.borderRight = this.m_lastDraggedOverElement.originalBorderRight;
        }
        if ( targetElement && this.m_dragggingElementType && this.m_dragggingElementType != "row" && this.m_dragggingElementType != "column" ) {
            // console.log("test1");
            if ( targetElement.type == "row" || targetElement.type == "column" ) {
                // console.log("originalBackground =", targetElement.originalBackground);
                if ( targetElement.originalBackground == undefined){
                    targetElement.originalBackground = targetElement.getHTMLElement().style.backgroundColor;
                }
                targetElement.getHTMLElement().style.backgroundColor = "#f0fff1";
            } else {
                if ( targetElement.originalBorderTop == undefined){
                    targetElement.originalBorderTop = targetElement.getHTMLElement().style.borderTop;
                }
                targetElement.getHTMLElement().style.borderTop = "10px solid darkseagreen";
            }
            // this.m_lastDraggedOverElement = targetElement;
        } 
        else if ( this.m_dragggingElementType && (this.m_dragggingElementType == "row" || this.m_dragggingElementType == "column" ) 
                        && ( targetElement.type == "row" || targetElement.type == "column" ) )
        {
            // console.log("test2");
            if (this.m_dragggingElementType != targetElement.type && isDraggingElementRootRow != true) 
            {
                if ( targetElement.originalBackground == undefined){
                    targetElement.originalBackground = targetElement.getHTMLElement().style.backgroundColor;
                }
                targetElement.getHTMLElement().style.backgroundColor = "#f0fff1";	
            } else {
                if ( isDraggingElementRootRow ) {
                    if ( targetElement.originalBorderTop == undefined){
                        targetElement.originalBorderTop = targetElement.getHTMLElement().style.borderTop;
                    }
                    targetElement.getHTMLElement().style.borderTop = "10px solid darkseagreen";
                } else {
                    if ( targetElement.originalBorderRight == undefined){
                        targetElement.originalBorderRight = targetElement.getHTMLElement().style.borderRight;
                    }
                    targetElement.getHTMLElement().style.borderRight = "10px solid darkseagreen";
                }
            }
            // this.m_lastDraggedOverElement = targetElement;	
        }
        this.m_lastDraggedOverElement = targetElement;
    }

    onDropped(ev, targetElement2:NElement) 
    {
        var newElementType = ev.dataTransfer.getData("addNewDragElement");
        var draggingElementNodeId = ev.dataTransfer.getData("moveDragElementNodeId");
    
        if ( this.m_dragggingElementType == "row" && draggingElementNodeId ){
            var dragggingElement = this.m_UIModel.findParent(draggingElementNodeId);
            var isDraggingElementRootRow = false;
            if ( dragggingElement && ! dragggingElement.id ) isDraggingElementRootRow = true;
        }
        
        if ( ( this.m_dragggingElementType != "column" && this.m_dragggingElementType != "row" )  
            || ( this.m_dragggingElementType == "column" && ( targetElement2.type == "row" || targetElement2.type == "column" ) ) 
            || ( this.m_dragggingElementType == "row" && isDraggingElementRootRow == false )
        ) {
            ev.preventDefault();
            ev.stopPropagation();
        }
    
        let targetElementNodeType = targetElement2.type;
        let targetElementNodeId  = targetElement2.njsNodeId;
        let targetElementParentNodeId = targetElement2.njsParentNodeId;
        
        let targetElementNode : DNode = this.m_UIModel.findNode("id", targetElementNodeId);

        if ( targetElement2.njsIsDropArea ){
            // targetElement = this.m_UIViewer.addNewContainer();
            this.m_UIBuilder.m_lastSelectedNode = null;
            if ( this.m_dragggingElementType != "container" ) {
                targetElementNode = this.m_UIBuilder.addNewRow();
                targetElementNodeType = targetElementNode.type;
                targetElementNodeId = targetElementNode.id;
            }
            // targetElementParentNodeId = this.m_UIModel.findContainer(targetElementNode.id).id;
        }
    
        // DROPPING NEW ELEMENT //
        // if ( type == "container" ){
        //     type = "row";
        //     if ( targetElementNodeType == "row" ) {
        //         type = "column";
        //         // targetElementNode = this.m_UIBuilder.addNewContainer(targetElement2.njsNodeId);
        //     }
        // }
        if ( newElementType ) {
            if ( targetElementNodeType == "row" || targetElementNodeType == "column" ) {
                this.m_UIBuilder.m_lastSelectedNode = targetElementNode;
                // targetElement.getHTMLElement().style.backgroundColor = "white";
            } else {
                // this.m_UIBuilder.m_lastSelectedNode = null;
                // this.m_UIBuilder.m_lastSelectedNode = this.m_UIBuilder.addNewContainer();
                // this.m_UIBuilder.addNewContainer();

            }
            if ( targetElementNodeType != "row" && targetElementNodeType != "column")  
            {
                // var contIdToExtend = targetElementNodeId; 
                // if ( targetElementNodeType != "row" && targetElementNodeType != "column" ) {
                //     contIdToExtend = targetElementParentNodeId;
                // }

                let contIdToExtend = targetElementParentNodeId;
                let parentNode = this.m_UIModel.findNodeById(contIdToExtend);
                if ( newElementType == "container") {
                    if ( parentNode.type == "column") {
                        newElementType = "row";
                    } else {
                        newElementType = "column"; // this is not possible in builder
                    }
                }
                let nodeProps = this.m_UIModel.createNodeProperties(newElementType);
                // if ( newElementType == "row" ) {
                //     nodeProps.style.height = "20px";
                // }
                this.m_UIModel.appendNodeTo(targetElementNodeId, nodeProps, true);
                this.m_UIBuilder.reloadData();
                this.m_UIBuilder.extendContainer(document.getElementById(contIdToExtend));
                this.m_UIBuilder.reloadData();	
                // setTimeout(function(){ extendContainer(targetElement); this.reloadData()}, 3000);
            } else {
                // if ( type == "canvas" ) {
                //     this.m_UIBuilder.addCanvas(()=>{
                //         this.m_UIBuilder.reloadData();
                //     });
                // } 
                if ( newElementType == "container") {
                    this.m_UIBuilder.addNewContainer();
                } else {
                    this.m_UIBuilder.addNewElement(newElementType);
                }
                this.m_UIBuilder.reloadData();
            }
        }
    
        // DROPPING EXISTING ELEMENT //
        if ( targetElement2 ) {
            if ( targetElementNodeType ==  "row" || targetElementNodeType ==  "column" ) {
                var targetContainer = this.m_UIModel.findNode("id", targetElementNodeId);
                if ( ! targetContainer.nodes){
                    targetContainer.nodes = [];
                }	
            } else {
                var targetContainer = this.m_UIModel.findParent(targetElementNodeId);
            }
            if ( draggingElementNodeId && draggingElementNodeId != targetContainer.id) 
            {
                var movedNode = this.m_UIModel.findNode("id", draggingElementNodeId);
                var targetContainerId = targetElementNodeId;
                if ( draggingElementNodeId != targetContainerId ) {
                    if ( movedNode.type == "row" ||  movedNode.type == "column" ) 
                    {
                        var copyNode = {};
                        Object.assign(copyNode, movedNode);
                        this.m_UIModel.removeNodeById(draggingElementNodeId);
                        if ( (targetContainer.type == "row" || targetContainer.type == "column") && targetContainer.type != movedNode.type ) {
                            this.m_UIModel.adddNodeToContainer(targetContainer, copyNode);
                        } else {
                            this.m_UIModel.appendNodeTo(targetContainerId, copyNode, movedNode.type == "row");
                        }
                        this.m_UIBuilder.reloadData();
                    } else {
                        if ( targetElement2) {
                            if ( targetContainer.type == "row" ){
                                // targetElement = this.m_UIViewer.addNewContainer( 0 , targetElement);
                                let targetElementNode : DNode = this.m_UIBuilder.addNewContainer(targetElementNodeId);                         
                                targetContainer = this.m_UIModel.findNode("id", targetElementNode.id);
                                // targetContainer = this.m_UIModel.findNode("id", targetElementNodeId);
                                targetContainer.nodes = [];
                            }
                            var copyNode = {};
                            Object.assign(copyNode, movedNode);
                            this.m_UIModel.removeNodeById(draggingElementNodeId);
                            if ( targetContainer.type == "column" && (targetElementNodeType == "row" || targetElementNodeType == "column" ) )
                            {
                                targetContainer.nodes.push(copyNode);
                            } else  { // if ui element
                                this.m_UIModel.appendNodeTo(targetElementNodeId, copyNode, true);
                            }	
                            this.m_UIBuilder.reloadData();
                        }
                    }	
                }
            }
            if ( targetElementNodeType == "row" || targetElementNodeType == "column" ) {
                targetElement2.getHTMLElement().style.backgroundColor = "white";
                targetElement2.getHTMLElement().style.borderRight = targetElement2.originalBorderRight;
            }
            targetElement2.getHTMLElement().style.borderTop = targetElement2.originalBorderTop;
        }
    }
    
    onDragLeave(ev, targetElement) {
        ev.preventDefault();
        if ( targetElement ) {
            if ( targetElement.type == "row" || targetElement.type == "column" ) {
                if ( targetElement.originalBackground ) {
                    targetElement.getHTMLElement().style.backgroundColor = targetElement.originalBackground;
                } else {
                    targetElement.getHTMLElement().style.backgroundColor = "";
                }
            }
        } 
    }
    

}



