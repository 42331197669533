import { NRow } from "./NRow";

export class NMainPanel {
    
    private static m_elCount:number = 0;
    private static m_formId:string ;
    m_mainDiv:any;
    gridOverlay:any = null;

    constructor(mainDiv:any, formId?:string ) 
    {
        if ( formId ) {
            NMainPanel.m_formId = formId;    
        } else {
            NMainPanel.m_formId = NMainPanel.generateUID();    
        }
        // if ( mainDiv ) {
            if ( mainDiv && mainDiv.nodeType == 1 ) {
                this.m_mainDiv = mainDiv;
            } else {
                this.m_mainDiv = document.getElementById(mainDiv);        
            }
            this.m_mainDiv.className = "NSDJS";
            this.m_mainDiv.innerHTML = "";    
        // }
    
    }

    getMainDiv():any {
        return this.m_mainDiv;
    }

    addRow (row:NRow):NRow {
        this.m_mainDiv.appendChild(row.getHTMLElement());
        return row;
    }

    static generateUID = function() {
		let firstPart: any;
		let secondPart: any;
		firstPart = (Math.random() * 46656) | 0;
		secondPart = (Math.random() * 46656) | 0;
		firstPart = ("000" + firstPart.toString(36)).slice(-3);
		secondPart = ("000" + secondPart.toString(36)).slice(-3);
		return firstPart + secondPart;
	}
    static getElementCount(){
        return NMainPanel.m_elCount++;
    }

    static getFormId(){
        return NMainPanel.m_formId;
    }

    // getUId(){
    //     return DJS.m_formId+"_njsElId" + DJS.m_elCount++;
    // }

    setGridVisible (isVisible:boolean)
    {
        if ( isVisible ) {
            if ( this.gridOverlay == null ) {
                this.gridOverlay = document.createElement('div');
                this.gridOverlay.className = 'overlay';
                // ov.onclick = onOverlayClicked;
                // var svg = document.createElement('svg');
                var inHl = '<svg width="100%" height="100%" > ';
                var xMax = 5;
                var yMax = 20;
                for ( var i = 0; i < xMax ; i++ ) {
                    let x = ((100/xMax)*(i+1)) + '%'; 
                    // x= '20%';
                    inHl = inHl + '<line x1="'+x+'" y1="0" x2="'+x+'" y2="100%" style="stroke:rgb(200,200,200);stroke-width:1" />'
                    //inHl = inHl + '<text x="0" y="15" fill="red">I love SVG!</text>'

                }
                for ( var i = 0; i < yMax ; i++ ) {
                    // y = 100*(i+1);
                    let y = ((100/yMax)*(i+1)) + '%'; 
                    inHl = inHl + '<line x1="0" y1="'+y+'" x2="100%" y2="'+y+'" style="stroke:rgb(200,200,200);stroke-width:1" />'
                }
                inHl = inHl +'</svg>';
                this.gridOverlay.innerHTML = inHl;
                // document.body.appendChild(gridOverlay);        
                this.m_mainDiv.appendChild(this.gridOverlay);        
            }
        }
        else {
            if (this.gridOverlay) {
                // document.body.removeChild(gridOverlay);        
                this.m_mainDiv.removeChild(this.gridOverlay);        
            } 
        }
    }

//     addElementTo(pDiv, element) 
//     {
//         if (  pDiv ){
//             let newDiv;
//             newDiv = document.createElement("DIV");  
//             newDiv.id = this.getUId();
//             if ( pDiv.elementPadding ) {
//                 pDiv.style.padding = pDiv.elementPadding;
//             }
//             newDiv.innerHTML = element.dom;
//             newDiv.elementType = element.type;
//             if ( element.type == "select") newDiv.options = element.options;
//             if ( element.type == "canvas") {
//                 newDiv.coordinates = element.coordinates;
//                 // newDiv.baseImage = element.image;
//                 newDiv.image = element.image;
//                 newDiv.chooserId = element.chooserId;
//             }
//             pDiv.appendChild(newDiv);
//             // if ( pDiv.type == "row" ) {
//             if ( pDiv.parentDiv.njsNodeType == "row") {
//                 if ( pDiv.parentDiv.clientHeight < newDiv.clientHeight ){
//                     pDiv.parentDiv.style.height = newDiv.clientHeight;
//                 }
//             }
//             // }
// //            element.parentDiv = newDiv;
//             // if ( isBordersVisible ) {
//             //     newDiv.style.border = "1px solid #eee";
//             // }

//             this.attachElementMethods(newDiv);
//             return newDiv;
//         }
//     }

    getMainDOMElement(pDiv:any)
    {
        if ( pDiv.elementType == "textfield" || pDiv.elementType == "numberfield" || pDiv.elementType == "passwordfield" 
                || pDiv.elementType == "checkbox" || pDiv.elementType == "color" ) {
            return pDiv.getElementsByTagName("input")[0];
        } else if ( pDiv.elementType == "textarea") {
            return pDiv.getElementsByTagName("textarea")[0];
        } else if ( pDiv.elementType == "label") {
            return pDiv.getElementsByTagName("label")[0];
        } else if ( pDiv.elementType == "select") {
            return pDiv.getElementsByTagName("select")[0];
        } else if ( pDiv.elementType == "button"  || pDiv.elementType == "iconbutton" ) {
            return pDiv.getElementsByTagName("button")[0];
        } else {
            return pDiv;
        }
    }

    attachElementMethods(pDiv:any)
    {
        pDiv.setValue = function(value:any){
            if ( pDiv.elementType == "textfield" || pDiv.elementType == "numberfield" || pDiv.elementType == "passwordfield") {
                var textfield = pDiv.getElementsByTagName("input")[0];
                textfield.value = value;
            } else if ( pDiv.elementType == "textarea") {
                var textarea = pDiv.getElementsByTagName("textarea")[0];
                textarea.value = value;
            // } else if ( pDiv.elementType == "label") {
            //     var label = pDiv.getElementsByTagName("label")[0];
            //     label.innerHTML = value;
            } else if ( pDiv.elementType == "checkbox") {
                var checkbox = pDiv.getElementsByTagName("input")[0];
                if ( value == "true") value = true;
                if ( value == "false") value = false;
                checkbox.checked = value;
            } else if ( pDiv.elementType == "select") {
                var select = pDiv.getElementsByTagName("select")[0];
                select.value = value;
            } else if ( pDiv.elementType == "color") {
                var colorChooser = pDiv.getElementsByTagName("input")[0];
                colorChooser.value = value;
            } else if ( pDiv.elementType == "table") {
                var arrInputs = pDiv.getElementsByTagName("input");
                if ( typeof value != 'undefined' ) {
                    var arrValues = value.split('@#@');
                    for ( var i = 0; i < arrInputs.length; i++ ) {
                        if ( arrValues && arrValues[i] ) {
                            arrInputs[i].value  = " "+arrValues[i].trim();
                        }
                    }    
                }
            }
        };
        pDiv.getValue = function(){
            if ( pDiv.elementType == "textfield" || pDiv.elementType == "numberfield" || pDiv.elementType == "passwordfield") {
                var textfield = pDiv.getElementsByTagName("input")[0];
                return textfield.value;
            } else if ( pDiv.elementType == "textarea") {
                var textarea = pDiv.getElementsByTagName("textarea")[0];
                return textarea.value;
            // } else if ( pDiv.elementType == "label") {
            //     var label = pDiv.getElementsByTagName("label")[0];
            //     return label.innerHTML;
            } else if ( pDiv.elementType == "checkbox") {
                var checkbox = pDiv.getElementsByTagName("input")[0];
                return checkbox.checked;
            } else if ( pDiv.elementType == "select") {
                var select = pDiv.getElementsByTagName("select")[0];
                return select.value;
            } else if ( pDiv.elementType == "color") {
                var color = pDiv.getElementsByTagName("input")[0];
                return color.value;
            } else if ( pDiv.elementType == "canvas") {
                return JSON.stringify({coordinates: pDiv.coordinates, image:pDiv.image});
            } else if ( pDiv.elementType == "table") {
                var arrInputs = pDiv.getElementsByTagName("input");
                var strValues;
                for ( var i = 0; i < arrInputs.length; i++ ) {
                    if ( typeof strValues == 'undefined' ) {
                        strValues = arrInputs[i].value ;
                    } else {
                        strValues = strValues + "@#@" + arrInputs[i].value ;
                    }
                }
                return strValues;
            }
        };

        pDiv.setStyle = function(name:string, value:any) {
            var mainDOMElement = this.getMainDOMElement(pDiv);
            mainDOMElement.style[name] = value;
        }

        pDiv.setAllStyle = function(style:any) {
            var mainDOMElement = this.getMainDOMElement(pDiv);
            if ( pDiv.njsNodeType == "separator" ) {
                mainDOMElement.backgroundColor = style.lineColor;
                mainDOMElement.height = style.lineHeight;
            } else {
                Object.assign(mainDOMElement.style, style);	
            }
        }

        pDiv.setDisabled = function(value:any){
            if ( pDiv.elementType == "button" ) {
                var button = pDiv.getElementsByTagName("button")[0];
                button.disabled = value;
            }
        }


        if ( pDiv.elementType == "textarea" ) {
            pDiv.setRows = function(value:any){
                var textarea = pDiv.getElementsByTagName("textarea")[0];
                textarea.rows = value;
            }
            pDiv.getRows = function(){
                var textarea = pDiv.getElementsByTagName("textarea")[0];
                return textarea.rows ;
            }
        }
    
        if ( pDiv.elementType == "label" || pDiv.elementType == "checkbox" || pDiv.elementType == "select"  ) 
        {
            pDiv.setLabel = function(value:any){
                var label = pDiv.getElementsByTagName("label")[0];
                label.innerHTML = value;
            }
            pDiv.getLabel = function(){
                var label = pDiv.getElementsByTagName("label")[0];
                return label.innerHTML;
            }

            // if ( pDiv.elementType == "checkbox" ) {
            //     pDiv.isSelected = function(){
            //         var input = pDiv.getElementsByTagName("input")[0];
            //         return input.checked;
            //     }
            // }

            // if ( pDiv.elementType == "checkbox" ) {
            //     pDiv.setHorizontalAlignment = function(alignment){
            //         var input = pDiv.getElementsByTagName("input")[0];
            //         return input.style.textAlign = alignment;
            //     }
            // }
        }
    
        if ( pDiv.elementType == "select"  ) {
            pDiv.setOptions = function(values:any){
                var valueArr = values.split(',');
                var options = "";
                for ( var i = 0; i < valueArr.length ; i++ ) {
                    options += "<option value='"+valueArr[i].trim()+"'>"+valueArr[i].trim()+"</option>";
                }            
                var select = pDiv.getElementsByTagName("select")[0];
                select.innerHTML = options;
                pDiv.options = values;
            }
            pDiv.getOptions = function(){
                return pDiv.options;
            }
        }
    }

    randomGreyHex() {
      var v = (Math.random()*(256)|200).toString(16);//bitwise OR. Gives value in the range 0-255 which is then converted to base 16 (hex).
      return "#" + v + v + v;
    }

    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return this.lightenDarkenColor(color,150);
    }


    lightenDarkenColor(col:any, amt:any) {
        var usePound = false;
        if ( col[0] == "#" ) {
            col = col.slice(1);
            usePound = true;
        }

        var num = parseInt(col,16);

        var r = (num >> 16) + amt;

        if ( r > 255 ) r = 255;
        else if  (r < 0) r = 0;

        var b = ((num >> 8) & 0x00FF) + amt;

        if ( b > 255 ) b = 255;
        else if  (b < 0) b = 0;

        var g = (num & 0x0000FF) + amt;

        if ( g > 255 ) g = 255;
        else if  ( g < 0 ) g = 0;

        return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
    }

    isVisible(elm:any) {
        var rect = elm.getBoundingClientRect();
        var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    }

}

