import { NSelect } from "../../../components/src/NSelect";
import { NSeparator } from "../../../components/src/NSeparator";
import { NSpacer } from "../../../components/src/NSpacer";
import { NColumn } from "../../../components/src/base/NColumn";
import { NRow } from "../../../components/src/base/NRow";
import { UIModel } from "../../../model/src/UIModel";
import { LOCALE } from "../../resources/locale";
import { NEditor } from "./NEditor";


export class NTextareaEditor extends NEditor
{
    addEditors() 
    {
        this.addTextAlignmentEditor();
        
        var sValues = "@#@1@#@2@#@3@#@4@#@5@#@6@#@7@#@8@#@9@#@10@#@11@#@12@#@13@#@14@#@15@#@16@#@17@#@18@#@19@#@20@#@21@#@22@#@23@#@24@#@25@#@26@#@27@#@28@#@29@#@30@#@31@#@32@#@33@#@34@#@35@#@36@#@37@#@38@#@39@#@40";
        var lstRows = new NSelect(LOCALE[this.lang].TEXTAREA_ROWS +" : ", sValues, sValues, this.pNode.rows+"");			
        this.colProps.addRow(new NRow().setHeight("20px") ).addColumn(new NColumn().setWidth('100%')).addElement(lstRows);
        lstRows.getHTMLElement().addEventListener("change", (event)=>{
            this.uiModel.updateNode(this.pNode.id, "rows", (event.target as HTMLTextAreaElement).value);
            this.reloadCallback();
        });

        this.colProps.addElement(new NSpacer(30));

        this.addFontChooser();

    }

}