import { NDialog } from "../../components/src/NDialog";

export class DialogManager 
{
    static m_lastDialog:any = null;

    static showMessageDialog(title:string, message:string, onClose:Function) {
        const dialogManagerInstance = new DialogManager();
        const dlg = new NDialog(title, message, onClose, false);
        dlg.show();
    }

    static showConfirmDialog(title:string, message:string, onClose:Function) {
        const dialogManagerInstance = new DialogManager();
        const dlg = new NDialog(title, message, onClose, true);
        dlg.show();
    }

  }
  