
import {NElement} from "./base/NElement";
import { NHTMLElement } from "./base/NHTMLElement";

export class NSeparator extends NElement
{
    // size: any;
    // lineColor: string;
    // lineHeight: number;
    // isVertical:  boolean;

    constructor(size = 10, lineColor = 'gray', lineHeight = 1 , isVertical = false) {
        super("separator", {size:size, lineColor:lineColor, lineHeight:lineHeight, isVertical:isVertical});
        // this.size = size;
        // this.lineColor = lineColor;
        // this.lineHeight = lineHeight;
        // this.isVertical = isVertical;
    }

    createHTMLElement(id, config)
    {
        // let htmlElement: {[k: string]: any} = {};
        let htmlElement: NHTMLElement;
        htmlElement = new NHTMLElement("DIV");  

        var style1 = "width:100%; height:50%; border-bottom:"+config.lineHeight+"px solid "+config.lineColor+"; box-sizing: border-box;";
        var style2 = "width:100%; height:50%;";
        if ( config.isVertical == true) {
            var style1 = "width:50%; height:100%; border-right:"+config.lineHeight+"px solid "+config.lineColor+"; box-sizing: border-box;";
            var style2 = "";
        }
        if ( config.size ) {
            config.size = (""+config.size).replace("px", "");
            var innerHTML = "";
            if ( config.isVertical){
                innerHTML = "<div style='"+style1+"'></div><div style='"+style2+"'></div>";
                htmlElement.style.width = config.size+"px";
            } else {
                innerHTML = "<div style='"+style1+"'></div><div style='"+style2+"'></div>";
                htmlElement.style.height = config.size+"px";
            }
        }  
        if ( config.isVertical == true) {
            htmlElement.style.height = "100%";
        } else {
            htmlElement.style.width = "100%";
        }
        htmlElement.innerHTML = innerHTML;
        htmlElement.id = id;
    
        // this.attachCommonMethods(htmlElement);
    
        // htmlElement.setAllStyle = function(style) {
        //     htmlElement.backgroundColor = style.lineColor;
        //     htmlElement.height = style.lineHeight;
        // }
        return htmlElement;
    
    
        // return sep ;
    
        
    }

    getMainElement(){
        return this.getHTMLElement();
    }

}
